import React, { useCallback, useEffect, useState, useRef } from 'react';
import S from '../../styled';

export default function Flash(props) {
  let { flash } = props.store;
  const { clearFlash } = props.setStore;

  const [renderFlash, setRenderFlash] = useState(false);
  const showTimeoutRef = useRef();

  const closeFlash = useCallback(() => {
    const flashClose = flash?.onClose;

    setRenderFlash(false);
    clearFlash();
    if (flashClose) {
      flashClose();
    }
    clearTimeout(showTimeoutRef.current);
    showTimeoutRef.current = null;
  }, [flash, clearFlash]);

  useEffect(() => {
    if (!flash) {
      // Closing the flash will cancel the timeouts
      closeFlash();
      return;
    }

    const { important } = flash;

    setRenderFlash(true);

    clearTimeout(showTimeoutRef.current);

    showTimeoutRef.current = null;

    if (!important) {
      showTimeoutRef.current = window.setTimeout(closeFlash, 5000);
    }
  }, [flash, closeFlash]);

  return <S.Flash render={renderFlash} onClick={closeFlash} type={flash?.type}>
    {flash?.message}
  </S.Flash>;
}
