import React, { useState, useEffect, useCallback } from 'react';
import S from '../../styled';
import { useHistory } from 'react-router-dom';
import moment from 'moment';

// Components
import Page from '../../components/Page';
import Module from '../../components/Module';
import Copyable from '../../components/Copyable';
import Metric from '../../components/Metric';

// Requests
import { getPromotion } from '../../data/requests/promotions';

// Icons
import { ChevronLeft } from '@styled-icons/fa-solid';

export default function Promotion(props) {
  const history = useHistory();

  const { promotionId: promotionId } = props.match.params;
  const { store } = props;

  const { token, t } = store;

  const [promotion, setPromotion] = useState([]);
  const [isMounted, setIsMounted] = useState(true);
  const [isLoading, setIsLoading] = useState(true);

  const getPromotionData = useCallback(async () => {
    if (!token) return;

    setIsLoading(true);
    try {
      const data = await getPromotion(token, promotionId);
      
      setPromotion(data);
    } catch (error) {
      console.error('getPromotion: ', error);
    } finally {
      if (isMounted) setIsLoading(false);
    }
  }, [token, promotionId]);

  useEffect(() => {
    getPromotionData();
    return () => setIsMounted(false);
  }, [getPromotionData]);

  const start = moment(promotion.start_time).format('MMM Do YYYY');
  const end = moment(promotion.end_time).format('MMM Do YYYY');

  const goBack = (e) => {
    e.preventDefault();
    history.goBack();
  };

  const titleContent = (
    <S.Flex flex={1} justifyStart>
      <S.Link marginTop={0} onClick={goBack}><ChevronLeft size={25} /></S.Link>
      <S.Text largeSize boldWeight capitalize whitespaceNoWrap paddingRight={40}>
        {t('components.PromotionsModule.promotionOverview')}
      </S.Text>
    </S.Flex>
  );

  return (
    <Page
      title={promotion.name}
      isLoading={isLoading}
    >
      <Module
        title={titleContent}
        isLoading={isLoading}
      >
        <S.Flex column justifyStart overflowHidden marginTop={15}>
          <S.Flex justifyStart flex={1} marginBottom={20}>
            <S.Text>{t('components.PromotionsModule.promotionDates')}: <S.Text lightWeight as="span">{start} - {end}</S.Text>
            </S.Text>
          </S.Flex>
          <S.Flex justifyStart flex={1} marginBottom={20}>
            <Copyable name={t('components.PromotionsModule.uniqueLink')} value={promotion.link} store={store} />
          </S.Flex>
        </S.Flex>
      </Module>
      <Module
        title={t('components.PromotionsModule.promotionPerformance')}
        isLoading={isLoading}
      >
        <S.Flex row justifyStart overflowHidden marginTop={15}>
          <Metric
            name={t('components.PromotionsModule.visitors')}
            clickable={false}
            value={promotion.visitors}
          />
          <Metric
            name={t('components.PromotionsModule.entrants')}
            clickable={false}
            value={promotion.entries}
          />
          <Metric
            name={t('components.PromotionsModule.growth')}
            clickable={false}
            value={promotion.growth}
            addPlusSign={promotion.growth && promotion.growth > 1}
          />
         </S.Flex>
      </Module>
    </Page>
  )
}
