import React from 'react';
import S from '../../styled';

// Icons
import {
  PlusCircle as PlusIcon,
} from '@styled-icons/bootstrap';

export default function CircleIcon(props) {
  const renderIcon = () => {
    return (
      <S.SvgWrapper
        clickable
        circular
        height={22}
        width={22}
      >
        <PlusIcon />
      </S.SvgWrapper>
    );
  };

  return (
    <S.Link target="_blank" rel="noreferrer" network onClick={props.onClick}>
      {renderIcon()}
    </S.Link>
  );
}
