import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { darken } from 'polished';

const CheckboxContainer = styled.div`
  display: inline-block;
  vertical-align: middle;
  cursor: pointer;
`;

const Icon = styled.svg`
  fill: none;
  stroke: white;
  stroke-width: 2px;
`;

// Hide checkbox visually but remain accessible to screen readers.
// Source: https://polished.js.org/docs/#hidevisually
const HiddenCheckbox = styled.input.attrs({ type: 'checkbox' })`
  border: 0;
  clip: rect(0 0 0 0);
  clippath: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`;

const StyledCheckbox = styled.div`
  height: ${({ height }) => height || 'unset'};
  display: inline-block;
  width: 28px;
  height: 28px;
  background: ${({ checked }) =>
    checked ? darken(0.1, 'grey') : 'grey'
  };
  border-radius: 3px;
  transition: all 150ms;
  box-shadow: ${({ checked }) =>
    checked ? `0 0 0 3px rgba(0, 0, 0, 0.1)` : null
  };

  ${HiddenCheckbox}:focus + & {
    box-shadow: 0 0 0 3px light-grey;
  }

  ${Icon} {
    visibility: ${({ checked }) => (checked ? 'visible' : 'hidden')};
  }
`;

const Checkbox = ({ className, checked, onChange, ...otherProps }) => {
  const [checkedState, setCheckedState] = useState(false);

  const handleCheckboxChange = event => {
    if (typeof onChange === 'function') {
      event.target.checked = !checked;
      event.target.name = otherProps.name;
      onChange(event);
    } else {
      event.target.checked = !checkedState;
      event.target.name = otherProps.name;
      setCheckedState(!checkedState);
    }
  };

  useEffect(() => {
    if (typeof checked === 'boolean') {
      setCheckedState(checked);
    }
  }, [checked])

  return (
    <CheckboxContainer
      className={className}
      onClick={handleCheckboxChange}
      {...otherProps}
    >
      <HiddenCheckbox checked={checkedState} readOnly {...otherProps} />
      <StyledCheckbox checked={checkedState}>
        <Icon viewBox="0 0 24 24">
          <polyline points="20 6 9 17 4 12" />
        </Icon>
      </StyledCheckbox>
    </CheckboxContainer>
  );
};

export default Checkbox;
