import styled, { css } from 'styled-components';

export const base = css`
	font-family: inherit;
	font-size: inherit;
	accent-color: ${({ theme }) => theme.input.color.selected};
	color: ${({ theme }) => theme.input.color.text};
	font-weight: ${({ theme }) => theme.input.fontWeight};
	margin-right: 5px;
`;

const InputRadio = styled.input`
	${base}
`;

export default InputRadio;
