import styled from 'styled-components';
import { motion } from 'framer-motion';
import { base as modalBase } from './Modal';
import { breakpointNames, breakpoint } from '../Mixins';

const topPosition = 80;
const minHeight = 480;

const Navigation = styled(motion.div)`
  ${modalBase}
  display: flex;
  flex-direction: column;
  align-items: center;
  transform: ${({ render }) =>
    render ? 'translateY(0)' : `translateY(calc(100vh + ${minHeight * 2}px))`};
  top: ${topPosition}px;
  min-height: ${minHeight}px;
  max-height: 100vh;
  padding: 14px;

  ${breakpoint(
    breakpointNames.tablet,
    `
    display: none;
    transform: unset;
    top: unset;
    min-height: unset;
    box-shadow: unset;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    borter-top-right-radius: 0;
    background-color: transparent;
  `
  )};
`;

export default Navigation;
