import React, { useState, useContext, useCallback } from 'react';
import _ from 'lodash';
import S from '../../../styled';
import FileSelect from '../../FileSelect';
import { FormFieldContext } from '../../../store/FormFieldContext';

import FieldErrorMessage from './FieldErrorMessage';

import { useFileUpload } from '../../../data/requests/uploads';

const ACCEPTABLE_MIME_TYPES = {
  'pdf': 'application/pdf',
  'jpeg': 'image/jpeg',
  'jpg': 'image/jpeg',
  'gif': 'image/gif',
  'png': 'image/png'
}

const EXTENSION_TYPES = {
  'pdf': '.pdf',
  'jpeg': '.jpeg',
  'jpg': '.jpg',
  'gif': '.gif',
  'png': '.png'
}

export default function FileUploadInput({ id, required, params, store }) {
  const { t, token } = store;
  const { name, options } = params
  const fieldType = params.field_type;
  const { onChangeData, errors } = useContext(FormFieldContext);
  const [fileName, setFileName] = useState("")
  const { uploadFile } = useFileUpload(token);

  const handleFile = useCallback(async (contentFile) => {
    try {
      const fileData = {
        file: contentFile,
        contentType: contentFile?.type,
        contentURL: URL.createObjectURL(contentFile)
      };
      const upload = await uploadFile(fileData.file, 'evaluator'); // same source as program form in IRM
      const backendData = {
        url: upload.url,
        key: upload.key,
        filename: upload.fileName
      };
      onChangeData(id, fieldType, required, JSON.stringify(backendData));
      setFileName(contentFile.name);
    } catch (error) {
      console.log('FileUploadInput: ', error);
    }
  }, [id, fieldType, required, uploadFile, onChangeData, setFileName]);

  const processOptions = (options, mapObject, defaultValue = '') => {
    let fileOptions = _.toLower(options || "").trim().split("\n");

    if (!Array.isArray(fileOptions)) {
      fileOptions = [fileOptions];
    }

    fileOptions = fileOptions.map((extension) => mapObject[extension]).filter(Boolean).join(', ');

    return fileOptions || defaultValue;
  };

  const accept = () => {
    return processOptions(options, ACCEPTABLE_MIME_TYPES, Object.values(ACCEPTABLE_MIME_TYPES).join(', '));
  };

  const acceptExtensions = () => {
    return processOptions(options, EXTENSION_TYPES, Object.values(EXTENSION_TYPES).join(', '));
  };

  const acceptedFiles = (
    <S.Text lightWeight>
      {t('components.FormField.FileUploadInput.acceptedFiles', { fileTypes: acceptExtensions() })}
    </S.Text>
  )

  const uploadedFile = (
    <S.Text>
      {t('components.FormField.FileUploadInput.fileUploaded', { fileName: fileName })}
    </S.Text>
  );

  return (
    <S.Flex alignStart column marginTop={14} padding={4}>
      <S.Text>{name}</S.Text>
      <FileSelect
        handleFile={handleFile}
        accept={accept()}
        maxSize={805306368}
        uploadFromDeviceText={t('components.FileSelect.uploadFromDevice')}
        fileTooLargeText={t('components.FileSelect.fileTooLarge')}/>
      {acceptedFiles}
      {fileName && uploadedFile}
      <FieldErrorMessage
        id={id}
        required={required}
        errors={errors}
        fieldName={name}
        store={store}
      />
    </S.Flex>
  );
}
