const facebookAppId = process.env.REACT_APP_FACEBOOK_APP_ID;
const facebookCookie = process.env.REACT_APP_FACEBOOK_COOKIE || true;
const facebookXFBML = process.env.REACT_APP_FACEBOOK_XFBML || true;
const facebookVersion = process.env.REACT_APP_FACEBOOK_VERSION || 'v8.0';


export function initFacebookSdk() {
  // wait for facebook sdk to initialize before starting the react app
  window.fbAsyncInit = function () {
    window.FB.init({
      appId: facebookAppId,
      cookie: facebookCookie,
      xfbml: facebookXFBML,
      version: facebookVersion
    });
  };

  // load facebook sdk script
  (function (doc, script, id) {
    var js, facebookjs = doc.getElementsByTagName(script)[0];
    if (doc.getElementById(id)) { return; }
    js = doc.createElement(script); js.id = id;
    js.src = "https://connect.facebook.net/en_US/sdk.js";
    facebookjs.parentNode.insertBefore(js, facebookjs);
  }(document, 'script', 'facebook-jssdk'));    
}
