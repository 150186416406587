import React, { useEffect, useState, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import numeral from 'numeral';
import getSymbolFromCurrency from 'currency-symbol-map';
import S from '../../styled';

// Routes
import { routeNames } from '../../routing/routes';

// Components
import Module from '../Module';
import Metric from '../Metric';
import { ReactComponent as PerformanceImage } from '../../assets/svg/module_enhancement.svg';

// Requests
import { getPerformance } from '../../data/requests/performance';

// Helpers
import navigateTo from '../../helpers/navigateTo';

export default function PerformanceModule(props) {
  const history = useHistory();

  const { token, account, t } = props.store;
  const { flex } = props;

  const [isMounted, setIsMounted] = useState(true);
  const [moduleData, setModuleData] = useState({});
  const [moduleIsLoading, setModuleIsLoading] = useState(true);

  const loadModuleData = useCallback(async () => {
    if (!token) return;

    try {
      setModuleIsLoading(true);
      const data = await getPerformance(token);
      setModuleData(data);
    } catch (error) {
      console.error('loadModuleData: ', error);
    } finally {
      if (isMounted) setModuleIsLoading(false);
    }
  }, [token, isMounted]);

  useEffect(() => {
    loadModuleData();
    return () => setIsMounted(false);
  }, [loadModuleData]);

  const commissionsEnabled = account?.commissions_enabled;

  const isEmpty = !moduleData.conversion_count && !moduleData.last_payment_amount_cents;
  const emptyStateText = () => {
    if (!commissionsEnabled) {
      return t('components.PerformanceModule.paymentInfo')
    } else if (moduleData.conversion_count < 1) {
      return t('components.PerformanceModule.performanceInfo')
    } else {
      return t('components.PerformanceModule.revenueSplitInfo');
    }
  };

  const emptyContent = (
    <>
      <S.Flex flex={1} column alignCenter justifyCenter textAlignCenter overflowHidden>
        <S.Text muted paddingBottom={10} textAlignCenter>
          {t('components.PerformanceModule.noConversionData')}
        </S.Text>
        <S.Text muted lightWeight textAlignCenter>
          {emptyStateText()}
        </S.Text>
        <S.SvgWrapper paddingTop={40} height={150} width={150} color='#FFFFFF'>
          <PerformanceImage />
        </S.SvgWrapper>
      </S.Flex>
    </>
  );


  const paymentCurrencySymbol = getSymbolFromCurrency(moduleData.last_payment_currency || 'USD')
  const revenueCurrencySymbol = getSymbolFromCurrency(moduleData.revenue_currency || 'USD');
  const lastPayment = numeral(moduleData.last_payment_amount_cents || 0).divide(100).format(paymentCurrencySymbol + '0,0.00');
  const clickCount = numeral(moduleData.click_count || 0).format('0a');
  const conversionCount = numeral(moduleData.conversion_count || 0).format('0a');
  const revenueTotal = numeral(moduleData.revenue_total || 0).format(revenueCurrencySymbol + '0,0.00');

  const navigateToPayments = () => navigateTo(history, routeNames.payments, token);
  const navigateToPerformance = () => navigateTo(history, routeNames.performance, token);

  return (
    <Module
      title={t('navigation.performance')}
      isLoading={moduleIsLoading}
      isEmpty={isEmpty}
      flex={flex}
      emptyContent={emptyContent}
    >
      <Metric
        largeSize
        clickable
        hoverable
        name={t('components.PerformanceModule.lastPayment')}
        value={lastPayment}
        onClick={navigateToPayments}
      />
      {commissionsEnabled &&
        <S.Flex column alignStart marginTop={20} gap={4}>
          <S.Text mediumSize>
            {t('components.PerformanceModule.monthToDate')}
          </S.Text>
          <S.Flex row gap={24} marginTop={14}>
            <Metric
              mediumSize
              clickable
              hoverable
              name={t('components.PerformanceModule.clicks')}
              value={clickCount}
              onClick={navigateToPerformance}
            />
            <Metric
              mediumSize
              clickable
              hoverable
              name={t('navigation.conversions')}
              value={conversionCount}
              onClick={navigateToPerformance}
            />
            <Metric
              mediumSize
              clickable
              hoverable
              name={t('navigation.revenue')}
              value={revenueTotal}
              onClick={navigateToPerformance}
            />
          </S.Flex>
        </S.Flex>
      }
    </Module>
  );
}
