import React from 'react';
import { BrowserRouter, Switch } from 'react-router-dom';
import _ from 'lodash';
import { AUTHED_STATUS_LOADING } from '../helpers/constants';

// Components
import Page from '../components/Page';

const Router = ({ routes, store, setStore }) => {
  const { accountIsLoading, authedStatus } = store;
  const loading = accountIsLoading && authedStatus === AUTHED_STATUS_LOADING;

  if (loading) {
    return <Page isLoading={loading} />;
  } else {
    return (
      <BrowserRouter>
        <Switch>
          {_.flatten(routes.map(({ layout, pages }) =>
            pages.map(({ routeType: Route, page, path, exact, strict, pageRouting, settings }, index) => (
              <Route
                key={`route-${index}`}
                path={path}
                exact={exact}
                strict={strict}
                store={store}
                setStore={setStore}
                layout={layout}
                page={page}
                pageRouting={pageRouting}
                routeSettings={settings}
              />
            ))
          ))}
        </Switch>
      </BrowserRouter>
    )
  }
};

export default Router;
