import { createGlobalStyle } from 'styled-components';
const Global = createGlobalStyle`
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video, input, form, textarea {
	margin: 0;
	padding: 0;
	border: 0;
	vertical-align: baseline;
}

article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
	display: block;
}

html {
	box-sizing: border-box;
}

*, *:before, *:after {
	box-sizing: inherit;
}

body {
	line-height: 1;
}

blockquote, q {
	quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}

table {
	border-collapse: collapse;
	border-spacing: 0;
}

body {
	margin: 0;
	padding: 0;
	font-family: ${({ theme }) => theme.fontFamilyPoppins};
	font-size: ${({ theme }) => theme.fontSize};
	font-weight: ${({ theme }) => theme.fontWeight};
	line-height: 1.25;
	background: ${({ theme }) => theme.body.color.background};
	color: ${({ theme }) => theme.text.color.default};
	transition: all 0.50s linear;
	word-wrap: break-word;
	text-rendering: optimizeLegibility;
	-webkit-font-smoothing: antialiased;
}
`;

export default Global;
