import styled from 'styled-components';
import Button from './Button';

export const DropDown = styled.div`
  position: relative;
  display: inline-block;
  .dropdown-content {
    display: block;
    position: absolute;
    z-index: 9;
    border: 1px solid grey;
    border-radius: 2px;
  }
`;

export const DropDownPrimaryButton = styled(Button)`
`;

export const DropDownSecondaryButton = styled(Button)`
  color: black;
  text-decoration: none;
  border-radius: 0;
  border: none;
  background-color: white;
  &:first-child {
    border-top-left-radius: 2px;
    border-top-right-radius: 2px;
  }
  &:last-child {
    border-bottom-left-radius: 2px;
    border-bottom-right-radius: 2px;
  }
`;
