import api from '../api';

const buildProductOptions = (products) => products.map(product => ({ value: product.id, label: product.title, ...product }));

export const getProducts = async (token, sourceId, searchString = '') => {
  const products = await api({
    method: 'get',
    url: `products/${token}`,
    params: {
      source_id: sourceId,
      search: searchString
    }
  });
  return buildProductOptions(products);
};

export const getProductLink = (token, sourceId, productId) => {
  return api({
    method: 'get',
    url: `links/${token}`,
    params: {
      source_id: sourceId,
      product_id: productId
    }
  });
};
