import React from 'react';
import S from '../../styled';

// Icons

import { InformationOutline } from '@styled-icons/zondicons';

export default function InformationalIcon(props) {
  const renderIcon = () => {
    return (
      <S.SvgWrapper
        clickable
        circular
        height={15}
        width={15}
      >
        <InformationOutline />
      </S.SvgWrapper>
    );
  };

  return (
    <S.Link target="_blank" rel="noreferrer" network onClick={props.onClick}>
      {renderIcon()}
    </S.Link>
  );
}
