import styled from 'styled-components';

const SectionBreak = styled.hr`
    width: 100%;
    margin-bottom: 20px;
    border: 0;
    border-bottom: 2px solid;
    border-color: ${({ theme }) => theme.input.color.border};
`;

export default SectionBreak;
