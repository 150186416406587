import styled, { css } from 'styled-components';
import { width, height, breakpointNames, flex } from '../Mixins';
import { breakpoint } from 'styled-components-breakpoint';

const Visibility = styled.div`
  display: none;
  max-width: 100%;
  min-height: 0;
  ${({ overflow }) =>
    overflow &&
    css`
      overflow-x: hidden;
      overflow-y: ${overflow};
    `};

  ${breakpoint(breakpointNames.mobile, breakpointNames.tablet)`
    ${({ mobile }) => (mobile ? flex : 'display: none;')};
  `}
  ${breakpoint(breakpointNames.tablet, breakpointNames.desktop)`
    ${({ tablet }) => (tablet ? flex : 'display: none;')};
  `}
  ${breakpoint(breakpointNames.desktop)`
    ${({ desktop }) => (desktop ? flex : 'display: none;')};
    flex: 1;
  `}

  ${width}
  ${height}
`;
export default Visibility;
