import React from 'react';
import S from '../../styled';
import HtmlContent from '../HtmlContent';

export default function TaskInstructions(props) {
  const { instructionsHtml } = props;

  return (
    <S.Flex
      height={"100%"}
      flex={1}
      overflowAuto
      justifyStart
      alignStart
      padding={10}
      paddingLeft={53}
    >
      <HtmlContent content={instructionsHtml} flex={0} textAlignLeft />
    </S.Flex>
  );
}
