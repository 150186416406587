import styled from 'styled-components';
import { motion } from 'framer-motion';
import { breakpoint, breakpointNames } from '../Mixins';

const minHeight = 480;

const ScreenOverlay = styled(motion.div)`
  transform: ${({ render }) =>
    render
    ? 'translateY(0), '
    : `translateY(calc(100vh + ${minHeight * 2}px))`};

  transition: transform 0.1s, opacity 1s;
  position: fixed;
  top: 0px;
  left: 0px;
  height: 100vh;
  width: 100%;
  z-index: 199;
  opacity: ${({ render }) => render ? '0.8' : '0' };
  background-color: ${({ theme }) => theme.modal.color.background};

  ${breakpoint(breakpointNames.tablet, `
    display: none;
    transform: unset;
    transition: unset;
    position: relative;
  `)};
`;

export default ScreenOverlay;
