import numeral from 'numeral'
import getSymbolFromCurrency from 'currency-symbol-map';
import isPresent from './isPresent';

const humanizeNumber = ([value, decimalPlaces=0], opts) => {
  if (!isPresent(value)) return "–";
  value = parseFloat(value);

  let suffix = '';
  let format;
  if (decimalPlaces != null && decimalPlaces > 0) {
    format = `0.${'0'.repeat(decimalPlaces)}a`;
  } else {
    format = '0a';
  }

  function stripUnits(s) {
    // "2.4 k" -> "2.4"
    return s.replace(/[A-Z]+/i, '').trim();
  }

  if (opts && Object.keys(opts).length > 0) {
    const { template } = opts;

    switch (template) {
      case 'percentage':
        if (value >= 10 || value === 0) {
          format = '0a';
        } else {
          format = '0.0a';
        }
        suffix = '%';
        break;
      case 'rounded':
      case 'emv':
        format = '0a';
        if (value >= 1000) {
          const lenForUnit = stripUnits(numeral(value).format('0a')).length;
          if (lenForUnit < 2) {
            format = '0.0a';
          }
        }
        break;
    }
  }

  // Safeguard against showing ".0" in the decimal place.
  let result = numeral(value).format(format);
  const decimal = stripUnits(result).split('.')[1];
  if (parseInt(decimal) === 0) {
    format = '0a';
  }
  if (opts && opts.currency) {
    const { currency } = opts
    const currencySymbol = getSymbolFromCurrency(currency)
    return `${currencySymbol}${numeral(value).format(format)}${suffix}`;
  } else {
    return `${numeral(value).format(format)}${suffix}`;
  }
};

export default humanizeNumber;
