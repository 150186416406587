import api from '../api';

export const getCurrentAccountByToken = (token) =>
	api({
		method: 'get',
		url: `accounts/${token}`
	});

export const getAccountsByUser = (token) =>
api({
  method: 'get',
  url: `accounts/${token}/grouped`
});
