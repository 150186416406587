import React from 'react';
import moment from 'moment';
import numeral from 'numeral';
import getSymbolFromCurrency from 'currency-symbol-map';
import S from '../../styled';

export default function Conversion(props) {
  const timestamp = moment(props.timestamp).format('MMM Do YYYY, h:mm A');
  const revenueCurrencySymbol = getSymbolFromCurrency(props.currency || 'USD')
  const commissionCurrencySymbol = getSymbolFromCurrency(props.commission_amount_currency || 'USD')
  const revenue = numeral(props.revenue || 0).format(revenueCurrencySymbol + '0,0.00');
  const commission = numeral(props.commission_amount || 0).format(commissionCurrencySymbol + '0,0.00');

  return (
    <S.Flex flex={1} row>
      <S.Flex flex={2} justifyStart><S.Text smallSize lightWeight>{timestamp}</S.Text></S.Flex>
      <S.Flex flex={1} justifyEnd><S.Text smallSize lightWeight>{commission}</S.Text></S.Flex>
      <S.Flex flex={1} justifyEnd><S.Text smallSize lightWeight>{revenue}</S.Text></S.Flex>
    </S.Flex>
  );
}
