import styled from 'styled-components';
import { motion } from 'framer-motion';
import {
  margin,
  padding,
  flex,
  width,
  height,
  breakpointNames
} from '../Mixins';
import { breakpoint } from 'styled-components-breakpoint';

const Module = styled(motion.div)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-items: flex-start;
  position: relative;
  padding: ${({ clear }) => (clear ? 0 : '20px')};
  border-width: 1px;
  border-style: solid;
  border-color: ${({ theme, outlined }) =>
    outlined ? theme.module.color.outlined : theme.module.color.border};
  background: ${({ theme }) => theme.module.color.background};
  border-radius: ${({ theme }) => theme.input.borderRadius};
  margintop: ${({ clear }) => (clear ? 0 : '14px')};
  marginbottom: ${({ clear }) => (clear ? 0 : '14px')};
  overflow: auto;
  transition: all 0.3s ease-in-out;

  ${breakpoint(breakpointNames.mobile, breakpointNames.tablet)`
    flex: ${({ flex, fullPage }) => {
      if (fullPage) return 1;
      if (flex !== undefined) return flex;
      return 0;
    }};
    width: 100%;
    max-width: 688px;
    height: ${({ fullPage }) => (fullPage ? '100%' : '500px')};
    max-height: ${({ fullPage }) => (fullPage ? '100%' : '500px')};
  `}

  ${breakpoint(breakpointNames.tablet, breakpointNames.desktop)`
      flex: ${({ flex, fullPage }) => {
        if (fullPage) return 1;
        if (flex !== undefined) return flex;
        return 0;
      }};
      min-width: 450px;
      max-width: 688px;
      height: ${({ fullPage }) => (fullPage ? '100%' : '500px')};
      max-height: ${({ fullPage }) => (fullPage ? '100%' : '500px')};
  `};

  ${breakpoint(breakpointNames.desktop)`
      flex: ${({ flex }) => (flex !== undefined ? flex : 1)};
      max-width: 688px;
      height: ${({ fullPage }) => (fullPage ? '100%' : '500px')};
      max-height: ${({ fullPage }) => (fullPage ? '100%' : '500px')};
  `};

  ${margin}
  ${padding}
  ${flex}
  ${width}
  ${height}
`;

export default Module;
