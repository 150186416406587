import React from 'react';
import S from '../../styled';

// Components
import Task from './Task';

export default function TaskGroup({ taskGroup, token, setStore, profile, onTaskUpdated, store}) {
  return (
    <>
      <S.Flex row flex={0} marginTop={15} marginBottom={15}>
        <S.Text muted truncate>{taskGroup.name}</S.Text>
      </S.Flex>
      {taskGroup.tasks.map((task, i) => (
        <S.Flex flex={0} key={`${task.id}-${i}`}>
          <Task
            taskGroupName={taskGroup.name}
            token={token}
            task={task}
            profile={profile}
            setStore={setStore}
            onTaskUpdated={onTaskUpdated}
            store={store}
          />
        </S.Flex>
      ))}
    </>
  )
}
