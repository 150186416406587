import api from '../api';

const baseUrl = (token) => `accounts/${token}/uploads`;

export const getSignedRequest = (token, params) =>
  api({
    method: 'get',
    url: `${baseUrl(token)}/new`,
    params: params
  });

export const completeFileUpload = (token, key, file, source = 'portal') =>
  api({
    method: 'post',
    url: `${baseUrl(token)}/uploaded`,
    params: {
      source,
      key,
      content_type: file.type,
      content_length: file.size,
      original_filename: file.name,
    }
  });

export const getUploadsByParentTaskId = (token, parentTaskId) =>
  api({
    method: 'get',
    url: `${baseUrl(token)}`,
    params: {
      task_id: parentTaskId
    }
  });

async function s3Upload(file, fields, url) {
  const body = new FormData();
  Object.entries(fields).forEach(([k,v]) => body.append(k,v));
  body.append('file', file);

  const response = await fetch(url, {body, method: 'POST'});
  if (!response.ok) {
    throw new Error(`${response.status}: ${response.statusText}`);
  }
}

export function useFileUpload(token, source) {
  const uploadFile = async (file) => {
    const params = {
      private: true,
      content_type: file.type,
      original_filename: file.name,
    }
    const { post_url, upload_url, fields } = await getSignedRequest(token, params);

    await s3Upload(file, fields, post_url);
    const { key } = fields;
    const uploadData = await completeFileUpload(token, key, file, source);

    return { url: upload_url, uploadId: uploadData.id, key: key, fileName: file.name };
  }

  return { uploadFile }
};
