import styled from 'styled-components';
import { backgroundColor, flex, width, fontSize, height, breakpointNames, breakpoint, padding, margin } from '../Mixins';

const BaseButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  font-weight: 600;
  white-space: nowrap;
  line-height: 19px;
  padding: 16px 32px;
  border-width: thin;
  cursor: pointer;`

const Button = styled(BaseButton)`
  width: ${({ width, outline}) => {
    if (outline) return '60%';
    if (width) return width;
    return '100%';
  }};
  height: ${({ height, outline }) => {
    if (outline) return '12px';
    if (height) return height;
    return '50px'
  }};
  font-size: ${({ theme, fontSize, outline }) => {
    if (theme.button.fontSize) return theme.button.fontSize;
    if (outline) return theme.fontSizeSmall;
    if (fontSize) return fontSize;
    return theme.fontSize
  }};
  font-family: ${({ theme }) => theme.fontFamily};
  border: ${({ theme, border, outline }) => {
    if (outline) return `2px solid ${theme.button.color.outline}`;
    if (border) return border;
    return 'none'
  }};
  transition: all 0.3s ease-in-out;
  border-radius: ${({ theme }) => theme.button.borderRadius};
  opacity: ${({ disabled }) => {
    if (disabled) return 0.5;
    return 1;
  }};
  pointer-events: ${({ disabled }) => {
    if (disabled) return 'none';
    return 'auto';
  }};
  color: ${({ theme, color, outline, task }) => {
    if (outline) return theme.button.color.outline;
    if (color) return color;
    if (task) return theme.button.color.outlineBackground;
    return theme.button.color.text;
  }};
  ${flex}
  ${backgroundColor}
  ${width}
  ${height}
  ${fontSize}
  ${margin}

  ${breakpoint(breakpointNames.tablet, `
    padding-left: ${padding || '20px'};
    padding-right: ${padding || '20px'};
  `)};

  ${padding}
`;

export default Button;
