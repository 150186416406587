import api from '../api';

const baseUrl = (token) => `delegates/${token}`;

export const getDelegateRelationships = (token) =>
  api({
    method: 'get',
    url: `${baseUrl(token)}/relationships`
  });

export const deleteDelegateRelationship = (token, delegateRelationshipId) =>
  api({
    method: 'delete',
    url: `${baseUrl(token)}/relationships/${delegateRelationshipId}`
  });

export const createDelegateRelationship = (token, email) =>
  api({
    method: 'post',
    url: `${baseUrl(token)}`,
    data: {
      email: email
    }
  });

export const selectDelegateRelationship = (token, id) =>
  api({
    method: 'patch',
    url: `${baseUrl(token)}`,
    data: {
      id
    },
  });

export const deselectDelegateRelationship = (token) =>
  api({
    method: 'patch',
    url: `${baseUrl(token)}/deselect_relationship`,
  });
