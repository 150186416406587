import { useMemo } from 'react';
import { useHistory } from 'react-router-dom';

// Helpers
import navigateTo from '../../helpers/navigateTo';

// Routes
import { routeNames } from '../../routing/routes';

export function useTask(task, token, profile, taskGroupName, openTaskModal, onTaskUpdated, connectNetwork) {
  const { id, action, type } = task;
  const history = useHistory();

  const handler = useMemo(() => {
    if (!action) return;
    if (type === 'partnership') return;

    let h = null;

    function navigateAction(type) {
      switch (type) {
        case 'payment':
          return () => navigateTo(history, routeNames.payments, token)
        default:
      }
    }

    switch (action) {
      case "navigate":
        if (!task.is_complete) {
          h = navigateAction(type)
        }
        break;

      case "post_content":
        h = () => navigateTo(history, routeNames.postContentTask, token, { queryParams: { taskId: id, taskGroupName } })
        break;

      case "file_request":
        h = () => navigateTo(history, routeNames.fileRequestTask, token, { queryParams: { taskId: id, taskGroupName } })
        break;
      
      case "share_code":
        h = () => navigateTo(history, routeNames.shareCodesTask, token, { queryParams: { taskId: id, taskGroupName } })
        break;

      case "connect_profile":
        if (!task.is_complete) {
          h = async () => await connectNetwork(profile, task?.network, true);
        }
        break;

      case "mark_todo":
        h = () => navigateTo(history, routeNames.todoTask, token, { queryParams: { taskId: id, taskGroupName: taskGroupName } });
        break;

      case "campaign_brief":
        h = () => navigateTo(history, routeNames.campaignBrief, token, { queryParams: { taskId: id, taskGroupName: taskGroupName } });
        break;

      default:
        console.error("Unsupported task action:", action);
    }
    if (h) return h;

    console.error("Unsupported action:", type, action);

  }, [id, action, type, history, token, taskGroupName, task, connectNetwork, profile]);

  return {
    isActionable: !!handler,
    taskAction: handler || (() => { }),
  };
}
