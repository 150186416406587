import React, { useContext } from 'react';

import S from '../../../styled';
import { FormFieldContext } from '../../../store/FormFieldContext';
import { FORM_FIELD_DEFAULT_LABELS } from '../../../helpers/constants';
import FieldErrorMessage from './FieldErrorMessage';

export default function TextInput({
  id, fieldType, minLength, maxLength, name, dataType, required, store
}) {
  const { formFieldData, onChangeData, errors } = useContext(FormFieldContext);
  const { t } = store;
  const labelText =
    name === FORM_FIELD_DEFAULT_LABELS[fieldType]
      ? t(`components.FormField.typeLabels.${fieldType}`)
      : name

  return (
    <S.Flex column alignStart marginTop={14}>
      <S.Text>{labelText}</S.Text>
      <S.Input
        name={labelText}
        maxLength={maxLength}
        minLength={minLength}
        value={formFieldData[id] || ""}
        onChange={(e) => onChangeData(id, fieldType, required, e.target.value)}
        type={dataType}
      />
      <FieldErrorMessage
        id={id}
        required={required}
        errors={errors}
        fieldName={labelText}
        store={store}
      />
    </S.Flex>
  );
}
