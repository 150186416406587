import React, { useContext, useState } from 'react';
import { getCountryCallingCode } from 'react-phone-number-input';

import S from '../../../styled';
import { FormFieldContext } from '../../../store/FormFieldContext';
import { FORM_FIELD_DEFAULT_LABELS, FORM_FIELD_TYPES } from '../../../helpers/constants';

import FieldErrorMessage from './FieldErrorMessage';

export default function MobilePhoneInput({ id, required, store, params }) {
  const { t } = store;
  const { name } = params
  const labelText =
    name === FORM_FIELD_DEFAULT_LABELS[FORM_FIELD_TYPES.PHONE]
      ? t('components.FormField.typeLabels.phone')
      : name
  // country code is a two-letter ISO country code
  // see: https://www.npmjs.com/package/react-phone-number-input#country-code
  const [country, setCountry] = useState("US");
  const countryCode = '+' + getCountryCallingCode(country);
  const { formFieldData, onChangeDataPhone, errors } = useContext(FormFieldContext);

  const onCountryChange = (country) => {
    setCountry(country);
  }

  const onChange = (value) => {
    if (value === undefined) {
      return onChangeDataPhone(id, required, countryCode, countryCode);
    }
    onChangeDataPhone(id, required, countryCode, value);
  }

  return (
    <S.Flex column alignStart marginTop={14}>
      <S.Text>{labelText}</S.Text>
      <S.InputPhone
        international
        defaultCountry="US"
        countryCallingCodeEditable={false}
        value={formFieldData[id] || ""}
        onCountryChange={onCountryChange}
        onChange={onChange}/>
      <FieldErrorMessage
        id={id}
        required={required}
        errors={errors}
        fieldName={labelText}
        store={store}
      />
    </S.Flex>
  );
}
