import styled from 'styled-components';
import { motion } from 'framer-motion';
import { breakpointNames } from '../Mixins';
import { breakpoint } from 'styled-components-breakpoint';

const ProfileModule = styled(motion.div)`
  display: flex;
  flex-direction: column;
  position: relative;
  flex: 1;
  max-width: 688px;
  border: none;
  border-radius: none;
  background: none;
  margin-bottom: 14px;
  background-color: ${({ theme }) => theme.body.color.shading};

  ${breakpoint(breakpointNames.tablet)`
    height: 100%;
    margin-bottom: 0px;
    top: 0px;
    background-color: ${({ theme }) => theme.module.color.background};
    padding: 0px 14px 50px 14px;
  `};
`;

export default ProfileModule;
