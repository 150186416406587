import api from '../api';

const baseUrl = (token) => `programs/${token}`;

export const getCampaignPrograms = (token, params) => (
  api({
    method: 'get',
    url: baseUrl(token),
    params
  })
)
export const getCampaignProgram = (token, programId) => 
  api({
    method: 'get',
    url: `${baseUrl(token)}/${programId}`
  });

export const declineCampaignProgram = (token, programId) =>
  api({
    method: 'patch',
    url: `${baseUrl(token)}/${programId}/decline`
  });
