import styled, { css } from 'styled-components';

export const base = css`
	font-family: inherit;
	font-size: inherit;
	color: ${({ theme }) => theme.input.color.text};
	font-weight: ${({ theme }) => theme.input.fontWeight};
	width: 100%;
	height: 50px;
	padding: 10px;
	border-width: 1px;
	border-style: solid;
	border-color: ${({ theme }) => theme.input.color.border};
	background: ${({ theme }) => theme.input.color.background};
	border-radius: ${({ theme }) => theme.input.borderRadius};
`;

const Input = styled.input`
	${base}
	&::placeholder {
		color: ${({ theme }) => theme.input.color.placeHolder};
		opacity: 1;
		text-align: center;
	}

	&:focus {
		outline-color: ${({ theme }) => theme.input.color.outline};
	}
`;

export default Input;
