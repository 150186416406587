import React from 'react';
import S from '../../styled';

// Layout Components
import TopNav from "../../components/TopNav";
import Footer from '../../components/Footer';

// Modal-like Components
import Flash from '../../components/Flash';
import Navigation from '../../components/Navigation';
import Modal from '../../components/Modal';
import ProfileModule from '../../components/ProfileModule';

import DelegateLoginNote from "../../components/DelegateLoginNote";

export default function App(props) {
  const { store } = props;

  const socialProfiles = store?.currentProfile?.social_profiles;
  const currentUser = props.store?.currentUser;

  const isNavRenderable = currentUser;

  return (
    <S.Flex justifyCenter>
      <Modal {...props} />
      <S.Visibility mobile>
        <Navigation {...props} />
      </S.Visibility>
      <S.App paddingBottom={12}>
        {isNavRenderable && (
          <S.Nav>
            <TopNav {...props} />
            <S.Visibility tablet desktop>
              <ProfileModule {...props} socialProfiles={socialProfiles} />
              <S.BottomNav>
                <DelegateLoginNote currentUser={currentUser} store={props.store} />
              </S.BottomNav>
            </S.Visibility>
          </S.Nav>
        )}
        <S.Yield>
          {props.children}
          <Flash {...props} />
        </S.Yield>
      </S.App>
      <Footer {...props} fullWidth={!currentUser} />
    </S.Flex>
  );
}
