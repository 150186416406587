import api from '../api';

export const getKraftonNews = () =>
  api({
    headers: {
      'Service-Namespace': 'PUBG_OFFICIAL'
    },
    method: 'get',
    url: `https://api-foc.krafton.com/content/post/news?lang=en&displayLocationType=NORMAL&size=3&page=1`
  });
