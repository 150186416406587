import S from '../../styled';

export default function DelegateLoginNote(props) {
  const { currentUser } = props;
  const { t } = props.store;

  if (!currentUser || !currentUser.login_email || currentUser.login_delegation_role === "self") {
    return null;
  }

  const T = {
    delegate: t("components.DelegateLoginNote.delegate"),
    agent: t("components.DelegateLoginNote.agent"),
  };

  const email = currentUser.login_email;
  const type = T[currentUser.login_delegation_role];

  return (
    <S.Text muted lightWeight textAlignCenter>
      {`${t("components.DelegateLoginNote.loggedInAs")}: ${email} (${type})`}
    </S.Text>
  );
}
