export const taskNameWithDueDateObject = (task) => {
  if (task?.due_date) {
    return `${task?.name} (Due: ${task.due_date})`
  }
  return task?.name;
};

export const taskNameWithDueDate = (name, dueDate) => {
  if (dueDate) {
    return `${name} (Due: ${dueDate})`
  }
  return name;
};

