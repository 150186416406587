import styled from 'styled-components';
import {
  width,
  margin,
  padding,
  whiteSpace,
  textDecoration
} from '../Mixins';

const Link = styled.a`
  margin-left: ${({ network, navigation }) =>
    network || navigation ? null : '5px'};

  margin-right: ${({ network, navigation }) =>
    network || navigation ? null : '5px'};

  margin-top: ${({ network, navigation }) =>
    network || navigation ? null : '8px'};

  cursor: pointer;
  transition: all 0.3s ease-in-out;

  ${width}
  ${margin}
  ${padding}
  ${whiteSpace}
  ${textDecoration}

  font-size: ${({ theme, fontSize }) => {
    if (fontSize) return fontSize;
    if (theme.link.fontSize) return theme.link.fontSize;
    return theme.fontSize
  }};

  font-weight: ${({ theme, lightWeight, boldWeight }) => {
    if (lightWeight) return theme.text.fontWeight.light;
    if (boldWeight) return theme.text.fontWeight.bold;
    return theme.fontWeight;
  }};

  font-family: ${({ theme, paragraph, label }) => {
    if (label) return `'IBM Plex Sans', sans-serif !important`;
    if (theme.text.fontFamily) return `${theme.text.fontFamily}, sans-serif !important`;
    return 'inherit';
  }};

  color: ${({ color, muted, theme }) => {
    if (color) return color;
    if (muted) return theme.text.color.muted;
    return 'unset';
  }};

  width: ${({ navigation }) => {
    return navigation ? '100%' : null;
  }};

  text-align: ${({ navigation }) => {
    return navigation ? 'center' : null;
  }};

  padding: ${({ navigation, theme }) => {
    return navigation ? theme.navigation.padding : null;
  }};

  &:link {
    color: ${({ theme, color }) => color || theme.link.color.default};
  }

  &:visited {
    color: ${({ theme }) => theme.link.color.visited};
  }

  &:hover {
    color: ${({ theme }) => theme.link.color.hover};
  }

  &:active {
    color: ${({ theme }) => theme.link.color.active};
  }
`;

export default Link;
