import React, { useContext } from 'react';
import S from '../../../styled';
import styled from 'styled-components';
import { FormFieldContext } from '../../../store/FormFieldContext';

import FieldErrorMessage from './FieldErrorMessage';

const FormFieldStyledTextArea = styled(S.Textarea)`
  border-width: 1px;
  border-color: ${({ theme }) => theme.input.color.border};
	border-style: solid;
`

export default function TextAreaInput({ id, required, store, params }) {
  const { formFieldData, onChangeData, errors } = useContext(FormFieldContext);
  const dataType  = params.data_type;
  const name = params.name;
  const fieldType = params.field_type;

  return (
    <S.Flex column alignStart marginTop={14}>
      <S.Text>{name}</S.Text>
      <FormFieldStyledTextArea
        name={name}
        rows={3}
        maxLength={1000}
        value={formFieldData[id]}
        type={dataType}
        onChange={(e) => onChangeData(id, fieldType, required, e.target.value)}
      />
      <FieldErrorMessage
        id={id}
        required={required}
        errors={errors}
        fieldName={name}
        store={store}
      />
    </S.Flex>
  );
}
