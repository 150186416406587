import React, { useState, useEffect, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import S from '../../styled';

// Routes
import { routeNames } from '../../routing/routes';

// Components
import Page from '../../components/Page';
import Module from '../../components/Module';
import HtmlContent from '../../components/HtmlContent';

// Requests
import { getPartnershipPrivacyPolicy } from '../../data/requests/partnership';

// Helpers
import navigateTo from '../../helpers/navigateTo';

export default function PrivacyPolicy(props) {
  const history = useHistory();
  const { token, t } = props.store;
  const { name: brand_name, portal_brand_name } = props.store.account;

  const [privacyPolicy, setPrivacyPolicy] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [isEmpty, setIsEmpty] = useState(false);

  const navigateToDashboard = (e) => {
    e.preventDefault();
    navigateTo(history, routeNames.dashboard, token);
  };

  const getPrivacyPolicy = useCallback(async () => {
    if (!token) return;

    let data;
    try {
      setIsLoading(true);
      data = await getPartnershipPrivacyPolicy(token);
      setPrivacyPolicy(data.privacy_policy);
    } catch (error) {
      console.error('getPartnershipPrivacyPolicy: ', error);
    } finally {
      console.log('getPartnershipPrivacyPolicy: ', { data });
      setIsEmpty(!data?.privacy_policy);
      setIsLoading(false);
    }
  }, [token]);

  useEffect(() => {
    getPrivacyPolicy();
  }, [getPrivacyPolicy]);

  const emptyContent = (
    <S.Text muted>
      {portal_brand_name || brand_name} {t('pages.PrivacyPolicy.privacyPolicyNotSetUp')}
    </S.Text>
  );

  return (
    <Page title="Privacy Policy" isLoading={isLoading} isProblem={!privacyPolicy}>
      <Module
        title="Privacy Policy"
        isLoading={isLoading}
        isEmpty={isEmpty}
        emptyContent={emptyContent}
      >
        <S.Flex overflow="auto" alignStart flex={1}>
          <HtmlContent content={privacyPolicy} />
        </S.Flex>
        <S.Flex justifyBetween marginTop={20} >
          <S.Link marginLeft={10} onClick={navigateToDashboard}>{t('shared.back')}</S.Link>
        </S.Flex>
      </Module>
    </Page>
  );
}
