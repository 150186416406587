const isPresent = (value) => {
  const valueType = typeof value
  switch (valueType) {
    case 'number':
      return true;
    case 'string':
      return value.trim().length;
  }
}

export default isPresent;
