import React from 'react';
import S from '../../styled';

// Icons
import {
	Times as TimesIcon
} from '@styled-icons/fa-solid';

export default function Modal(props) {
	const { renderModal, modalTitle, modalContent, modalContentUnsafe, t } = props.store;
	const { setModal } = props.setStore;

	const closeModal = () => setModal(false);

	const renderContent = () => {
		if (!modalContent && !modalContentUnsafe) {
			const problemText = `${t('components.Modal.problemLoadingContent')} ${modalTitle} ${t('components.Modal.content')}.`;
			return (
				<S.Flex column alignStart flex={1} gap={14}>
					<S.Text smallSize>
						{problemText}
					</S.Text>
					<S.Text smallSize>
						{t('components.Modal.pleaseTryAgainLater')}
					</S.Text>
				</S.Flex>
			);
		}

		const contentType = typeof modalContent;
		const contentTypeUnsafe = typeof modalContentUnsafe;
		if (['string', 'number', 'boolean'].includes(contentType)) {
			return <S.Text smallSize>{modalContent}</S.Text>;
		} else if (contentType === 'function') {
			return modalContent();
		} else if (['string', 'number', 'boolean'].includes(contentTypeUnsafe)) {
			return <S.Flex column alignStart justifyStart whiteSpacePreWrap small dangerouslySetInnerHTML={{ __html: modalContentUnsafe }}></S.Flex>
		} else if (modalContent) {
			return <S.Text smallSize>{modalContent}</S.Text>;
		} else if (modalContentUnsafe) {
			return <S.Flex>{modalContentUnsafe}</S.Flex>;
		}
	}

	return (
		<>
			<S.ScreenOverlay render={renderModal} onClick={closeModal} />
			<S.Modal render={renderModal}>
				<S.Flex column gap={14} flex={1}>
					<S.Flex row justifyBetween marginBottom={14}>
						<S.Text largeSize as="h2">
							{modalTitle}
						</S.Text>
						<S.SvgWrapper
							clickable
							height={24}
							width={24}
							onClick={closeModal}
						>
							<TimesIcon />
						</S.SvgWrapper>
					</S.Flex>
					{renderContent()}
				</S.Flex>
			</S.Modal>
		</>
	);
}
