import {
	DELEGATE_RELATIONSHIP_LOCAL_STORAGE_NAME
} from './constants';

export const getDelegateRelationshipIdFromLocalStorage = () =>
	localStorage.getItem(DELEGATE_RELATIONSHIP_LOCAL_STORAGE_NAME);

export const setDelegateRelationshipIdFromLocalStorage = (id) =>
  localStorage.setItem(DELEGATE_RELATIONSHIP_LOCAL_STORAGE_NAME, id);

export const removeDelegateRelationshipIdFromLocalStorage = () =>
  localStorage.removeItem(DELEGATE_RELATIONSHIP_LOCAL_STORAGE_NAME);
