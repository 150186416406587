import { useEffect, useState } from 'react';

export default function CometChatWidgetClient({ store: { currentUser, account } }) {
  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    const isCometChatWidgetLoaded = () => {
      return typeof window.CometChatWidget !== 'undefined';
    };

    const loadScript = () => {
      return new Promise((resolve, reject) => {
        const script = document.createElement('script');
        script.src = "https://widget-js.cometchat.io/v3/cometchatwidget.js";
        script.async = true;

        script.onload = resolve;
        script.onerror = reject;

        document.body.appendChild(script);
      });
    };

      const initCometChatWidget = async (user, account) => {
        try {
          const { comet_chat_enabled, comet_chat_app_id, comet_chat_auth_key, comet_chat_widget_id } = account;

          if(!comet_chat_enabled) return;

          if (!isCometChatWidgetLoaded()) {
            await loadScript();
          }

          if (!comet_chat_enabled || !comet_chat_app_id || !comet_chat_auth_key || !comet_chat_widget_id) {
              console.error("Error: The account is missing required CometChat configuration");
              return;
          }

          if (window.CometChatWidget && !initialized) {
            window.CometChatWidget.init({
              appID: comet_chat_app_id,
              appRegion: 'US',
              authKey: comet_chat_auth_key
            }).then(() => {
              window.CometChatWidget.login({
                uid: `portaluser${currentUser.id}`
              }).then(() => {
                window.CometChatWidget.launch({
                  widgetID: comet_chat_widget_id,
                  docked: true,
                  alignment: 'right',
                  roundedCorners: true,
                  height: '450px',
                  width: '400px',
                  defaultType: 'user'
                });
              });
            });
            setInitialized(true);
          }
        } catch (error) {
          console.error("Error loading the CometChatWidget script:", error);
        }
      };

      if (currentUser && account) {
        initCometChatWidget(currentUser, account);
      }
  }, [currentUser, account]);

  return <div className='App'></div>;
}
