import React, { useState, useEffect, useCallback } from 'react';
import S from '../../styled';

// Routes
import { routeNames } from '../../routing/routes';

// Components
import Page from '../../components/Page';
import NetworkIcon from '../../components/NetworkIcon';

// Requests
import { getCurrentUserProfilesByToken } from '../../data/requests/profile';
import { confirmProfiles, lockProfiles } from '../../data/requests/user';

// Helpers
import navigateTo from '../../helpers/navigateTo';

export default function ProfileReview(props) {
	const { history } = props;
	const { token, t } = props.store;
	const { setCurrentUser } = props.setStore;

	const [profile, setProfile] = useState({});
	const [isLoading, setIsLoading] = useState(true);

	const loadProfile = useCallback(async () => {
		if (!token) return;

		try {
			setIsLoading(true);
			const data = await getCurrentUserProfilesByToken(token);
			setProfile(data);
		} catch (error) {
			console.error('loadProfile: ', error);
		} finally {
			setIsLoading(false);
		}
	}, [token]);

	const confirm = async () => {
		try {
			const data = await confirmProfiles(token);
			setCurrentUser(data);
		} catch (error) {
			console.error('confirmProfiles: ', error);
		} finally {
			navigateTo(history, routeNames.dashboard, token);
		}
	}

	const reject = async () => {
		try {
			const data = await lockProfiles(token);
			setCurrentUser(data);
		} catch (error) {
			console.error('lockProfiles: ', error);
		} finally {
			navigateTo(history, routeNames.invalid, token);
		}
	};

	useEffect(() => {
		loadProfile();
	}, [loadProfile]);


	const socialProfiles = [...(profile?.social_profiles || [])];
	const filteredSocialProfiles = socialProfiles.filter((profile) => profile.network && profile.username);
	if (socialProfiles.length !== filteredSocialProfiles.length) {
		console.error('Some of the social profiles are missing an id, network, or username.');
	}

	return (
		<Page isLoading={isLoading} isFailure={!profile}>
			<S.Flex column gap={14}>
				<S.Flex column gap={14} width={332}>
					<S.Flex column gap={14} marginBottom={24}>
						<S.Text>{t('pages.ProfileReview.welcomeToCommunity')}</S.Text>
						<S.Text>{t('pages.ProfileReview.areTheseYourProfiles')}</S.Text>
					</S.Flex>
					<S.Flex
						column
						alignStart
						justifyStart
						gap={14}
						paddingLeft={34}
					>
						{filteredSocialProfiles.map(({ network, username }) => (
							<S.Flex
								key={`${username}-${network}`}
								row
								hoverable
								alignCenter
								justifyStart
								gap={14}
								paddingLeft={24}
								paddingRight={24}
							>
								<NetworkIcon network={network} clickable={false} />
								<S.Text>{username}</S.Text>
							</S.Flex>
						))}
					</S.Flex>
				</S.Flex>
				<S.Flex row gap={14} marginTop={44} width={332}>
					<S.Button secondary onClick={reject}>
						{t('shared.no')}
					</S.Button>
					<S.Button onClick={confirm}>{t('shared.yes')}</S.Button>
				</S.Flex>
			</S.Flex>
		</Page>
	);
}
