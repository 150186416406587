import React from 'react';
import S from '../../styled';
import Metric from '../Metric';

/*
* Generates a row of Metrics.
* - Either metric data or a placeholder can be passed in
* - A placeholder is an item in the row that isn't a metric. i.e. +, =, etc
*/
export default function MetricRow(props) {
  const { between, center } = props;

  return (
    <S.Flex row justifyBetween={between} justifyCenter={center} flex={1}>
      {props.metrics && props.metrics.map(metric => {
        if (metric.placeholder) return metric.name
        return (
          <S.Flex paddingLeft={center ? 20 : 0} paddingRight={center ? 20 : 0}>
            <Metric
              largeSize={metric.largeSize}
              hoverable={metric.hoverable}
              name={metric.name}
              value={metric.value}
            />
          </S.Flex>
        )
      })}
    </S.Flex>
  );
}
