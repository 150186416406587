import React from 'react';
import S from '../../styled';

// Icons
import NetworkIcon from '../NetworkIcon';

// Routes
import { routeNames } from '../../routing/routes';

// Helpers
import navigateTo from '../../helpers/navigateTo';
import humanizeNumber from '../../helpers/humanizeNumber';
import { Trans } from 'react-i18next';
import HtmlContent from '../HtmlContent';

export default function ProfileRow(props) {
  const { profile, t, history, token } = props;

  const navigateToNetworks = (e) => {
    e.preventDefault();
    navigateTo(history, routeNames.networkConnect, token);
  };

  const statColumn = (stat, label) => {
    return (
      <S.Flex column alignCenter justifyCenter width={10}>
        <S.Text smallSize textAlignCenter>{humanizeNumber([stat || 0])}</S.Text>
        <S.Text smallSize lightWeight textAlignCenter>
          <HtmlContent content={label} flex={0} textAlignLeft />
        </S.Text>
      </S.Flex>
    )
  };

  const profileStats = (p) => {
    switch (p.network) {
      case 'twitch':
        return (
          <>
            {statColumn(p.max_viewers, t('components.PerformanceModule.maxViewers'))}
            {statColumn(p.average_viewers, t('components.PerformanceModule.avgViewers'))}
            {statColumn(p.hours_watched, t('components.PerformanceModule.hoursWatched'))}
          </>
        )
      case 'twitter':
        return (
          <>
            {statColumn(p.average_engagements, t('components.PerformanceModule.avgEngagements'))}
            {statColumn(p.average_favorites, t('components.PerformanceModule.avgLikes'))}
            {statColumn(p.average_shares, t('components.PerformanceModule.avgRetweets'))}
          </>
        )
      case 'facebook':
        return (
          <>
            {statColumn(p.average_engagements, t('components.PerformanceModule.avgEngagements'))}
            {statColumn(p.average_favorites, t('components.PerformanceModule.avgLikes'))}
            {statColumn(p.average_shares, t('components.PerformanceModule.avgShares'))}
          </>
        )
      default:
        return (
        <>
          {statColumn(p.average_engagements, t('components.PerformanceModule.avgEngagements'))}
          {statColumn(p.average_favorites,t('components.PerformanceModule.avgLikes'))}
          {statColumn(p.average_comments, t('components.PerformanceModule.avgComments'))}
        </>
      )
    };
  };

  return (
    <S.Flex row justifyBetween marginBottom={20} flex={0}>
      <S.Flex column alignStart maxWidth={45}>
        <NetworkIcon
          key={`${profile.network}`}
          network={profile.network}
          profileUrl={profile.raw_url}
          flex={0}
        />
      </S.Flex>
      {(profile.connected || profile.network === 'tiktok') ? (
        profileStats(profile)
      ) : (
        <S.Flex column alignCenter justifyCenter>
          <S.Text padding={10} lightWeight textAlignCenter smallSize>
            {/* keep this line break below (and leading space in translation files) or it breaks */}
            <Trans t={t} i18nKey="components.PerformanceModule.connectProfile"><br></br>
              <S.Link textDecoration="underline" href="#" onClick={navigateToNetworks}>Connect your profile</S.Link> to see metrics.
            </Trans>
          </S.Text>
        </S.Flex>
      )}
    </S.Flex>
  )
}
