import styled from 'styled-components';
import { darken } from 'polished';
import { clickable, width } from '../Mixins';

// Styled components
import { base } from './Input';

const InputFake = styled.div`
	${base}
	${clickable}
	${width}
	transition: all 0.3s ease-in-out;
	color: ${({ theme }) => theme.input.color.text};

	&:hover {
		background-color: ${({ theme, hoverable }) =>
			hoverable ? darken(0.05, theme.input.color.background) : null
		};
	}
	height: ${({ height }) => height || 'unset' }
`;

export default InputFake;
