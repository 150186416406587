import React from 'react';
import _ from 'lodash';
import S from '../../styled';

import MobilePhoneInput from './Components/MobilePhoneInput';
import TextInput from './Components/TextInput';
import TextAreaInput from './Components/TextAreaInput';
import SectionBreak from './Components/SectionBreak';
import TermsInput from './Components/TermsInput';
import RadioGroupInput from './Components/RadioGroupInput'
import CheckboxGroupInput from './Components/CheckBoxGroupInput'
import SelectInput from './Components/SelectInput'
import FileUploadInput from './Components/FileUploadInput';
import BirthdayInput from './Components/BirthdayInput';
import CheckBoxInput from './Components/CheckBoxInput';

import HtmlContent from '../HtmlContent';

import { FORM_FIELD_TYPES as FIELD_TYPES } from '../../helpers/constants';

export default function FormField(props) {
  const formFields = props.formFields
  const store = props.store
  const hasAlreadyApplied = props.hasAlreadyApplied

  const MAX_LENGTHS = {
    ZIP_CODE: 10,
    POSTAL_CODE: 400,
    TEXT_INPUT: 200,
    PASSWORD: 64,
  };

  const specialComponentMap = {
    [FIELD_TYPES.PHONE]: MobilePhoneInput,
    [FIELD_TYPES.CUSTOM_TEXT_AREA]: TextAreaInput,
    [FIELD_TYPES.SECTION_BREAK_LABEL]: SectionBreak,
    [FIELD_TYPES.TERMS_AND_CONDITIONS]: TermsInput,
    [FIELD_TYPES.CUSTOM_RADIO]: RadioGroupInput,
    [FIELD_TYPES.CUSTOM_CHECKBOX_GROUP]: CheckboxGroupInput,
    [FIELD_TYPES.CUSTOM_FILE]: FileUploadInput,
  };

  const selectFields = [
    FIELD_TYPES.COUNTRY,
    FIELD_TYPES.GENDER,
    FIELD_TYPES.LABELS_DROPDOWN,
    FIELD_TYPES.CUSTOM_DROPDOWN
  ];

  const textInputFields = [
    FIELD_TYPES.ORGANIZATION,
    FIELD_TYPES.CITY,
    FIELD_TYPES.STATE,
    FIELD_TYPES.STREET,
    FIELD_TYPES.LOCATION,
    FIELD_TYPES.CUSTOM_WEBSITE,
    FIELD_TYPES.POSTAL_CODE,
    FIELD_TYPES.ZIP_CODE,
    FIELD_TYPES.TAX_ID,
    FIELD_TYPES.CUSTOM_SINGLE_LINE,
    FIELD_TYPES.PASSWORD
  ];

  const checkboxFields = [
    FIELD_TYPES.RULES_AGREEMENT,
    FIELD_TYPES.NEWSLETTER_SUBSCRIBE,
    FIELD_TYPES.CUSTOM_CHECKBOX
  ];

  const descriptiveText = (item) => {
    return <HtmlContent
      key={item.id}
      content={item.name}
      useThemeFonts
    />
  }

  const checkboxes = (item) => {
    return (
      <CheckBoxInput
        key={item.id}
        id={item.id}
        fieldType={item.field_type}
        name={item.name}
        required={item.required}
        store={store}
      />
    )
  }

  const birthdayInput = (item) => {
    return (
      <BirthdayInput
        key={item.id}
        id={item.id}
        fieldType={item.field_type}
        name={item.name}
        required={item.required}
        metadata={item.metadata}
        store={store}
      />
    )
  }

  const renderTextInput = (item) => {
    const maxLength = item.field_type === FIELD_TYPES.PASSWORD ? MAX_LENGTHS.PASSWORD : MAX_LENGTHS.TEXT_INPUT;
    return <TextInput
      key={item.id}
      id={item.id}
      dataType={item.data_type}
      fieldType={item.field_type}
      name={item.name}
      maxLength={maxLength}
      required={item.required}
      minLength={item.field_type === FIELD_TYPES.PASSWORD ? 7 : undefined}
      store={store}
    />;
  };

  const renderSelectInput = (item) => {
    return <SelectInput
      key={item.id}
      id={item.id}
      name={item.name}
      required={item.required}
      fieldName={item.field_name}
      fieldType={item.field_type}
      options={item.options}
      store={store}/>;
  };

  const renderInput = (item) => {
    item.field_type = _.camelCase(item.field_type);
    const Component = specialComponentMap[item.field_type];
    if (Component) {
      return <Component key={item.id} id={item.id} required={item.required} params={item} store={store}/>;
    }

    if (item.field_type === FIELD_TYPES.BIRTHDAY) {
      return birthdayInput(item);
    }

    if(item.field_type === FIELD_TYPES.DESCRIPTIVE_TEXT) {
      return descriptiveText(item);
    }

    if (checkboxFields.includes(item.field_type)) {
      return checkboxes(item);
    }

    if (textInputFields.includes(item.field_type)) {
      return renderTextInput(item);
    }

    if (selectFields.includes(item.field_type)) {
      return renderSelectInput(item);
    }

    return null;
  };

  const shouldRender = (Boolean(formFields.length) && !hasAlreadyApplied);

  const inputItems = (
    <S.Flex column alignStart>
      {formFields.map(item => (
        renderInput(item)
      ))}
    </S.Flex>
  )
  return (shouldRender && inputItems);
}
