import React from 'react';

// Feature Flag
import { useFeatureFlag } from '../../helpers/useFeatureFlag';

// Components
import Page from '../../components/Page';
import PerformanceModule from '../../components/PerformanceModule';
import ConversionsModule from '../../components/ConversionsModule';
import MyProfilesModule from '../../components/MyProfilesModule';

export default function Performance(props) {
  const { store, setStore } = props;
  const { t } = store;

  const portalPerformanceMetrics = useFeatureFlag(store, 'portal_performance_metrics');

  return (
    <Page title={t('navigation.performance')} flexStart justifyStart>
      {portalPerformanceMetrics && <MyProfilesModule store={store} setStore={setStore} />}
      <PerformanceModule store={store} />
      <ConversionsModule store={store} setStore={setStore} />
    </Page>
  );
}
