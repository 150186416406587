import { useEffect } from 'react';
import { Route, useHistory } from 'react-router-dom';

// Routing
import {
  shouldNavigateToNotFoundPage,
  shouldNavigateToInvalidPage,
  shouldNavigateToProfileReviewPage,
  shouldNavigateToLastPrivateRouteVisitedOrDashboardPage,
  shouldNavigateToLoginPage,
  shouldNavigateToDelegateRelationshipSelectionPage,
} from './redirect';

// Helpers
import getUrlToken from '../helpers/getUrlToken';

const PublicRoute = (props) => {
  const history = useHistory();

  const { layout: RouteLayout, page: RoutePage, exact, strict, ...otherProps } = props;
  const { authedStatus, currentUser, token, lastPrivateRouteVisited } = props.store;

  useEffect(() => {
    const shouldNavigateParams = {
      history,
      portal_token: token || getUrlToken(),
      currentUser,
      authedStatus,
      lastPrivateRouteVisited,
      isPrivate: false,
    };
    shouldNavigateToNotFoundPage(shouldNavigateParams);
    shouldNavigateToInvalidPage(shouldNavigateParams);
    shouldNavigateToProfileReviewPage(shouldNavigateParams);
    shouldNavigateToLastPrivateRouteVisitedOrDashboardPage(shouldNavigateParams);
    shouldNavigateToLoginPage(shouldNavigateParams);
    shouldNavigateToDelegateRelationshipSelectionPage(shouldNavigateParams);
  }, [authedStatus, currentUser, history, token, lastPrivateRouteVisited]);

  return (
    <Route
      strict={strict}
      exact={exact}
      path={props.path}
      render={(routeProps) => (
        <RouteLayout {...otherProps} {...routeProps}>
          <RoutePage {...otherProps} {...routeProps} />
        </RouteLayout>
      )}
    />
  );
};

export default PublicRoute;
