import React from 'react';
import S from '../../styled';

import { useTranslation } from 'react-i18next';


export default function Page(props) {
  const {
    isLoading,
    isFailure,
    title,
    children,
    clear,
    flexDirection = "column",
    ...otherProps
  } = props

  const { t } = useTranslation();

  if (isLoading) {
    return (
      <S.Page clear={clear} {...otherProps}>
        <S.Flex row alignCenter justifyCenter>
          <S.Spinner />
        </S.Flex>
      </S.Page>
    );
  } else if (isFailure) {
    return (
      <S.Page {...otherProps}>
        <S.Flex row alignCenter justifyCenter>
          <S.Text>
            {t('components.Page.problemTryAgain')}
          </S.Text>
        </S.Flex>
      </S.Page>
    );
  } else {
    return (
      <S.Page clear={clear} {...otherProps}>
        {title && <S.DashboardLabel>{title}</S.DashboardLabel>}
        {children && (
          <S.Flex
            gap={24}
            flex={0}
            flexDirection={flexDirection}
            alignStart
            justifyStart
            flexWrap
          >
            {children}
          </S.Flex>
        )}
      </S.Page>
    );
  }
}
