import styled from 'styled-components';
import { breakpointNames } from '../Mixins';
import { breakpoint } from 'styled-components-breakpoint';

const BottomNav = styled.div`
  position: fixed;
  bottom: 50px;
  left: 0;
  padding: 14px;
  background-color: transparent;
  
  ${breakpoint(breakpointNames.tablet, breakpointNames.desktop)`
    width: 283px;
  `}

  ${breakpoint(breakpointNames.desktop)`
    width: 343px;
  `}
`;

export default BottomNav;
