import React from 'react';
import S from '../../styled';
import ContentForReview from './ContentForReview';
import moment from 'moment';

// Components
import Module from '../Module';

import { ChevronLeft } from '@styled-icons/fa-solid';

export default function PendingApprovalContentReview({ contentReview, isLoading, store, goBack, isPostContentTask, fileOrContent }) {
  const { t } = store;
  const submissionReviewedNotice = t('components.PendingApprovalContentReview.submissionReviewedNotice')
  const timestamp = moment(contentReview.created_at).format('MMM Do YYYY, h:mm A');

  const moduleTitle = (
    <S.Flex flex={1}>
      <S.Flex flex={0}>
        <S.Link marginLeft={10} onClick={goBack}><ChevronLeft size={25} /></S.Link>
      </S.Flex>
      <S.Flex flex={1} whitespaceNoWrap paddingRight={40}>
        <S.Text largeSize boldWeight capitalize>
          {t('components.PendingApprovalContentReview.submitted')} {fileOrContent}
        </S.Text>
      </S.Flex>
    </S.Flex>
  );

  return (
    <Module
      flex={1}
      titleBlock={moduleTitle}
      isLoading={isLoading}
    >
      <S.Flex column>
        <S.Flex row padding={10} flex={1} textAlign='center'>
          <ContentForReview flex={1} contentReview={contentReview} isPostContentTask={isPostContentTask} />
        </S.Flex>
        <S.Flex row padding={10}>
          <S.Text mediumWeight smallSize paragraph>
            {t('components.PendingApprovalContentReview.uploaded')}: {timestamp}
          </S.Text>
        </S.Flex>
        {contentReview?.text && (
          <S.Flex row padding={10} margin={10} borderRadius={5} highlight flex={1} tabletStyles={{ width: '70%' }} desktopStyles={{ width: '70%' }}>
            <S.Text smallSize lightWeight paragraph flex={1} highlight>
              {contentReview?.text}
            </S.Text>
          </S.Flex>
        )}
        <S.Flex row textAlignCenter padding={10}>
          <S.Text mediumWeight smallSize paragraph>
            {submissionReviewedNotice}
          </S.Text>
        </S.Flex>
      </S.Flex>
    </Module>
  );
}
