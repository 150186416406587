import api from '../api';

export const getPaymentRailsWidgetIframeUrl = (token, theme) =>
	/*  Adding color fields is also optional, used to override the
		color settings set in the dashboard. Note that these overrides must
		be valid CSS compatible colors. See PaymentRails for options:
		docs.paymentrails.com/widget
	*/
	api({
		method: 'get',
		url: 'payments/payment_rails_widget',
		params: {
			account_portal_token: token,
			colors: {
				'colors.heading': theme.label.color.default,
				'colors.inputText': theme.text.color.default,
				'colors.inputBorder': theme.input.color.border,
				'colors.text': theme.text.color.default,
				'colors.subText': theme.text.color.muted,
				'colors.background': theme.module.color.background,
				'colors.primary': theme.button.color.default,
				'colors.border': theme.border.color.default,
				'colors.error': theme.input.color.error
			}
		}
	});
