import React, { useState, useEffect, useCallback } from 'react';
import S from '../../styled';

// Components
import Module from '../Module';
import News from './News';
import pubg from '../../../src/assets/pubg/pubg.png'

// Requests
import { getKraftonNews } from '../../data/requests/kraftonNewsFeed';


export default function NewsFeedModule(props) {
  const { setStore, flex, store } = props;
  const { t } = store;

  const [isMounted, setIsMounted] = useState(true);
  const [moduleData, setModuleData] = useState([]);
  const [moduleIsLoading, setModuleIsLoading] = useState(true);
  const [isEmpty, setIsEmpty] = useState(false);

  const newsUrl = 'http://pubg.com/en/news';

  const loadModuleData = useCallback(async () => {
    let data;
    try {
      setModuleIsLoading(true);
      data = await getKraftonNews();
      setModuleData(data._embedded.post);
    } catch (error) {
      console.error('loadModuleData: ', error);
    } finally {
      setIsEmpty(!data?._embedded?.post?.length);
      if (isMounted) setModuleIsLoading(false);
    }
  }, [isMounted]);

  useEffect(() => {
    loadModuleData();
    return () => setIsMounted(false);
  }, [loadModuleData]);

  const topRightContent = (
    <S.Flex row alignEnd justifyEnd flex={1}>
      <S.Link
        href={newsUrl}
        whiteSpaceNoWrap
        muted
        hoverable
        clickable
        fontSize={14}
        flex={0}
        target="_blank"
        rel="noreferrer"
      >
        {t('components.NewsFeedModule.viewAllNews')}
      </S.Link>
    </S.Flex>
  );

  const emptyContent = (
    <S.Text muted>
       {t('components.NewsFeedModule.noNewsToDisplay')}
    </S.Text>
  );

  return (
    <Module
      title={t('components.NewsFeedModule.newsFeed')}
      isLoading={moduleIsLoading}
      isEmpty={isEmpty}
      flex={flex}
      topRightContent={topRightContent}
      emptyContent={emptyContent}
    >
      <S.Flex column justifyStart>
        {moduleData && moduleData.map((newsPost, i) => (
          <News
           key={`${newsPost.postId}-${i}`}
           postId={newsPost.postId}
           newsPost={newsPost}
           setStore={setStore}
           title={newsPost.title}
           imageUrl={newsPost?.images[0]?.imageUrl || pubg}
           newsUrl={newsUrl}
           summary={newsPost?.summary || newsPost.title}
          />
        ))}
      </S.Flex>
    </Module>
  );
}
