import React from 'react';
import S from '../../styled';
import moment from 'moment';

import { ChevronRight } from '@styled-icons/fa-solid';

export default function NameDatesRow( { record, navigateToRecord } ) {
  const start = moment(record.start_date || record.start_time).format('MMM Do YYYY');
  const end = moment(record.end_date || record.end_time).format('MMM Do YYYY');

  return (
    <S.Flex 
      row
      justifyCenter
      marginBottom={20}
      clickable
      hoverable
      onClick={() => navigateToRecord(record)}
    >
      <S.Flex flex={1} column alignEnd justifyCenter>
        <S.Flex row flex={0}>
          <S.Text truncate>{record.name}</S.Text>
        </S.Flex>
        <S.Flex row flex={0}>
          <S.Text lightWeight truncate>{start} - {end}</S.Text>
        </S.Flex>
      </S.Flex>
      <S.Flex flex={1} column alignEnd justifyCenter>
        <ChevronRight size={15} />
      </S.Flex>
    </S.Flex>
  );
};
