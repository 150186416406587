import React from 'react';
import { useHistory } from 'react-router-dom';

// Styles
import S from '../../styled';

// Icons
import { ChevronLeft } from '@styled-icons/fa-solid';

// Components
import Page from '../../components/Page';
import Module from '../../components/Module';
import CampaignProgramModule from '../../components/CampaignProgramsModule';

export default function DeclinedCampaignProgramsPage(props) {
  const { store } = props;
  const { t } = store
  const history = useHistory();

  const goBack = (e) => {
    e.preventDefault();
    history.goBack();
  };

  const titleContent = (
    <S.Flex flex={1}>
      <S.Link marginTop={0} onClick={goBack}><ChevronLeft size={25} /></S.Link>
      <S.Text largeSize boldWeight capitalize whitespaceNoWrap paddingRight={40}>
        {t('pages.CampaignProgramSignUp.declinedCampaigns')}
      </S.Text>
    </S.Flex>
  )

  return (
    <Page alignStart justifyStart>
      <Module>
        <CampaignProgramModule
          store={store}
          title={titleContent}
          available={true}
          declined={true}
        />
      </Module>
    </Page>
  );
};
