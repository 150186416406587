import styled from 'styled-components';
import { motion } from 'framer-motion';
import { gap, breakpointNames, breakpoint } from '../Mixins';

const variants = {
  initial: {
    opacity: 0,
  },
  in: {
    opacity: 1,
    transition: { duration: 1 },
  },
  out: {
    opacity: 0,
  },
};

const Yield = styled(motion.div).attrs(() => ({
  initial: "initial",
  animate: "in",
  exit: "out",
  variants
}))`
  
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  flex: 1;
  width: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  min-height: 100vh;
  position: relative;
  ${gap}
  background-color: ${({ theme }) => theme.body.color.shading};

  ${breakpoint(breakpointNames.tablet, `
    flex-direction: row;
    flex: 1;
  `)};
`;

export default Yield;
