import styled from 'styled-components';
import 'react-phone-number-input/style.css';
import PhoneInput from 'react-phone-number-input';

const InputPhone = styled(PhoneInput)`
  --PhoneInput-color--focus: ${({ theme }) => theme.input.color.outline};
  --PhoneInputCountrySelectArrow-color: ${({ theme }) => theme.input.color.text};
  --PhoneInputCountrySelectArrow-color--focus: var(--PhoneInput-color--focus);
  --PhoneInputCountryFlag-borderColor: ${({ theme }) => theme.input.color.border};
  --PhoneInputCountryFlag-borderColor--focus: var(--PhoneInput-color--focus);
  --PhoneInputCountryFlag-backgroundColor--loading: ${({ theme }) => theme.input.color.outline};
  width: 100%;
  height: 50px;
  padding: 10px;

  .PhoneInputInput {
    border-width: 1px;
    border-style: solid;
    font-family: inherit;
    font-size: inherit;
    height: 50px;
    padding-left: 5px;
    background: ${({ theme }) => theme.input.color.background};
    border-radius: ${({ theme }) => theme.input.borderRadius};
    border-color: ${({ theme }) => theme.input.color.border};
    color: ${({ theme }) => theme.input.color.text};
    font-weight: ${({ theme }) => theme.input.fontWeight};

    &:focus {
		  outline-color: ${({ theme }) => theme.input.color.outline};
	  }
  }
`;

export default InputPhone;
