import React, { useContext } from 'react';
import S from '../../../styled';
import { FormFieldContext } from '../../../store/FormFieldContext';

import FieldErrorMessage from './FieldErrorMessage';

export default function CheckboxGroupInput({ id, required, store, params }) {
  const { options, name } = params
  const fieldType = params.field_type
  const { formFieldData, onChangeData, errors } = useContext(FormFieldContext);

  const splitOptions = options?.split("\n") || [];

  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;

    let currentlySelected = formFieldData[id] || [];
    if (checked) {
      currentlySelected = [...currentlySelected, name];
    } else {
      currentlySelected = currentlySelected.filter((checkbox) => checkbox !== name)
    }
    onChangeData(id, fieldType, required, currentlySelected);
  };

  const isChecked = (name) => {
    return (formFieldData[id] || []).includes(name);
  }

  const checkboxOption = (option, idx) => {
    return (
      <S.Flex key={option + idx} gap={16} flex={0}>
        <S.Checkbox name={option} onChange={handleCheckboxChange} checked={isChecked(option)}/>
        <S.Text as="p" maxWidth marginTop={3}>
          {option}
        </S.Text>
      </S.Flex>
    )
  }

  const mapOptions = (optionsList) => {
    if (options === "") {
      return null;
    }

    return optionsList.map((option, idx) => (
      checkboxOption(option, idx)
    ));
  }

  return (
    <S.Flex column alignStart marginTop={14}>
      <fieldset>
        <legend>{name}</legend>
        {mapOptions(splitOptions)}
      </fieldset>
      <FieldErrorMessage
        id={id}
        required={required}
        errors={errors}
        fieldName={name}
        store={store}
      />
    </S.Flex>
  );
}
