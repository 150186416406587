// Layouts
import AppLayout from '../layouts/App';

// Pages
import CampaignBriefTaskPage from '../pages/CampaignBriefTask';
import ContentStatusPage from '../pages/ContentStatus';
import DashboardPage from '../pages/Dashboard';
import FileRequestTaskPage from '../pages/FileRequestTask';
import InvalidPage from '../pages/Invalid';
import LoginPage from '../pages/Login';
import NetworkConnectPage from '../pages/NetworkConnect';
import NotFoundPage from '../pages/NotFound';
import CampaignsPage from '../pages/Campaigns';
import CampaignSummaryPage from '../pages/CampaignSummary';
import PaymentsPage from '../pages/Payments';
import PerformancePage from '../pages/Performance';
import PersonalInfoPage from '../pages/PersonalInfo';
import PostContentTaskPage from '../pages/PostContentTask';
import PrivacyPolicyPage from '../pages/PrivacyPolicy';
import ProductLinkPage from '../pages/ProductLink';
import ProfileReviewPage from '../pages/ProfileReview';
import PromotionPage from '../pages/Promotion';
import SettingsPage from '../pages/Settings';
import ShareCodesTaskPage from '../pages/ShareCodesTask';
import TasksPage from '../pages/Tasks';
import TermsPage from '../pages/Terms';
import TodoTaskPage from '../pages/TodoTask';
import DelegatesPage from '../pages/Delegates';
import CampaignProgramsPage from '../pages/CampaignPrograms';
import CampaignProgramSignUpPage from '../pages/CampaignProgramSignUp';
import DeclinedCampaignProgramsPage from '../pages/DeclinedCampaignPrograms';

// Routes
import PublicRoute from './PublicRoute';
import PrivateRoute from './PrivateRoute';

export const paths = {
  dashboard: '/:token',
  campaigns: '/:token/campaigns',
  campaignSummary: '/:token/campaign-summary/:campaignId',
  payments: '/:token/payments',
  performance: '/:token/performance',
  privacyPolicy: '/:token/performance/privacy-policy',
  terms: '/:token/performance/terms',
  login: '/:token/login',
  profileReview: '/:token/profile-review',
  networkConnect: '/:token/networks',
  personalInfo: '/:token/personal-info',
  invalid: '/:token/invalid',
  productLink: '/:token/productLink',
  postContentTask: '/:token/post-content-task/:taskId/',
  fileRequestTask: '/:token/file-request-task/:taskId/',
  campaignBrief: '/:token/campaign-brief-task/:taskId/',
  shareCodesTask: '/:token/share-code-task/:taskId',
  todoTask: '/:token/todo-task/:taskId',
  contentStatus: '/:token/task/:taskId/content-status/:reviewId',
  tasks: '/:token/tasks',
  settings: '/:token/settings',
  delegates: '/:token/managed-creators',
  campaignPrograms: '/:token/campaign-programs',
  campaignProgramSignUp: '/:token/campaign-program/:programId',
  declinedCampaignPrograms: '/:token/campaign-program/declined',
  promotion: '/:token/:promotionId',
  notFound: '*',
};

export const routeNames = (() => {
  const pathNameObj = {};
  const keys = Object.keys(paths);
  keys.forEach((key) => (pathNameObj[key] = key));
  return pathNameObj;
})();

// Note: The order of this object matters
// for example; when placing the payments route below
// the dashboard, it would constantly render the dashboard
// when accessing the payment url. Not sure why this happens
// as of yet; will need to do more investigation.
const routes = [
  {
    layout: AppLayout,
    pages: [
      {
        routeType: PublicRoute,
        page: LoginPage,
        path: paths.login,
        pageRouting: [],
        settings: {},
      },
      {
        routeType: PublicRoute,
        page: InvalidPage,
        path: paths.invalid,
        pageRouting: [],
        settings: {},
      },
      {
        routeType: PrivateRoute,
        page: ProfileReviewPage,
        path: paths.profileReview,
        pageRouting: [],
        settings: {},
      },
      {
        routeType: PrivateRoute,
        page: NetworkConnectPage,
        path: paths.networkConnect,
        pageRouting: [],
        settings: {
          renderNavButton: true,
        },
      },
      {
        routeType: PrivateRoute,
        page: PersonalInfoPage,
        path: paths.personalInfo,
        pageRouting: [],
        settings: {},
      },
      {
        routeType: PrivateRoute,
        page: SettingsPage,
        path: paths.settings,
        pageRouting: [],
        settings: {
          renderNavButton: true,
        },
      },
      {
        routeType: PrivateRoute,
        page: CampaignsPage,
        path: paths.campaigns,
        pageRouting: [],
        settings: {
          renderNavButton: true,
        },
      },
      {
        routeType: PrivateRoute,
        page: CampaignSummaryPage,
        path: paths.campaignSummary,
        exact: true,
        strict: true,
        pageRouting: [],
        settings: {
          renderNavButton: true,
        },
      },
      {
        routeType: PrivateRoute,
        page: PaymentsPage,
        path: paths.payments,
        pageRouting: [],
        settings: {
          renderNavButton: true,
        },
      },
      {
        routeType: PrivateRoute,
        page: TermsPage,
        path: paths.terms,
        pageRouting: [],
        settings: {
          renderNavButton: true,
        },
      },
      {
        routeType: PrivateRoute,
        page: PrivacyPolicyPage,
        path: paths.privacyPolicy,
        pageRouting: [],
        settings: {
          renderNavButton: true,
        },
      },
      {
        routeType: PrivateRoute,
        page: PerformancePage,
        path: paths.performance,
        pageRouting: [],
        settings: {
          renderNavButton: true,
        },
      },
      {
        routeType: PrivateRoute,
        page: ProductLinkPage,
        path: paths.productLink,
        pageRouting: [],
        settings: {
          renderNavButton: true,
        },
      },
      {
        routeType: PrivateRoute,
        page: PostContentTaskPage,
        path: paths.postContentTask,
        exact: true,
        strict: true,
        pageRouting: [],
        settings: {
          renderNavButton: true,
        },
      },
      {
        routeType: PrivateRoute,
        page: FileRequestTaskPage,
        path: paths.fileRequestTask,
        exact: true,
        strict: true,
        pageRouting: [],
        settings: {
          renderNavButton: true,
        },
      },
      {
        routeType: PrivateRoute,
        page: CampaignBriefTaskPage,
        path: paths.campaignBrief,
        exact: true,
        strict: true,
        pageRouting: [],
        settings: {
          renderNavButton: true,
        },
      },
      {
        routeType: PrivateRoute,
        page: ShareCodesTaskPage,
        path: paths.shareCodesTask,
        exact: true,
        strict: true,
        pageRouting: [],
        settings: {
          renderNavButton: true,
        },
      },
      {
        routeType: PrivateRoute,
        page: TodoTaskPage,
        path: paths.todoTask,
        exact: true,
        strict: true,
        pageRouting: [],
        settings: {
          renderNavButton: true,
        },
      },
      {
        routeType: PrivateRoute,
        page: ContentStatusPage,
        path: paths.contentStatus,
        exact: true,
        strict: true,
        pageRouting: [],
        settings: {
          renderNavButton: true,
        },
      },
      {
        routeType: PrivateRoute,
        page: TasksPage,
        path: paths.tasks,
        exact: true,
        strict: true,
        pageRouting: [],
        settings: {
          renderNavButton: true,
        },
      },
      {
        routeType: PrivateRoute,
        page: DelegatesPage,
        path: paths.delegates,
        exact: true,
        strict: true,
        pageRouting: [],
        settings: {
          hideProfile: true,
          hideNavItems: true,
          renderNavButton: true,
        },
      },
      {
        routeType: PrivateRoute,
        page: CampaignProgramsPage,
        path: paths.campaignPrograms,
        exact: true,
        pageRouting: [],
        settings: {
          renderNavButton: true,
        },
      },
      {
        routeType: PrivateRoute,
        page: DeclinedCampaignProgramsPage,
        path: paths.declinedCampaignPrograms,
        exact: true,
        strict: true,
        pageRouting: [],
        settings: {
          renderNavButton: true,
        },
      },
      {
        routeType: PrivateRoute,
        page: CampaignProgramSignUpPage,
        path: paths.campaignProgramSignUp,
        pageRouting: [],
        settings: {
          renderNavButton: true,
        },
      },
      {
        routeType: PrivateRoute,
        page: PromotionPage,
        path: paths.promotion,
        pageRouting: [],
        settings: {
          renderNavButton: true,
        },
      },
      {
        routeType: PrivateRoute,
        page: DashboardPage,
        path: paths.dashboard,
        exact: true,
        pageRouting: [],
        settings: {
          renderNavButton: true,
        },
      },
      {
        routeType: PublicRoute,
        page: NotFoundPage,
        path: paths.notFound,
        pageRouting: [],
        settings: {},
      },
    ],
  },
];

export default routes;
