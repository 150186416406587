import styled from 'styled-components';
import Button from './Button';

export const ConnectedButton = styled(Button)`
  color: ${({ theme }) => theme.button.color.default};
  border-style: solid;
  border-width: 2px;
  border-color: ${({ theme }) => theme.button.color.default};
  background-color: transparent;
  &:hover {
    color: ${({ theme }) => theme.button.color.error};
    background-color: transparent;
    border-color: ${({ theme }) => theme.button.color.error};
  }
  &:hover span {
    display: none;
  }
  &:hover:before {
    content: '${({ label }) => label}';
  }
`;
