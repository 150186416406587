import styled, { css } from 'styled-components';
import { motion } from 'framer-motion';
import { padding, margin, width, position, overflow, borderRadius } from '../Mixins';

const Video = styled(motion.video)`
  height: auto;
  overflow: hidden;
  max-height:100%;
  max-width:100%;
  border-radius: ${({ theme, borderRadius }) => borderRadius === undefined ? theme.borderRadius : borderRadius };
  ${padding}
  ${margin}
  ${width}
  ${position}
  ${overflow}
  ${borderRadius}
  ${({ objectFit }) => 
    objectFit && css`
      object-fit: ${objectFit}
    `}
`;

export default Video;
