import { VIDEO_DATA_TYPE, FILE_DATA_TYPE } from '../../helpers/constants';
import S from '../../styled';
import { Download } from '@styled-icons/fa-solid';

const MAX_WIDTH = 400;
/**
 * Aims to preview the content uploaded or to be uploaded.
 * If content is waiting to be uploaded contentReview might not have a `video_url` or `image_url`.
 * for that case we need a content type to identify how to display it.
 * If the content has already been uploaded then we can resolve it using `video_url` or `image_url`.
 * @param {*} param0 
 * @returns 
 */
export default function ContentForReview({ contentReview, uploadingFileContentType, isPostContentTask, uploadName, src, ...props }) {
  const handleImageError = () => {
    console.log('error loading image');
  };
  const {
    content_type: contentType,
    presigned_url: presignedUrl,
    file_name: fileName,
    id: id
  } = contentReview || {};
  const isVideo = (uploadingFileContentType || contentType)?.startsWith(VIDEO_DATA_TYPE);
  const isFile = (uploadingFileContentType || contentType)?.startsWith(FILE_DATA_TYPE);
  const Component = isVideo ? S.Video : S.Image;
  const additionalParams = {
    controls: true
  };

  const name = fileName || uploadName;
  const url = presignedUrl || src;

  const file = () => (
    <S.Link key={id} href={url} textDecorationNone download>
      <S.Text maxWidth={MAX_WIDTH} mediumSize hoverable boldWeight>
        <Download size={10} /> {name}
      </S.Text>
    </S.Link>
  );

  const imageOrVideo = () => (
    <Component
      {... isVideo? additionalParams : {}}
      src={url}
      alt="content review image"
      onError={() => handleImageError()}
      maxWidth={MAX_WIDTH}
      {...props}
    />
  );

  return (
    <>
      { isFile ? file() : imageOrVideo() }
    </>
    );
};
