import React, { useContext } from 'react';
import S from '../../../styled';
import { FormFieldContext } from '../../../store/FormFieldContext';

import FieldErrorMessage from './FieldErrorMessage';

export default function RadioGroupInput({ id, required, store, params }) {
  const { name, options } = params
  const fieldType = params.field_type
  const { formFieldData, onChangeData, errors } = useContext(FormFieldContext);

  const splitOptions = options?.split("\n") || [];

  const radioOption = (item, index) => {
    const key = 'radioOption' + index + item;
    return (
      <div key={key}>
        <S.Label>
          <S.InputRadio
            type="radio"
            name={name}
            value={item}
            onChange={() => onChangeData(id, fieldType, required, item)}
            checked={formFieldData[id] === item}/>
          {item}
        </S.Label>
      </div>
    )
  }

  const mapOptions = (optionsList) => {
    if (options === "") {
      return null;
    }
    return optionsList.map((item, index) => (
      radioOption(item, index)
    ));
  }

  return (
    <S.Flex column alignStart marginTop={14}>
      <fieldset>
        <legend>{name}</legend>
        {mapOptions(splitOptions)}
      </fieldset>
      <FieldErrorMessage
        id={id}
        required={required}
        errors={errors}
        fieldName={name}
        store={store}
      />
    </S.Flex>
  );
}
