import React, { useState, useEffect, useCallback } from 'react';
import { useHistory } from 'react-router-dom';

// Styles
import S from '../../styled';

// Components
import Page from '../../components/Page';
import ApprovedContentReview from '../../components/ContentReviewStatus/approved';
import PendingApprovalContentReview from '../../components/ContentReviewStatus/PendingApproval';
import ChangeRequestedContentReview from '../../components/ContentReviewStatus/ChangeRequested';
import UploadContentReview from '../../components/ContentReviewStatus/upload';

// Model requests
import { getContentReview } from '../../data/requests/task';
import { useFileUpload } from '../../data/requests/uploads';
import { updateContentReview } from '../../data/requests/task';

// Helpers
import {
  FLASH_TYPE_ERROR,
  FLASH_TYPE_SUCCESS,
  POST_CONTENT_TASK,
  CONTENT,
  FILE,
  CONTENT_REVIEW_STATUS_APPROVED,
  CONTENT_REVIEW_STATUS_APPROVE_CONTENT,
  CONTENT_REVIEW_STATUS_FEEDBACK_GIVEN,
  CONTENT_REVIEW_STATUS_AWAITING_CONTENT,
} from '../../helpers/constants';

import { PhotoVideo } from '@styled-icons/fa-solid';
import { File } from '@styled-icons/fa-solid';

export default function ContentStatusPage(props) {
  const history = useHistory();
  const { match, store, setStore: { setRenderFlashMessage } } = props;
  const { taskId, reviewId } = match.params;
  const { token, t } = store;

  const [isLoading, setIsLoading] = useState(true);
  const [contentReview, setContentReview] = useState(undefined);

  const [file, setFile] = useState(null);
  const [contentType, setContentType] = useState(null);
  const [contentURL, setContentURL] = useState(null);
  const [submitting, setSubmitting] = useState(false);
  const { uploadFile } = useFileUpload(token);

  const goBack = (e) => {
    e.preventDefault();
    history.goBack();
  };

  const getContentReviewData = useCallback(async () => {
    if (!token) return;

    setIsLoading(true);
    try {
      const data = await getContentReview(token, taskId, reviewId);
      setContentReview(data);
    } catch (error) {
      console.error('getContentReviews: ', error);
    } finally {
      setIsLoading(false);
    }
  }, [token, taskId, reviewId]);

  useEffect(() => {
    getContentReviewData();
  }, [getContentReviewData]);

  const postContent = async (text) => {
    setSubmitting(true);
    try {
      const { uploadId } = await uploadFile(file, 'content_review');
      const contentReviewData = {
        text: text,
        upload_id: uploadId
      };
      const data = await updateContentReview(token, taskId, contentReview?.id, contentReviewData)
      return data;
    } finally {
      setSubmitting(false);
    }
  };

  const submitForReview = async (text) => {
    try {
      setIsLoading(true);
      const newContentReview = await postContent(text);
      setTimeout(() => history.goBack(), 1000);
      setRenderFlashMessage(
        t('pages.ContentStatus.uploadSuccessfully'),
        FLASH_TYPE_SUCCESS,
        { onClose: (() => setContentReview(newContentReview)) }
      );
    } catch (error) {
      console.error("Error posting content:", error);
      setRenderFlashMessage(t('pages.ContentStatus.uploadError'), FLASH_TYPE_ERROR);
    } finally {
      setIsLoading(false);
    }
  };

  const onContentSelected = (contentFile) => {
    setFile(contentFile)
    setContentType(contentFile?.type)
    setContentURL(URL.createObjectURL(contentFile));
  };

  const getValues = (contentReview, key) => {
    if (!contentReview) return '';
    const values = [];
    contentReview.accept.forEach(value => {
      values.push(value[key]);
    });
    return values;
  }

  const getPrefixes = () => {
    return getValues(contentReview, 'prefix').join(', ');
  }
  const getMime = () => {
    return getValues(contentReview, 'mime').join('');
  }

  const checkContentReviewStatus = (status) => {
    return contentReview?.status === status
  }

  const isPostContentTask = contentReview?.task_type === POST_CONTENT_TASK;
  const fileOrContent = isPostContentTask ? CONTENT : FILE;

  const rederDescription = () => isPostContentTask ? t('pages.ContentStatus.selectMediaToUpload') : t('pages.ContentStatus.selectFileToUpload')
  const renderFileTypes = () => isPostContentTask ? `${t('pages.ContentStatus.imageAndVideoFilesMustBe')} ${getPrefixes()}.` : `${t('pages.ContentStatus.acceptableFileTypesInclude')}: ${getPrefixes()}.`
  const renderIcon = () => isPostContentTask ? ( <PhotoVideo size={54} />) : (<File size={54} />)
  const renderTextPlaceholder = () => isPostContentTask ? t('pages.ContentStatus.enterPostCaption') : t('pages.ContentStatus.addNote');

  return (
    <Page>
      <S.Flex column flex={1}>
        { checkContentReviewStatus(CONTENT_REVIEW_STATUS_APPROVED) &&
          <ApprovedContentReview
            contentReview={contentReview}
            isLoading={isLoading}
            goBack={goBack}
            fileOrContent={fileOrContent}
            store={store}
          />}
        { checkContentReviewStatus(CONTENT_REVIEW_STATUS_APPROVE_CONTENT) &&
            <PendingApprovalContentReview
              contentReview={contentReview}
              isLoading={isLoading}
              store={store}
              goBack={goBack}
              isPostContentTask={isPostContentTask}
              fileOrContent={fileOrContent}
              /> }
        { checkContentReviewStatus(CONTENT_REVIEW_STATUS_FEEDBACK_GIVEN) &&
            <ChangeRequestedContentReview
              contentReview={contentReview}
              uploadingFileContentType={contentType}
              isLoading={isLoading}
              goBack={goBack}
              onContentSelected={onContentSelected}
              contentURL={contentURL}
              submitForReview={submitForReview}
              isPostContentTask={isPostContentTask}
              fileOrContent={fileOrContent}
              accept={getMime()}
              description={rederDescription()}
              fileType={renderFileTypes()}
              icon={renderIcon()}
              textPlaceholder={renderTextPlaceholder()}
              store={store}
            /> }
         { checkContentReviewStatus(CONTENT_REVIEW_STATUS_AWAITING_CONTENT) &&
            <UploadContentReview
              contentReview={contentReview}
              uploadingFileContentType={contentType}
              isLoading={isLoading}
              goBack={goBack}
              onContentSelected={onContentSelected}
              contentURL={contentURL}
              submitForReview={submitForReview}
              isPostContentTask={isPostContentTask}
              fileOrContent={fileOrContent}
              accept={getMime()}
              description={rederDescription()}
              fileType={renderFileTypes()}
              icon={renderIcon()}
              uploadName={file?.name}
              textPlaceholder={renderTextPlaceholder()}
              store={store}
            /> }
      </S.Flex>
    </Page>
  );
};
