import React, { useEffect } from 'react';
import S from '../../styled';

// Routes
import { routeNames } from '../../routing/routes';

// Components
import Page from '../../components/Page';

// Helpers
import navigateTo from '../../helpers/navigateTo';
import { useAuthentication } from '../../authentication';

import { authErrors } from '../../helpers/constants';

// Intercom
import { useIntercom } from 'react-use-intercom';

const errors = authErrors();
const { NoActiveAccountError, EmailDoesntMatchContactsError } = errors;

function ErrorContent({ error_code, reference_code, t }) {
  error_code = error_code || 'default';

  const fallbackMessage = t('constants.authErrors.default');
  const message = t(`constants.authErrors.${error_code}`, fallbackMessage);

  return (
    <>
      <S.Text>{message}</S.Text>
      {reference_code && (
        <S.Text>{t('pages.Invalid.referenceCode')}: {reference_code}</S.Text>
      )}
    </>
  )
}

export default function Invalid(props) {
  const { history } = props;
  const { token, account, t } = props.store;
  const { setRenderNavigation } = props.setStore;
  const { logout, error } = useAuthentication();

  const { error_code: errorCode } = error || '';
  const isFormError = errorCode === NoActiveAccountError.name || errorCode === EmailDoesntMatchContactsError.name;

  const closeNavigation = () => setRenderNavigation(false);

  const navigateToLogin = () => navigateTo(history, routeNames.login, token);

  const signOutUser = async () => {
    try {
      await logout();
    } finally {
      navigateToLogin();
      closeNavigation();
    }
  };

  useEffect(() => {
    if (isFormError && account.form_url?.length) {
      window.location.href = `${account.form_url}?error_code=PortalRedirect`;
    }
  }, [isFormError, account]);

  const { show: showIntercom } = useIntercom();

  if (isFormError && account.form_url) return (<></>);

  return (
    <Page>
      <S.Flex column textAlignCenter gap={24}>
        {error ? (
          <ErrorContent {...error} t={t} />
        ) : (
          <>
            <S.Text>
              {t('pages.Invalid.invitationOrAccountError')}
            </S.Text>
            <S.Text>
              {t('pages.Invalid.issueNotification')}
            </S.Text>
          </>
        )}
        <S.Text>
          {t('pages.Invalid.please')}
          <S.Link href="#" onClick={showIntercom} displayInline>{t('pages.Invalid.contactUs')}</S.Link>
          {t('pages.Invalid.ifYouNeedHelp')}
        </S.Text>
        <S.Flex marginTop={24}>
          <S.Button onClick={signOutUser}>{t('pages.Invalid.goToLogin')}</S.Button>
        </S.Flex>
      </S.Flex>
    </Page>
  );
}
