import React, { useEffect, useState, useCallback } from 'react';

import S from '../../styled';
import { FLASH_TYPE_SUCCESS, FLASH_TYPE_ERROR } from '../../helpers/constants';

// Components
import Module from '../Module';

// Requests
import {
  isAuthenticated,
  updateUser
} from '../../data/requests/user';

export default function UserSettingsModule(props) {
  const { setStore } = props;
  const { token, t } = props.store;
  const [isMounted, setIsMounted] = useState(true);
  const [moduleData, setModuleData] = useState({});
  const [moduleIsLoading, setModuleIsLoading] = useState(false);
  const { setRenderFlashMessage } = setStore;

  const loadModuleData = useCallback(async () => {
    if (!token) return;
    try {
      setModuleIsLoading(true);
      const data = await isAuthenticated(token);
      setModuleData(data);
    } catch (error) {
      console.error('loadModuleData: ', error);
    } finally {
      if (isMounted) setModuleIsLoading(false);
    }
  }, [token, isMounted]);

  useEffect(() => {
    loadModuleData();
    return () => setIsMounted(false);
  }, [loadModuleData]);

  const handleUserChange = (e) => {
    const { name, value } = e.target;
    setModuleData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const onNotificationSubmit = async (e) => {
    e.preventDefault();
    try {
      const data = await updateUser(token, moduleData);
      setModuleData(data);
      setRenderFlashMessage(
        t('components.UserSettingsModule.updateAccountSettingsSucccess'),
        FLASH_TYPE_SUCCESS
      );
    } catch (error) {
      console.error('onNotificationSubmit: ', error);
      setRenderFlashMessage(
        t('components.UserSettingsModule.updateAccountSettingsError'),
        FLASH_TYPE_ERROR
      );
    }
  };

  return (
    <Module title={t('components.UserSettingsModule.title')} isLoading={moduleIsLoading}>
      <S.Form
        column
        justifyBetween
        gap={14}
        flex={1}
        marginTop={14}
        maxWidth
        onSubmit={onNotificationSubmit}
      >
        <S.Flex column gap={24} alignStart justifyStart>
          <S.Flex gap={24} flex={0}>
            <S.Text as="p" maxWidth light Weight marginTop={3}>
              <S.Label htmlFor="email">
                {t('shared.emailAddress')}
              </S.Label>
              <S.Input name="email" type="email" onChange={handleUserChange} required value={moduleData.email} />
            </S.Text>
          </S.Flex>
        </S.Flex>
        <S.Button type="submit">{t('shared.save')}</S.Button>
      </S.Form>
    </Module>
  );
}
