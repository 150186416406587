import styled from 'styled-components';
import { gap, breakpointNames } from '../Mixins';
import { breakpoint } from 'styled-components-breakpoint';

const Footer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  position: fixed;
  bottom: 0;
  left: 0;
  height: 40px;
  width: 100%;
  cursor: pointer;
  background-color: ${({ theme }) => theme.footer.color.background};
  ${gap}

  ${breakpoint(breakpointNames.tablet, breakpointNames.desktop)`
    width: ${({ fullWidth }) => fullWidth ? '100%' : '283px'};
  `}

  ${breakpoint(breakpointNames.desktop)`
    width: ${({ fullWidth }) => fullWidth ? '100%' : '343px'};
  `}
`;

export default Footer;
