import styled from 'styled-components';
import { position, height, width, flex, margin, padding } from '../Mixins';

const Badge = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: row;

	border: ${({ theme }) => `2px solid ${theme.badge.color.text}`};

	border-radius:50%;
    -moz-border-radius:50%;
    -webkit-border-radius:50%;

	min-width: 14px;
	min-height: 14px;
	padding: 4px 8px;
	white-space: nowrap;
	text-align: center;
	color: ${({ theme }) => theme.badge.color.text};
	background-color: ${({ theme }) => theme.badge.color.background};
	font-size: ${({ theme }) => theme.badge.fontSize};
	font-weight: ${({ theme }) => theme.badge.fontWeight};
	${position}
	${height}
	${width}
	${flex}
	${margin}
	${padding}
`;

export default Badge;
