import { useState, useCallback, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

// Styles
import S from '../../styled';

// Model Requests
import { getTodoTask, updateTasks } from '../../data/requests/task';
import { getUploadsByParentTaskId } from '../../data/requests/uploads';

// Components
import Page from '../../components/Page';
import Module from '../../components/Module';
import DownloadFileLinks from '../../components/DownloadFileLinks';

// Helpers
import { taskNameWithDueDateObject } from '../../helpers/taskNameDueDate';

// Constants
import { FLASH_TYPE_SUCCESS, FLASH_TYPE_ERROR } from '../../helpers/constants';
import ModuleHeader from '../../components/ModuleHeader';
import TaskInstructions from '../../components/TaskInstructions';

export default function TodoTask(props) {
  const history = useHistory();

  const [todoTask, setTodoTask] = useState({});
  const [uploads, setUploads] = useState([]);
  const [isLoadingTask, setIsLoadingTask] = useState(true);
  const [isLoadingUploads, setIsLoadingUploads] = useState(true);
  const [showIncompleteTaskFields, setShowIncompleteTaskFields] = useState(true);

  const { store, setStore } = props;
  const { taskId: todoTaskIdQueryParam } = props.match.params;

  const { token, t } = store;
  const { setRenderFlashMessage } = setStore;

  const cancel = () => history.goBack();

  const successMessage = useCallback(() => {
    setRenderFlashMessage(
      t('pages.TodoTask.markedComplete'),
      FLASH_TYPE_SUCCESS
    );
  }, [setRenderFlashMessage, t]);

  const errorMessage = useCallback(() => {
    setRenderFlashMessage(
      t('pages.TodoTask.errorMarkingTaskComplete'),
      FLASH_TYPE_ERROR
    );
  }, [setRenderFlashMessage, t]);

  const completeTodoTask = useCallback(async () => {
    try {
      const response = await updateTasks(token, todoTaskIdQueryParam);

      if (response?.['json']?.['completed_at']) {
        setTimeout(() => history.goBack(), 1000);
        setShowIncompleteTaskFields();
        successMessage();
      } else {
        errorMessage();
      }
    } catch (e) {
      errorMessage();
    }
  }, [
    token,
    todoTaskIdQueryParam,
    history,
    errorMessage,
    successMessage
  ]);

  const getTask = useCallback(async () => {
    if (!token) return;

    setIsLoadingTask(true);
    try {
      const lookupTask = await getTodoTask(token, todoTaskIdQueryParam);
      setTodoTask(lookupTask);
      setShowIncompleteTaskFields(!lookupTask.is_complete)
    } catch (error) {
      console.error('getTodoTask: ', error);
    } finally {
      setIsLoadingTask(false);
    }
  }, [token, todoTaskIdQueryParam]);

  const getUploads = useCallback(async () => {
    const parentTaskId = todoTask.parent_task_id;
    if (!token || !parentTaskId) return;

    setIsLoadingUploads(true);
    try {
      const lookupUploads = await getUploadsByParentTaskId(token, parentTaskId);
      setUploads(lookupUploads);
    } catch (error) {
      console.error('getUploadsByTaskId', error);
    } finally {
      setIsLoadingUploads(false);
    }
  }, [token, todoTask]);

  useEffect(() => {
    getTask();
  }, [getTask]);

  useEffect(() => {
    getUploads();
  }, [getUploads]);

  const todoTaskForm = (
    <S.Flex alignCenter textAlignCenter column gap={24}>
      <S.Flex paddingTop={34}>
      {showIncompleteTaskFields && (
        <S.Text lightWeight paragraph flex={1} muted>
          {t('pages.TodoTask.markTaskComplete')}
        </S.Text>
      )}
      </S.Flex>
      {showIncompleteTaskFields && (
        <S.Flex gap={20}>
          <S.Button onClick={completeTodoTask}>
            {t('shared.confirm')}
          </S.Button>
          <S.Button secondary onClick={() => cancel()}>
          {t('shared.cancel')}
          </S.Button>
        </S.Flex>
      )}
    </S.Flex>
  );

  const moduleTitle = (
    <ModuleHeader moduleName={taskNameWithDueDateObject(todoTask)} history={history} />
  );

  const isLoading = isLoadingTask || isLoadingUploads

  return (
    <Page>
      <Module
        flex={1}
        titleBlock={moduleTitle}
        isLoading={isLoading}
      >
        <TaskInstructions instructionsHtml={todoTask?.instructions_html} />
        <DownloadFileLinks uploads={uploads} />
        {todoTaskForm}
      </Module>
    </Page>
  );
}
