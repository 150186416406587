import React, { createContext, useState } from 'react'
import { FORM_FIELD_TYPES } from '../helpers/constants';
import { noPropertyOrNull } from '../helpers/hasErrors';

export const FormFieldContext = createContext()

export const HTTP_STRING = 'http://';
export const HTTPS_STRING = 'http://';

export const FormFieldProvider = ({ children }) => {
  const [formFieldData, setFormFieldData] = useState({});
  const [errors, setErrors] = useState({});

  const onChangeDataPhone = (fieldId, required, countryCode, value) => {
    setFormFieldData((prevData) => ({
      ...prevData,
      [fieldId]: value
    }));

    validatePhone(fieldId, required, countryCode, value);
  };

  const validatePhone = (fieldId, required, countryCode, value) =>  {
    let error = null;
    if (required && (value?.replace(countryCode, '') === '')){
      error = 'components.FormField.requiredMessage';
    }
    setErrors((prevErrors) => ({
      ...prevErrors,
      [fieldId]: error
    }));
  };

  const onChangeData = (fieldId, fieldType, required, value) => {
    setFormFieldData((prevData) => ({
      ...prevData,
      [fieldId]: value
    }));

    validateField(fieldId, fieldType, value, required);
  };

  const onChangeBirthday = (fieldId, validationMethod, value) => {
    setFormFieldData((prevData) => ({
      ...prevData,
      [fieldId]: value
    }));

    validationMethod(value);
  };

  const validateField = (fieldId, fieldType, value, required) => {
    let error = null;
    switch (fieldType) {
      case FORM_FIELD_TYPES.CUSTOM_WEBSITE:
        error = validateWebsite(required, value);
        break;
      default:
        error = validateRequired(required, value);
        break;
    }
    setErrors((prevErrors) => ({
      ...prevErrors,
      [fieldId]: error
    }));
  };

  const validateWebsite = (required, value) =>  {
    if (value !== "" &&
      !(value.startsWith(HTTP_STRING) || value.startsWith(HTTPS_STRING))
    ){
      return 'components.FormField.invalidUrl';
    }
    return validateRequired(required, value);
  };

  const validateRequired = (required, value) =>  {
    if (required && (
      value === false ||
      value === "" ||
      (Array.isArray(value) && !value.length) ||
      value === undefined
    )){
      return 'components.FormField.requiredMessage';
    }
    return null;
  };

  const validateAllFields = (formFields) => {
    let newErrors = {};
    formFields.forEach((item) => {
      if (noPropertyOrNull(errors, item.id)) {
        newErrors[item.id] = validateRequired(item.required, formFieldData[item.id]);
      }
    });
    setErrors((prevErrors) => ({
      ...prevErrors,
      ...newErrors
    }));

    return {
      ...errors,
      ...newErrors
    };
  };

  return (
    <FormFieldContext.Provider value={{ formFieldData, onChangeData, setErrors, errors, onChangeBirthday, onChangeDataPhone, validateAllFields }}>
        {children}
    </FormFieldContext.Provider>
  )
}
