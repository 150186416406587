import React, { useState, useEffect, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import S from '../../styled';

// Components
import Module from '../Module';

// Helpers
import navigateTo from '../../helpers/navigateTo';
import {
  setDelegateRelationshipIdFromLocalStorage,
  removeDelegateRelationshipIdFromLocalStorage
} from '../../helpers/delegates';

// Requests
import {
  getDelegateRelationships,
  selectDelegateRelationship,
  deselectDelegateRelationship,
} from '../../data/requests/delegates';

// Routes
import { routeNames } from '../../routing/routes';

export default function DelegateSelectionModule(props) {
  const history = useHistory();

  const { store, setStore } = props;
  const { token, currentUser, t } = store;
  const { setCurrentUser } = setStore;

  const [moduleData, setModuleData] = useState([]);
  const [moduleIsLoading, setModuleIsLoading] = useState(false);
  const [delegateRelationshipSelected, setDelegateRelationshipSelected] = useState(false);

  const getDelegates = useCallback(async () => {
    if (!token) return;

    removeDelegateRelationshipIdFromLocalStorage();
    setCurrentUser({
      ...currentUser,
      delegate_relationship_id: null,
      acting_as_delegate: true,
      login_email: currentUser?.email,
      login_delegation_role: "self"
    });

    try {
      setModuleIsLoading(true);
      await deselectDelegateRelationship(token);
      const data = await getDelegateRelationships(token);

      // NOTE: If the portal user has never confirmed, we filter them out
      const filteredData = data.filter((d) => d?.owner_has_access_to_portal);

      setModuleData(filteredData);
    } catch (error) {
      console.error('getDelegateRelationships: ', error);
    } finally {
      setModuleIsLoading(false);
    }
  }, [token]);

  useEffect(() => {
    getDelegates();
  }, [getDelegates]);

  const onDelegateRelationshipSelection = async (id) => {
    try {
      const response = await selectDelegateRelationship(token, id);
      if (response) {
        setDelegateRelationshipSelected(true);
        setDelegateRelationshipIdFromLocalStorage(id);
        setCurrentUser({ ...response, delegate_relationship_id: id });
        navigateTo(history, routeNames.dashboard, token);
      }
    } catch (error) {
      console.error('onDelegateRelationshipSelection: ', error);
    }
  };

  const delegates = moduleData.map(
    ({ id, owner_email, owner_name, owner_profile_image_url }) => (
      <S.Link
        key={`delegate-${id}`}
        src="#"
        onClick={() => onDelegateRelationshipSelection(id)}
        maxWidth
        margin={0}
      >
        <S.Flex gap={14}>
          {owner_profile_image_url && (
            <S.Image
              height={24}
              width={24}
              borderRadius={'100%'}
              src={owner_profile_image_url}
              alt={`${owner_name} Creator Profile Image`}
            />
          )}
          <S.Text lightWeight>
            {owner_name} - {owner_email}
          </S.Text>
          <S.Flex flex={1} justifyEnd maxWidth>
            <S.Button flex={0}>
              {t('navigation.access')}
            </S.Button>
          </S.Flex>
        </S.Flex>
      </S.Link>
    )
  );

  const noDelegates = (
    <S.Flex justifyCenter>
      <S.Text>
        {t('components.DelegateSelectionModel.noActiveRelationships')}
      </S.Text>
    </S.Flex>
  );

  return (
    <Module title="Relationships" isLoading={moduleIsLoading}>
      <S.Flex column gap={24} alignStart justifyStart>
        <S.Flex gap={24} flex={0}>
          <S.Text as="p" maxWidth lightWeight marginTop={3}>
            {t('components.DelegateSelectionModel.selectCreatorForPortalAccess')}
          </S.Text>
        </S.Flex>
        <S.Flex gap={24} flex={0}>
          {delegateRelationshipSelected ?
            <S.Spinner />
          :
            <S.Flex column alignStart marginBottom={24} gap={14}>
              {delegates.length ?
                  delegates
                :
                  noDelegates
              }
            </S.Flex>
          }
        </S.Flex>
      </S.Flex>
    </Module>
  );
};
