import React, { useContext } from 'react';
import styled from 'styled-components';

import S from '../../../styled';
import { breakpoint, breakpointNames } from '../../../styled/Mixins';

import { FORM_FIELD_DEFAULT_LABELS, FORM_FIELD_TYPES as FIELD_TYPES } from '../../../helpers/constants';
import { countries } from '../../../helpers/countries';
import { FormFieldContext } from '../../../store/FormFieldContext';

import FieldErrorMessage from './FieldErrorMessage';

const FormFieldStyledSelect = styled(S.Select)`
  background-color: ${({ theme }) => theme.input.color.background};
  border-color: ${({ theme }) => theme.input.color.border};
  color: ${({ theme }) => theme.input.color.text};
  max-width: 100%;
  width: 100%;
  ${breakpoint(breakpointNames.tablet, `
    width: 100%;
  `)};
`

export default function SelectInput({ id, name, fieldName, fieldType, options, store, required }) {
  const { formFieldData, onChangeData, errors } = useContext(FormFieldContext);
  const { t } = store;
  const isGenderInput = (fieldType === FIELD_TYPES.GENDER);
  const isCountryInput = (fieldType === FIELD_TYPES.COUNTRY);
  const labelText =
    name === FORM_FIELD_DEFAULT_LABELS[fieldType]
      ? t(`components.FormField.typeLabels.${fieldType}`)
      : name

  const genderOptions = [
    { value: '', description: t('components.FormField.selectGender'), hidden: true},
    { value: 'female', description: t('components.FormField.genders.female') },
    { value: 'male', description: t('components.FormField.genders.male') },
    { value: 'other', description: t('components.FormField.genders.other') }
  ]

  const optionsList = (formFieldOptions) => {
    if (isGenderInput) return genderOptions;
    if (isCountryInput) return countries;

    const blankOption = { value: "", description: ""};
    const optionsList = formFieldOptions?.split("\n").map((item) => (
      { value: item, description: item }
    )) || [];

    return [blankOption].concat(optionsList);
  }

  const renderOption = (option, idx) => {
    return (
      <S.Option key={option.value + idx} hidden={option.hidden} value={option.value}>
        {option.description}
      </S.Option>
    )
  }

  return (
    <S.Flex column alignStart marginTop={14}>
      <S.Text>{labelText}</S.Text>
      <FormFieldStyledSelect
        name={fieldName}
        value={formFieldData[id]}
        onChange={(e) => onChangeData(id, fieldType, required, e.target.value)}
      >
        {optionsList(options).map((option, idx) => (
            renderOption(option, idx)
          ))}
      </FormFieldStyledSelect>
      <FieldErrorMessage
        id={id}
        required={required}
        errors={errors}
        fieldName={labelText}
        store={store}
      />
    </S.Flex>
  );
}
