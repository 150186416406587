import React from 'react';

import Page from '../../components/Page';
import ProductLinkModule from '../../components/ProductLinkModule';

// Requests
const ProductLink = props => {
  return (
    <Page title="Product Link">
      <ProductLinkModule
        store={props.store}
        setStore={props.setStore}
        flex={1}
      />
    </Page>
  );
}

export default ProductLink;
