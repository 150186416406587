import api from '../api';

const baseUrl = (token) => `activations/${token}`;

export const getCampaignsByToken = (token, completed = false) =>
  api({
    method: 'get',
    url: `${baseUrl(token)}`,
    params: { completed },
  });

  export const getCampaign = (token, campaignId) =>
  api({
    method: 'get',
    url: `${baseUrl(token)}/${campaignId}`
  });

  export const getPerformanceRates = (token, campaignId) =>
  api({
    method: 'get',
    url: `${baseUrl(token)}/${campaignId}/performance_rates`
  });

  export const getInformations = (token, campaignId) =>
  api({
    method: 'get',
    url: `${baseUrl(token)}/${campaignId}/informations`
  });
