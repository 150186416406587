import styled from 'styled-components';

const IFrame = styled.iframe`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  min-height: calc(100vh - 180px);
  width: 100%;
  position: relative;
  border-radius: 8px;

  #app {
    height: 100%;
  }
`;

export default IFrame;
