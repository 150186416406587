import styled from 'styled-components';
import { flex, gap, breakpointNames } from '../Mixins';
import { breakpoint } from 'styled-components-breakpoint';

const Page = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  flex: 1;
  overflow-y: auto !important;
  overflow-x: hidden;
  padding: ${({ clear }) => (clear ? 0 : '24px')};
  padding-top: ${({ clear }) => (clear ? 0 : '100px')};
  padding-bottom: ${({ clear }) => (clear ? 0 : '80px')};
  gap: ${({ gap }) => (gap ? null : '24px')};
  ${gap}
  ${flex}
  width: 100%;

  ${breakpoint(breakpointNames.mobile)`
    padding-top: 60px;
    background-color: ${({ theme, clear }) =>
      clear ? theme.module.color.background : null};
  `};

  ${breakpoint(breakpointNames.tablet)`
    padding-top: 24px;
    padding-bottom: 24px;
    padding: 24px;
    height: ${({ clear, height }) => (clear ? null : height || '100vh')};
    justify-content: flex-start;
    background-color: unset;
  `};

  ${breakpoint(breakpointNames.desktop)`
    padding-left: 24px;
    overflow: hidden
  `};
`;

export default Page;
