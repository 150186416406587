import { useRef, useState } from "react";
import S from '../../styled';

function Tooltip({ position, text, children, background, styleMe = true }) {
  const [isHovered, setIsHovered] = useState(false);
  const [isFocused, setIsFocused] = useState(false);
  const targetRef = useRef(null);
  const showTooltip = isHovered || isFocused; //isHovered || isFocused;

  const handleClick = (e) => {
    setIsFocused(!isFocused);
    setIsHovered(false);
  };

  return (
    <S.Tooltip.TooltipWrapper
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      onFocus={() => setIsFocused(true)}
      onBlur={() => setIsFocused(false)}
      onClick={handleClick}
    >
      <S.TooltipTarget
        ref={targetRef}
        styleMe={styleMe}
        showOnFocus={isFocused}
      >
        {children}
      </S.TooltipTarget>
      {showTooltip && (
        <S.Tooltip.CenterContainer position={position}>
          <S.Tooltip.TooltipBox background={background} position={position}>
            {text}
          </S.Tooltip.TooltipBox>
        </S.Tooltip.CenterContainer>
      )}
    </S.Tooltip.TooltipWrapper>
  );
}

export default Tooltip;