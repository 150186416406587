import { useState, useCallback, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

// Styles
import S from '../../styled';

// Model Requests
import { getCampaignBriefTask, updateTasks } from '../../data/requests/task';
import { getUploadsByParentTaskId } from '../../data/requests/uploads';

// Components
import Page from '../../components/Page';
import Module from '../../components/Module';
import DownloadFileLinks from '../../components/DownloadFileLinks';
import TaskInstructions from '../../components/TaskInstructions';
import ModuleHeader from '../../components/ModuleHeader';

// Helpers
import { taskNameWithDueDateObject } from '../../helpers/taskNameDueDate';

// Constants
import { FLASH_TYPE_SUCCESS, FLASH_TYPE_ERROR } from '../../helpers/constants';

export default function CampaignBriefTask(props) {
  const history = useHistory();

  const [campaignBriefTask, setCampaignBriefTask] = useState({});
  const [uploads, setUploads] = useState([]);
  const [isLoadingCampaignBriefTask, setIsLoadingCampaignBriefTask] = useState(true);
  const [isLoadingUploads, setIsLoadingUploads] = useState(true);
  const [showCampaignTaskAcceptButton, setShowCampaignTaskAcceptButton] = useState(true);

  const { store, setStore } = props;
  const { taskId: campaignBriefTaskIdQueryParam } = props.match.params;

  const { token, t } = store;
  const { setRenderFlashMessage } = setStore;

  const successMessage = useCallback(() => {
    setRenderFlashMessage(
      t('pages.CampaignBriefTask.markingTaskAccepted'),
      FLASH_TYPE_SUCCESS
    );
  }, [setRenderFlashMessage, t]);

  const errorMessage = useCallback(() => {
    setRenderFlashMessage(
      t('pages.CampaignBriefTask.errorMarkingTaskComplete'),
      FLASH_TYPE_ERROR
    );
  }, [setRenderFlashMessage, t]);

  const completeCampaignBriefTask = useCallback(async () => {
    try {
      const response = await updateTasks(token, campaignBriefTaskIdQueryParam);

      if (response?.['json']?.['completed_at']) {
        setTimeout(() => history.goBack(), 1000);
        setShowCampaignTaskAcceptButton();
        successMessage();
      } else {
        errorMessage();
      }
    } catch (e) {
      errorMessage();
    }
  }, [
    token,
    campaignBriefTaskIdQueryParam,
    history,
    errorMessage,
    successMessage
  ]);

  const getCampaignTask = useCallback(async () => {
    if (!token) return;

    setIsLoadingCampaignBriefTask(true);
    try {
      const lookupTask = await getCampaignBriefTask(token, campaignBriefTaskIdQueryParam);
      setCampaignBriefTask(lookupTask);
      setShowCampaignTaskAcceptButton(!lookupTask.is_complete);
    } catch (error) {
      console.error('getCampaignTask: ', error);
    } finally {
      setIsLoadingCampaignBriefTask(false);
    }
  }, [token, campaignBriefTaskIdQueryParam]);

  const getUploads = useCallback(async () => {
    const parentTaskId = campaignBriefTask.parent_task_id;
    if (!token || !parentTaskId) return;

    setIsLoadingUploads(true);
    try {
      const lookupUploads = await getUploadsByParentTaskId(token, parentTaskId);
      setUploads(lookupUploads);
    } catch (error) {
      console.error('getUploadsByTaskId', error);
    } finally {
      setIsLoadingUploads(false);
    }
  }, [token, campaignBriefTask]);

  useEffect(() => {
    getCampaignTask();
  }, [getCampaignTask]);

  useEffect(() => {
    getUploads();
  }, [getUploads]);

  const moduleTitle = (
    <ModuleHeader moduleName={taskNameWithDueDateObject(campaignBriefTask)} history={history} />
  );

  const campaignTaskAcceptButton = (
    <S.Flex column alignCenter flex={1} overflowHidden>
      <S.Flex justifyCenter alignCenter desktopStyles={{ width: '50%' }}>
        {showCampaignTaskAcceptButton && (
          <S.Button onClick={completeCampaignBriefTask}>{t('pages.CampaignBriefTask.accept')}</S.Button>
        )}
      </S.Flex>
    </S.Flex>
  );

  const isLoading = isLoadingCampaignBriefTask || isLoadingUploads

  return (
    <Page>
      <Module
        flex={1}
        titleBlock={moduleTitle}
        isLoading={isLoading}
      >
        <TaskInstructions instructionsHtml={campaignBriefTask?.instructions_html} />
        <DownloadFileLinks uploads={uploads} />
        {campaignTaskAcceptButton}
      </Module>
    </Page>
  );
}
