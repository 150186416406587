import React from 'react';
import { useHistory } from 'react-router-dom';

// Styles
import S from '../../styled';

// Icons
import { ChevronLeft } from '@styled-icons/fa-solid';

// Routes
import { routeNames } from '../../routing/routes';

// Helpers
import navigateTo from '../../helpers/navigateTo';

// Components
import Page from '../../components/Page';
import Module from '../../components/Module';
import AvailableCampaignProgramsModule from '../../components/CampaignProgramsModule/AvailableCampaignProgramsModule';

export default function CampaignProgramsPage(props) {
  const { store } = props;
  const { token, t } = store
  const history = useHistory();

  const goBack = (e) => {
    e.preventDefault();
    history.goBack();
  };

  const navigateToDeclinedCampaigns = (e) => {
    e.preventDefault();
    navigateTo(history, routeNames.declinedCampaignPrograms, token)
  }

  const titleContent = (
    <S.Flex flex={1}>
      <S.Link marginTop={0} onClick={goBack}><ChevronLeft size={25} /></S.Link>
      <S.Text largeSize boldWeight capitalize whitespaceNoWrap paddingRight={40}>
        {t('pages.CampaignPrograms.allAvailableCampaigns')}
      </S.Text>
    </S.Flex>
  )

  const footerContent = (
    <S.Flex overflowHidden>
        <S.Link
          textDecoration="underline"
          lightWeight
          onClick={navigateToDeclinedCampaigns}
        >
          {t('pages.CampaignPrograms.hiddenCampaigns')}
        </S.Link>
    </S.Flex>
  )

  return (
    <Page alignStart justifyStart>
      <Module>
        <AvailableCampaignProgramsModule
          store={store}
          available={true}
          title={titleContent}
          footer={footerContent}
        />
      </Module>
    </Page>
  );
};
