import React from 'react';
import S from '../../styled';

export default function News(props) {
  const { postId, title, imageUrl, newsUrl, summary } = props;

  if (!title) return;

  const postUrl = `${newsUrl}/${postId}`;

  const newsRow = (
    <S.Flex
      row
      gap={24}
      padding={5}
      clickable
      hoverable
      justifyStart
      flex={1}
    >
      <S.Flex row justifyStart maxWidth={800}>
          {imageUrl && <S.Image
            width={'30%'}
            src={imageUrl}
            alt={title}
            sharpBorderRadius
            marginRight={10}
            overflowImage
          />}
          <S.Visibility mobile>
            <S.Text truncate maxWidth boldWeight marginBottom={4} whiteSpaceInitial>{title}</S.Text>
          </S.Visibility>
          <S.Visibility tablet desktop>
            <S.Flex column flex={1}>
              <S.Text truncate maxWidth boldWeight marginBottom={4} whiteSpaceInitial>{title}</S.Text>
              <S.Text truncate textAlignLeft lightWeight width={1} whiteSpaceInitial>{summary}</S.Text>
            </S.Flex>
          </S.Visibility>
      </S.Flex>
    </S.Flex>
  );

  return (
    <S.Flex
      flex={1}
      row
      gap={24}
      hoverable
      justifyStart
    >
      {postId ? (
        <S.Link
          href={postUrl}
          whiteSpaceNoWrap
          textAlignLeft
          textDecorationNone
          target="_blank"
          rel="noreferrer"
        >
          {newsRow}
        </S.Link>
      ) : (
        <>
          {newsRow}
        </>
      )}
    </S.Flex>
  );
}
