import React, { useContext, useCallback } from 'react';
import moment from 'moment';

import S from '../../../styled';
import { FormFieldContext } from '../../../store/FormFieldContext';
import { FORM_FIELD_DEFAULT_LABELS } from '../../../helpers/constants';

import FieldErrorMessage from './FieldErrorMessage';

// Utility function to apply the mask to the input value
const applyMask = (value, mask) => {
  let maskedValue = '';
  let maskIndex = 0;

  for (let i = 0; i < value.length; i++) {
    if (maskIndex >= mask.length) break;

    const maskChar = mask[maskIndex];
    const inputChar = value[i];

    if (maskChar === '9') {
      // '9' means any numeric digit
      if (/\d/.test(inputChar)) {
        maskedValue += inputChar;
        maskIndex++;
      }
    } else {
      // Literal character in the mask
      maskedValue += maskChar;
      maskIndex++;
      i--; // To re-check this character with the next mask character
    }
  }

  return maskedValue;
};

export default function BirthdayInput({ id, fieldType, metadata, name, required, store }) {
  const mask = "99/99/9999";
  const dateFormat = "MM/DD/YYYY";
  const placeholder = dateFormat.toLowerCase();
  const { t } = store;
  const { formFieldData, onChangeBirthday, errors, setErrors } = useContext(FormFieldContext);
  const labelText =
    name === FORM_FIELD_DEFAULT_LABELS[fieldType]
      ? t(`components.FormField.typeLabels.${fieldType}`)
      : name

  const validateBirthday = useCallback((value) => {
    const dateValue = moment(value, dateFormat, true);
    const now = moment();
    const age = moment.duration(now.diff(dateValue));
    let error = null;
    if (required && value === '') {
      error = t('components.FormField.requiredMessage', { fieldName: labelText });
    } else if (!dateValue.isValid()) {
      error = t('components.FormField.invalidDateMessage');
    } else if (metadata?.age_requirement && age.years() < metadata?.minimum_age) {
      error = t('components.FormField.invalidAgeMessage');
    }

    setErrors((prevErrors) => ({
      ...prevErrors,
      [id]: error
    }));
  }, [required, labelText, dateFormat, metadata, id, t, setErrors]);

  const handleChange = useCallback((event) => {
    const { value } = event.target;
    const maskedValue = applyMask(value, mask);
    onChangeBirthday(id, validateBirthday, maskedValue);
  }, [mask, id, validateBirthday, onChangeBirthday]);

  return (
    <S.Flex column alignStart marginTop={14}>
      <S.Text>{labelText}</S.Text>
      <S.Input
        type="text"
        value={formFieldData[id] || ""}
        onChange={handleChange}
        placeholder={placeholder}
      />
      <FieldErrorMessage
        id={id}
        required={required}
        errors={errors}
        fieldName={labelText}
        store={store}
      />
    </S.Flex>
  );
};
