import React from 'react';
import S from '../../../styled';

const requiredMessageKey = 'components.FormField.requiredMessage';
const invalidUrlMessageKey = 'components.FormField.invalidUrl';

export default function FieldErrorMessage({ required, errors, id, fieldName, store }) {
  const { t } = store;
  const showErrorMessage = required && errors[id] !== undefined;

  const requiredMessage = () => {
    if (requiredMessageKey && errors[id] === requiredMessageKey) {
      return (
        <S.Text warning>
          {t(requiredMessageKey, { fieldName: fieldName })}
        </S.Text>
      );
    } else if(errors[id] === invalidUrlMessageKey) {
      return (
        <S.Text warning>
          {t(invalidUrlMessageKey)}
        </S.Text>
      );
    }
    return <S.Text warning>{errors[id]}</S.Text>;
  };

  return showErrorMessage ? requiredMessage() : null;
};

