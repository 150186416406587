import React, { useState, useEffect, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import S from '../../styled';

// Components
import Module from '../../components/Module';
import ProfileRow from './ProfileRow';

// Requests
import { getCurrentUserProfilesByToken } from '../../data/requests/profile';
import { filterSocialProfiles } from '../../data/requests/profile';

export default function MyProfilesModule(props) {
  const history = useHistory();

  const [moduleIsLoading, setModuleIsLoading] = useState(true);
  const { token, t } = props.store;

  const [socialProfiles, setSocialProfiles] = useState([]);
  const [showAllProfiles, setShowAllProfiles] = useState(false);

  const show = () => setShowAllProfiles(true);
  const hide = () => setShowAllProfiles(false);

  const loadProfiles = useCallback(async () => {
    if (!token) return;

    try {
      setModuleIsLoading(true);
      const currentProfile = await getCurrentUserProfilesByToken(token);
      const socialProfiles = filterSocialProfiles(currentProfile);
      const sortedSocialProfiles = socialProfiles.sort((a, b) => {
        if (a.reach > b.reach) {
          return -1;
        }
        if (a.reach < b.reach) {
          return 1;
        }
        return 0;
      });
      setSocialProfiles(sortedSocialProfiles);
    } catch (error) {
      console.error('loadProfile: ', error);
    } finally {
      setModuleIsLoading(false);
    }
  }, [token]);

  useEffect(() => {
    loadProfiles();
  }, [loadProfiles]);

  const renderShowAllProfiles = () => {
    const linkText = showAllProfiles ? t('components.PerformanceModule.hideProfiles') : t('components.PerformanceModule.showProfiles');
    return (
      <S.Flex row flex={1} overflowHidden>
        <S.Link lightWeight href='#' onClick={showAllProfiles ? hide : show}>{linkText}</S.Link>
      </S.Flex>
    );
  };

  const topRightContent = (
    <S.Text muted nowrap marginLeft={14} marginRight={14}>
      {t('components.PerformanceModule.past90Days')}
    </S.Text>
  );

  const profiles = showAllProfiles ? socialProfiles : socialProfiles.slice(0, 3);

  return (
    <Module
      title={t('components.PerformanceModule.myProfiles')}
      isLoading={moduleIsLoading}
      isEmpty={!socialProfiles}
      flex={1}
      emptyContent={'fix'}
      topRightContent={topRightContent}
    >
      {profiles && profiles.map((profile, i) => (
        <ProfileRow
          key={`${profile.id}-${i}`}
          profile={profile}
          t={t}
          history={history}
          token={token}
        />
      ))}
      {renderShowAllProfiles()}
    </Module>
  );
}
