import api from '../api';

const baseUrl = (token) => `promotions/${token}`;

export const getPromotionsByToken = (token) =>
  api({
    method: 'get',
    url: `${baseUrl(token)}`
  });


export const getPromotion = (token, promotionId) =>
  api({
    method: 'get',
    url: `${baseUrl(token)}/${promotionId}`
  });
