import React from 'react';

// Components
import Page from '../../components/Page';
import DelegateSelectionModule from '../../components/DelegateSelectionModule';

export default function Delegates(props) {
  return (
    <Page flexStart justifyStart>
      <DelegateSelectionModule {...props} />
    </Page>
  );
}
