import React, { useState, useCallback, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

// Routes
import { routeNames } from '../../routing/routes';

// Styles
import S from '../../styled';

// Model Requests
import { getCampaignProgram } from '../../data/requests/program';
import { checkForApplication } from '../../data/requests/programApplication';

// Components
import Page from '../../components/Page';
import Module from '../../components/Module';
import ModuleHeader from '../../components/ModuleHeader';
import HtmlContent from '../../components/HtmlContent';
import CampaignProgramSignUpForm from '../../components/CampaignProgramSignUpForm';

// Constants
import { FLASH_TYPE_SUCCESS, FLASH_TYPE_ERROR } from '../../helpers/constants';

// Helpers
import navigateTo from '../../helpers/navigateTo';

// Provider
import { FormFieldProvider } from '../../store/FormFieldContext';

export default function CampaignProgramSignUpPage(props) {
  const history = useHistory();

  const [programDetails, setProgramDetails] = useState({});
  const [isLoadingProgram, setIsLoadingProgram] = useState(true);
  const [hasError, setHasError] = useState(false);
  const [hasAlreadyApplied, setHasAlreadyApplied] = useState(false);
  const [hasAlreadyDeclined, setHasAlreadyDeclined] = useState(false);

  const { programId } = props.match.params;
  const { store, setStore } = props;

  const { token, t, currentProfile } = store;
  const { setRenderFlashMessage } = setStore;


  const successMessage = useCallback((decline = false) => {
    if (decline) {
      setRenderFlashMessage(
        t('pages.CampaignProgramSignUp.declinedSuccess'),
        FLASH_TYPE_SUCCESS
      );
    } else {
      setRenderFlashMessage(
        t('pages.CampaignProgramSignUp.applicationSuccess'),
        FLASH_TYPE_SUCCESS
      );
    }
  }, [setRenderFlashMessage, t]);

  const errorMessage = useCallback((decline = false) => {
    if (decline) {
      setRenderFlashMessage(
        t('pages.CampaignProgramSignUp.declinedError'),
        FLASH_TYPE_ERROR
      );
    } else {
      setRenderFlashMessage(
        t('pages.CampaignProgramSignUp.signupError'),
        FLASH_TYPE_ERROR
      );
    }
  }, [setRenderFlashMessage, t]);

  const getProgramDetailsAndCheckApplication = useCallback(async (currentProfile) => {
    if (!token) return;

    setIsLoadingProgram(true);
    try {
      const program = await getCampaignProgram(token, programId);
      setProgramDetails(program);
      if (program.declined_contact_ids.includes(currentProfile.id)) setHasAlreadyDeclined(true);
      const application = await checkForApplication(token, programId);
      if (application) {
        setHasAlreadyApplied(true);
        setHasAlreadyDeclined(true);
      }
    } catch (error) {
      setHasError(true);
    } finally {
      setIsLoadingProgram(false);
    }
  }, [token, programId]);

  useEffect(() => {
    if (currentProfile) getProgramDetailsAndCheckApplication(currentProfile);
  }, [getProgramDetailsAndCheckApplication, currentProfile]);

  const navigateToSettings = (e) => {
    e.preventDefault();
    navigateTo(history, routeNames.settings, token);
  };

  const moduleTitle = (
    <ModuleHeader moduleName={programDetails?.activation_name} history={history} />
  );

  return (
    <Page>
      <Module
        flex={1}
        titleBlock={moduleTitle}
        isLoading={isLoadingProgram}
        hasError={hasError}
      >
        <FormFieldProvider>
          <S.Flex
            flex={1}
            padding={20}
            column
          >
            <HtmlContent content={programDetails?.description || programDetails?.influencer_incentive} flex={1} textAlignLeft />
            <CampaignProgramSignUpForm
              programId={programId}
              hasAlreadyApplied={hasAlreadyApplied}
              hasAlreadyDeclined={hasAlreadyDeclined}
              successMessage={successMessage}
              errorMessage={errorMessage}
              formFields={programDetails?.form_fields}
              store={store}
            />
            <S.Flex column alignCenter flex={1} overflowHidden marginTop={15} desktopStyles={{ width: '80%' }} >
              <S.Text textAlignCenter smallSize lightWeight paddingTop={20} >
                  {t('pages.CampaignProgramSignUp.verifyNotificationsEnabled')}
                  <S.Link
                    textDecoration="underline"
                    lightWeight
                    onClick={navigateToSettings}
                  >
                    {t('navigation.settings')}.
                  </S.Link>
              </S.Text>
            </S.Flex>
          </S.Flex>
        </FormFieldProvider>
      </Module>
    </Page>
  );
};
