import api from '../api';

const baseUrl = (token) => `partnership/${token}`;

export const getPartnership = (token) =>
  api({
    method: 'get',
    url: `${baseUrl(token)}`,
  });

export const getPartnershipPrivacyPolicy = (token) =>
  api({
    method: 'get',
    url: `${baseUrl(token)}/privacy_policy`,
  });

export const getPartnershipTerms = (token) =>
  api({
    method: 'get',
    url: `${baseUrl(token)}/terms`,
  });
