import React from 'react';
import S from '../../styled';

// Assets
import { ReactComponent as SideqikFooterLogo } from '../../assets/svg/sideqik_footer_logo.svg';
import { ReactComponent as PoweredByFooterLogo } from '../../assets/svg/powered_by_footer_logo.svg';

export default function Footer(props) {
	return (
		<S.Footer gap={8} {...props} onClick={() => window.open('http://www.sideqik.com', '_blank')}>
			<S.SvgWrapper height={15} width={80}>
				<PoweredByFooterLogo />
			</S.SvgWrapper>
			<S.SvgWrapper height={30} width={100}>
				<SideqikFooterLogo />
			</S.SvgWrapper>
		</S.Footer>
	);
}
