import React from 'react';

// Styles
import S from '../../styled';

// Components
import NavigationContent from '../NavigationContent';
import DelegateLoginNote from "../DelegateLoginNote";

export default function Navigation(props) {
  const { renderNavigation, currentUser } = props.store;
  const { setRenderNavigation } = props.setStore;

  const closeNavigation = () => setRenderNavigation(false);

  return (
    <>
      <S.ScreenOverlay
        render={renderNavigation}
        onClick={closeNavigation}
      />
      <S.Navigation render={renderNavigation}>
        <S.ModalBody>
          <NavigationContent {...props} />
        </S.ModalBody>
        <S.ModalFooter>
          <DelegateLoginNote currentUser={currentUser} store={props.store} />
        </S.ModalFooter>
      </S.Navigation>
    </>
  );
}
