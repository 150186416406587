let serverOrigin = process.env.REACT_APP_SERVER_ORIGIN;

const incomingHookBody = process.env.REACT_APP_INCOMING_HOOK_BODY
if (incomingHookBody?.length) {
  try {
    let { heroku_app_name } = JSON.parse(decodeURI(incomingHookBody).split('&')[0]);
    serverOrigin = `https://${heroku_app_name}.herokuapp.com`;
  } catch (err) {
    console.log("Error parsing incoming hook body:", err);
  }
}

// NOTE: This is for overriding the serverOrigin for preview apps
if (process.env.CONTEXT !== 'production') {
  const sessionKeyName = 'serverOrigin';

  const urlQueryParams = (() => {
    const params = {};
    new URLSearchParams(window.location.search).forEach((value, key) =>
      params[key] = value
    );
    return params;
  })();

  if (urlQueryParams[sessionKeyName]) {
    window.sessionStorage.setItem(sessionKeyName, urlQueryParams[sessionKeyName]);
  }

  let savedServerOriginSessionValue = window.sessionStorage.getItem(sessionKeyName);
  if (savedServerOriginSessionValue) {
    serverOrigin = savedServerOriginSessionValue;
  }
}
// -------------------------------------------------------------------

const config = {
  serverOrigin,
  baseApiUrl: `${serverOrigin}/api/portal`,
  logRocket: 'xkilqq/portal-dy4ji',
  intercomAppId: 'mhs5wuse',
};

export default config;
