import { paths } from '../routing/routes';

const navigateTo = (history, pathKey, token, otherArgs = {}) => {
  const {
    queryParams = {},
    pathKeyisFullPath = false,
    forceRefresh = false
  } = otherArgs;

  const from = history.location.pathname;
  let to = (pathKeyisFullPath
    ? pathKey
    : paths[pathKey]
  );

  if (to === paths.notFound) to = '/';
  if (!token || token === ':token') return history.push('');
  if (to.match(/:token/)) to = to.replace(/:token/, token);

  if (queryParams) {
    const queryParamKeys = Object.keys(queryParams);
    if (queryParamKeys.length) {
      queryParamKeys.forEach(queryParamKey => {
        const urlRegex = new RegExp(':' + queryParamKey);
        if (to.match(urlRegex)) {
          to = to.replace(urlRegex, queryParams[queryParamKey]);
        }
      });
    }
  }

  if (!forceRefresh && from === to) return;

  return history.push(to, queryParams);
};

export default navigateTo;
