import styled from 'styled-components';
import { breakpointNames } from '../Mixins';
import { breakpoint } from 'styled-components-breakpoint';

const Nav = styled.div`
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.body.color.shading};
  max-width: 688px;
  width: 100%;
  color: ${({ color }) => color || 'unset'};
  transition: all 0.3s ease-in-out;

  ${breakpoint(breakpointNames.tablet)`
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 100vh;
    max-width: 283px;
    width: 100%;
    flex: 1;
    overflow-x: auto;
    position: sticky;
    background: ${({ theme }) => theme.module.color.background};
  `}

  ${breakpoint(breakpointNames.desktop)`
    max-width: 343px;
  `}

  &:hover {
    opacity: ${({ hoverable }) => (hoverable ? '0.5' : null)};
  }
`;

export default Nav;
