import styled from 'styled-components';
import { motion } from 'framer-motion';
import { base } from './Modal';
import { FLASH_TYPE_ERROR, FLASH_TYPE_SUCCESS } from '../../helpers/constants';

const topPosition = 100;
const minHeight = 50;

const Flex = styled(motion.div)`
	${base}
	transform: ${({ render }) =>
		render
			? 'translateY(0)'
			: `translateY(-${topPosition + minHeight * 2}px)`};
	top: ${topPosition}px;
	min-height: ${minHeight}px;
	padding: 14px;
	margin-left: 20px;
	margin-right: 20px;
 	align-items: center;
	justify-content: center;
	z-index: 201;
	width: 80%;
  color: ${({ theme }) => theme.button.color.text};
  background-color: ${({ theme, type }) => {
		if (type === FLASH_TYPE_ERROR) return theme.flash.error;
		if (type === FLASH_TYPE_SUCCESS) return theme.flash.success;
		return theme.flash.default;
	}}
`;

export default Flex;
