import styled from 'styled-components';
import { gap, breakpointNames, breakpoint } from '../Mixins';

const App = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  min-height: 100vh;
  width: 100%;
  position: relative;
  overflow: hidden;
  flex: 1;
  ${gap}
  background-color: ${({ theme }) => theme.body.color.shading};

  ${breakpoint(breakpointNames.tablet, `
    justify-content: flex-start;
    flex-direction: row;
    flex: 1;
  `)};
`;

export default App;
