import { useLocation } from 'react-router-dom';

const useQuery = () => {
	const queryParams = {};
	new URLSearchParams(useLocation().search).forEach((value, key) => {
		queryParams[key] = value;
	});
	return queryParams;
};

export default useQuery;
