import { css } from 'styled-components';
import { breakpoint } from 'styled-components-breakpoint';
import { breakpointNames } from './Mixins';

export const responsiveBackgroundColor = css`
  ${breakpoint(breakpointNames.mobile)`
    background-color: ${({ mobileStyles }) => {
      if (mobileStyles && mobileStyles.backgroundColor) return mobileStyles.backgroundColor;
      return null;
    }};
  `}

  ${breakpoint(breakpointNames.tablet)`
    background-color: ${({ tabletStyles }) => {
      if (tabletStyles && tabletStyles.backgroundColor) return tabletStyles.backgroundColor;
      return null;
    }};
  `}
  
  ${breakpoint(breakpointNames.desktop)`
    background-color: ${({ desktopStyles }) => {
      if (desktopStyles && desktopStyles.backgroundColor) {
        return desktopStyles.backgroundColor;
      }
      return null;
    }};
  `}
`;

export const responsiveWidth = css`
  ${breakpoint(breakpointNames.mobile)`
    width: ${({ mobileStyles }) => {
      if (mobileStyles && mobileStyles.width) return mobileStyles.width;
      return null;
    }};
  `}

  ${breakpoint(breakpointNames.tablet)`
    width: ${({ tabletStyles }) => {
      if (tabletStyles && tabletStyles.width) return tabletStyles.width;
      return null;
    }};
  `}
  
  ${breakpoint(breakpointNames.desktop)`
    width: ${({ desktopStyles }) => {
      if (desktopStyles && desktopStyles.width) return desktopStyles.width;
      return null;
    }};
  `}
`;
