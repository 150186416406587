import styled from 'styled-components';
import { height, width, margin, padding, networkColor, clickable, transform } from '../Mixins';
import { darken } from 'polished';

const SvgWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border:${({ theme, border }) => (border ? `1px solid ${theme.border.color.divider}` : null)};
  background-color: ${({ network, transparent }) => network && transparent !== true ? networkColor(network) : 'transparent'};
  border-radius: ${({ borderRadius, circular }) => {
    if (circular) return '100%';
    if (borderRadius) return borderRadius;
    return 'inherit';
  }};
  transition: all 0.3s ease-in-out;
  ${margin}
  ${padding}
  ${height}
  ${width}
  ${transform}
  &:hover {
    background-color: ${({ network, transparent }) =>
    network && transparent !== true ? darken(0.10, networkColor(network)) : null
  };
  }

  > svg {
    ${width}
    width: ${({ network }) => (network ? '60%' : null)};
    ${height}
    ${clickable}
    color: ${({ theme, muted, network, inputColor, buttonColor, grayscale, warning }) => {
    if (grayscale) return theme.text.color.default;
    if (network) return '#FFFFFF';
    if (muted) return theme.text.color.muted;
    if (inputColor) return theme.text.color.input;
    if (buttonColor) return theme.button.color.default;
    if (warning) return theme.button.color.error;
    return theme.text.color.default;
  }};
  }
`;

export default SvgWrapper;
