import '../App.css';

const breakpoints = {
  mobile: 0, // targeting all devices
  tablet: 737, // targeting devices that are larger than the iPhone 6 Plus (which is 736px in landscape mode)
  desktop: 1025, // targeting devices that are larger than the iPad (which is 1024px in landscape mode)
};

const base = {
  breakpoints,
  fontSizeHeader: '24px',
  fontSizeLarger: '48px',
  fontSizeLarge: '18px',
  fontSize: '16px',
  fontSizeMedium: '14px',
  fontSizeSmall: '12px',
  paddingMedium: '14px',
  paddingSmall: '9px',
  fontWeightBold: '800',
  fontWeight: '600',
  fontWeightMedium: '500',
  fontWeightLight: '300',
  borderRadius: '20px',
  borderRadiusModal: '20px',
  borderRadiusInput: '12px',
  borderRadiusButton: '12px',
  sharpBorderRadius: '5px',
  fontFamily: `'Poppins', sans-serif, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Fira-Sans, Ubuntu, Cantarell, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"`,
  colors: {
    success: '#5DB075'
  }
};

const pubgBase = {
  breakpoints,
  fontSizeHeader: '34px',
  fontSizeLarger: '60px',
  fontSizeLarge: '26px',
  fontSize: '24px',
  fontSizeMedium: '20px',
  fontSizeSmall: '16px',
  paddingMedium: '14px',
  paddingSmall: '9px',
  fontWeightBold: '800',
  fontWeight: '600',
  fontWeightMedium: '500',
  fontWeightLight: '300',
  borderRadius: '20px',
  borderRadiusModal: '20px',
  borderRadiusInput: '12px',
  borderRadiusButton: '12px',
  sharpBorderRadius: '5px',
  fontFamily: 'RefrigeratorDeluxe',
  colors: {
    success: '#5DB075'
  }
};

const light = {
  ...base,
  loading: {
    color: {
      default: base.colors.success,
    },
  },
  body: {
    color: {
      background: '#FFFFFF',
      shading: '#F0F0F0',
    },
  },
  text: {
    backgroundColor: {
      highlight: '#F4F4F4',
    },
    color: {
      default: '#000000',
      muted: '#737373',
      warning: '#D43333',
      heading: '#000000',
      highlight: '#000000',
    },
    fontSize: {
      header: base.fontSizeHeader,
      large: base.fontSizeLarge,
      medium: base.fontSizeMedium,
      small: base.fontSizeSmall,
    },
    fontWeight: {
      light: base.fontWeightLight,
      medium: base.fontWeightMedium,
      bold: base.fontWeightBold,
    },
    fontFamily: base.fontFamily,
  },
  link: {
    color: {
      default: '#2A2A2E',
      visited: '#BBBBBB',
      hover: '#BBBBBB',
      active: '#BBBBBB',
    },
    fontSize: base.fontSizeSmall,
    fontWeight: base.fontWeight,
  },
  module: {
    color: {
      background: '#FFFFFF',
      border: '#FFFFFF',
      oulined: '#000000',
    },
    borderRadius: base.borderRadius,
  },
  border: {
    color: {
      divider: '#BBBBBB',
      default: '#FAFAFA',
    },
    borderRadius: base.borderRadius,
  },
  input: {
    color: {
      background: '#F0F0F0',
      placeHolder: '#BBBBBB',
      border: '#787878',
      outline: base.colors.success,
      error: '#DC3545',
      selected: '#59B1D8',
    },
    borderRadius: base.borderRadiusInput,
    fontWeight: base.fontWeightLight,
  },
  label: {
    color: {
      default: '#333333',
    },
    fontWeight: base.fontWeightLight,
  },
  option: {
    fontWeight: base.fontWeightLight,
  },
  button: {
    color: {
      default: base.colors.success,
      secondary: '#BBBBBB',
      text: '#FFFFFF',
      outline: '#000000',
      outlineBackground: '#FFFFFF',
      error: '#DC3545',
    },
    borderRadius: base.borderRadiusButton,
  },
  flash: {
    error: '#DC3545',
    success: base.colors.success,
    default: '#BBBBBB',
  },
  footer: {
    color: {
      background: '#5A5A5A',
    },
  },
  modal: {
    color: {
      background: '#F9F9FA',
      text: '#666666',
    },
    borderRadius: base.borderRadiusModal,
    fontWeight: base.fontWeight,
  },
  badge: {
    color: {
      background: '#FFFFFF',
      text: '#000000',
    },
    fontSize: base.fontSizeSmall,
    fontWeight: base.fontWeight,
  },
  navigation: {
    padding: base.paddingMedium,
  },
};

const dark = {
  ...base,
  loading: {
    color: {
      default: base.colors.success,
    },
  },
  body: {
    color: {
      background: '#0C0C0D',
      shading: '#1F1D2B',
    },
  },
  text: {
    backgroundColor: {
      highlight: '#FFFFFF',
    },
    color: {
      default: '#FFFFFF',
      muted: '#B7B7B7',
      warning: '#D43333',
      heading: '#FFFFFF',
      input: '#3B4256',
      highlight: '#000000',
    },
    fontSize: {
      header: base.fontSizeHeader,
      large: base.fontSizeLarge,
      medium: base.fontSizeMedium,
      small: base.fontSizeSmall,
    },
    fontWeight: {
      light: base.fontWeightLight,
      medium: base.fontWeightMedium,
      bold: base.fontWeightBold,
    },
  },
  link: {
    color: {
      default: '#FAFAFA',
      visited: '#BBBBBB',
      hover: '#BBBBBB',
      active: '#BBBBBB',
    },
    fontSize: base.fontSizeSmall,
    fontWeight: base.fontWeight,
    fontFamily: base.fontFamily,
  },
  module: {
    color: {
      background: '#3C3F54',
      border: '#3C3F54',
      outlined: '#3C3F54',
    },
    borderRadius: base.borderRadius,
  },
  border: {
    color: {
      divider: '#fff',
      default: '#38383d',
    },
    borderRadius: base.borderRadius,
  },
  input: {
    color: {
      background: '#F8FAFC',
      placeHolder: '#BBBBBB',
      border: '#59B1D8',
      outline: base.colors.success,
      error: '#DC3545',
      text: '#3B4256',
      selected: '#59B1D8',
    },
    borderRadius: base.borderRadiusInput,
    fontWeight: base.fontWeightLight,
  },
  label: {
    color: {
      default: '#BBBBBB',
    },
    fontWeight: base.fontWeightLight,
  },
  option: {
    fontWeight: base.fontWeightLight,
  },
  button: {
    color: {
      default: base.colors.success,
      secondary: '#BBBBBB',
      text: '#FFFFFF',
      outline: '#FFFFFF',
      error: '#DC3545',
      outlineBackground: '#3C3F54',
    },
    borderRadius: base.borderRadiusButton,
  },
  flash: {
    error: '#DC3545',
    success: base.colors.success,
    default: '#BBBBBB',
  },
  footer: {
    color: {
      background: '#55B1D8',
    },
  },
  modal: {
    color: {
      background: '#38383d',
      text: '#FAFAFA',
    },
    borderRadius: base.borderRadiusModal,
    fontWeight: base.fontWeight,
  },
  badge: {
    color: {
      background: '#3C3F54',
      text: '#FFFFFF',
    },
    fontSize: base.fontSizeSmall,
    fontWeight: base.fontWeight,
  },
  navigation: {
    padding: base.paddingMedium,
  },
};

const pubg = {
  ...pubgBase,
  loading: {
    color: {
      default: pubgBase.colors.success,
    },
  },
  body: {
    color: {
      background: '#1D1D1F',
      shading: '#000000',
    },
  },
  text: {
    backgroundColor: {
      highlight: '#FFFFFF',
    },
    color: {
      default: '#FFFFFF',
      muted: '#B7B7B7',
      warning: '#D43333',
      heading: '#1D1D1F',
      highlight: '#1D1D1F',
    },
    fontSize: {
      header: pubgBase.fontSizeHeader,
      larger: pubgBase.fontSizeHeader,
      large: pubgBase.fontSizeLarge,
      medium: pubgBase.fontSizeMedium,
      small: pubgBase.fontSizeSmall,
    },
    fontWeight: {
      light: pubgBase.fontWeightLight,
      medium: pubgBase.fontWeightMedium,
      bold: pubgBase.fontWeightBold,
    },
    fontFamily: pubgBase.fontFamily,
    taskFontFamily: 'Consolas, monaco, monospace'
  },
  dashboard: {
    fontSize: {
      larger: pubgBase.fontSizeLarger,
    },
    fontFamily: 'PUBGBattlegrounds',
  },
  link: {
    color: {
      default: '#FAFAFA',
      visited: '#BBBBBB',
      hover: '#BBBBBB',
      active: '#BBBBBB',
    },
    fontSize: pubgBase.fontSize,
    fontWeight: pubgBase.fontWeight,
  },
  module: {
    color: {
      background: '#1D1D1F',
      border: '#1D1D1F',
      oulined: '#1D1D1F',
    },
    borderRadius: pubgBase.borderRadius,
  },
  border: {
    color: {
      divider: '#fff',
      default: '#38383d',
    },
    borderRadius: pubgBase.borderRadius,
  },
  input: {
    color: {
      background: '#1D1D1F',
      placeHolder: '#BBBBBB',
      border: '#59B1D8',
      outline: pubgBase.colors.success,
      error: '#DC3545',
      text: '#BBBBBB',
      selected: '#59B1D8',
    },
    borderRadius: pubgBase.borderRadiusInput,
    fontWeight: pubgBase.fontWeightLight,
  },
  label: {
    color: {
      default: '#BBBBBB',
    },
    fontWeight: pubgBase.fontWeightLight,
  },
  option: {
    fontWeight: pubgBase.fontWeightLight,
  },
  button: {
    color: {
      default: pubgBase.colors.success,
      secondary: '#BBBBBB',
      text: '#FFFFFF',
      outline: '#FFFFFF',
      outlineBackground: '#1D1D1F',
      error: '#DC3545',
    },
    borderRadius: pubgBase.borderRadiusButton,
    fontSize: pubgBase.fontSizeHeader,
  },
  flash: {
    error: '#DC3545',
    success: pubgBase.colors.success,
    default: '#BBBBBB',
  },
  footer: {
    color: {
      background: '#55B1D8',
    },
  },
  modal: {
    color: {
      background: '#38383d',
      text: '#FAFAFA',
    },
    borderRadius: pubgBase.borderRadiusModal,
    fontWeight: pubgBase.fontWeight,
  },
  badge: {
    color: {
      background: '#1D1D1F',
      text: '#FFFFFF',
    },
    fontSize: pubgBase.fontSizeSmall,
    fontWeight: pubgBase.fontWeight,
  },
  navigation: {
    padding: pubgBase.paddingSmall,
  },
};

const themes = {
  light,
  dark,
  pubg
};

export const getTheme = (theme) => {
  return themes[theme];
}
