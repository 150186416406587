import React, { useState, useEffect, useCallback } from 'react';
import S from '../../styled';
import { getTheme } from '../../styled/Themes';
import { useHistory } from 'react-router-dom';
import Module from '../Module';
import TaskGroup from './TaskGroup';

// Model Requests
import { getTasksByToken } from '../../data/requests/task';

// Icons
import {
  CheckCircle as CheckIcon
} from '@styled-icons/fa-regular';
import { ChevronLeft } from '@styled-icons/fa-solid';

export default function TaskList(props) {
  const history = useHistory();

  const { token, setStore, store: { currentProfile, t } } = props;
  const theme = getTheme(props.store.theme);

  const [isMounted, setIsMounted] = useState(true);
  const [completeData, setCompleteData] = useState([]);
  const [incompleteData, setIncompleteData] = useState([]);
  const [moduleIsLoading, setModuleIsLoading] = useState(false);

  const loadData = useCallback(async () => {
    if (!token) return;

    try {
      setModuleIsLoading(true);
      const incompleteData = await getTasksByToken(token);
      setIncompleteData(incompleteData);
      const completeData = await getTasksByToken(token, true);
      setCompleteData(completeData);
    } catch (error) {
      console.error('loadModuleData: ', error);
    } finally {
      if (isMounted) setModuleIsLoading(false);
    }
  }, [token, isMounted]);

  useEffect(() => {
    loadData();
    return () => setIsMounted(false);
  }, [loadData]);

  const moduleTitle = (
    <S.Flex alignCenter justifyStart flex={1} gap={14} whitespaceNoWrap paddingRight={40}>
      <S.Link marginLeft={10} onClick={history.goBack}>
        <ChevronLeft size={25} />
      </S.Link>
      <S.Text headerSize as="h2">
        {t('components.TasksModule.allTasks')}
      </S.Text>
    </S.Flex>
  );

  const isEmpty = !incompleteData.length && !completeData.length;

  const emptyContent = (
    <S.Flex column>
      <S.Flex row paddingBottom={10}>
        <S.SvgWrapper height={50} width={50}>
          <CheckIcon style={{ color: theme.button.color.default }} />
        </S.SvgWrapper>
      </S.Flex>

      <S.Flex row textAlignCenter padding={5}>
        <S.Text boldWeight smallSize>
          {t('components.TasksModule.noPendingTasks')}
        </S.Text>
      </S.Flex>

      <S.Flex row textAlignCenter padding={5}>
        <S.Text smallSize lightWeight>
          {t('components.TasksModule.tasksWillAppearHere')}
        </S.Text>
      </S.Flex>
    </S.Flex>
  );

  const shouldRenderCompleteTasks = Array.isArray(completeData) && !!completeData.length;

  return (
    <Module
      flex={1}
      titleBlock={moduleTitle}
      isLoading={moduleIsLoading}
      height="100%"
      isEmpty={isEmpty}
      emptyContent={emptyContent}
    >
      <S.Flex column alignStart justifyStart overflowAuto>
        <S.Flex column flex={0} justifyStart alignStart padding={10}>
          <S.Flex row alignStart flex={0} justifyStart marginTop={15} marginBottom={15}>
            <S.Text largeSize>
              {t('components.TasksModule.openTasks')}
            </S.Text>
          </S.Flex>
          {incompleteData.map((taskGroup) => (
            <TaskGroup
              key={`incomplete-tasks-${taskGroup.id}`}
              token={token}
              taskGroup={taskGroup}
              profile={currentProfile}
              setStore={setStore}
              onTaskUpdated={loadData}
              store={props.store}
            />
          ))}
          {shouldRenderCompleteTasks &&
            <>
              <S.Flex row justifyStart marginTop={40} marginBottom={15}>
                <S.Text largeSize flex={1}>
                  {t('components.TasksModule.completedTasks')}
                </S.Text>
              </S.Flex>
              {completeData.map((taskGroup) => (
                <TaskGroup
                  key={`complete-tasks-${taskGroup.id}`}
                  token={token}
                  taskGroup={taskGroup}
                  profile={currentProfile}
                  setStore={setStore}
                  onTaskUpdated={loadData}
                  store={props.store}
                />
              ))}
          </>
          }
        </S.Flex>
      </S.Flex>
    </Module>
  );
}
