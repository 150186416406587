import { facebookAuthenticate } from '../data/requests/user';
import { AUTHENTICATION_FACEBOOK } from '../helpers/constants';
import getUrlToken from '../helpers/getUrlToken';
import _ from 'lodash';

const facebookLogin = async() => {
  const response = await (new Promise((resolve) => {
    window.FB.login(resolve, {
      scope: 'email,pages_manage_metadata',
      return_scopes: true,
      auth_type: 'rerequest',
    })
  }));

  if (!response.authResponse || _.isEmpty(response.authResponse.userID)) {
    return false // user cancel
  }

  return await apiAuthenticate(AUTHENTICATION_FACEBOOK, response);
}

const apiAuthenticate = async(type, providerResponse) => {
  switch(type) {
    case AUTHENTICATION_FACEBOOK:
      const data = await facebookAuthenticate(getUrlToken(), providerResponse.authResponse.accessToken);
      return !_.isEmpty(data);
    default:
      return false;
  }
}

const facebookLogout = () => {
  window.FB.logout();
}

export const accountService = {
  facebookLogin,
  apiAuthenticate,
  facebookLogout,
};