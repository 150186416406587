import api from '../api';

const baseUrl = (token) => `codes/${token}`;

export const getCodes = (token, codeBankId, quantity, contactTaskId) =>
  api({
    method: 'get',
    url: `${baseUrl(token)}/${codeBankId}`,
    params: { quantity: quantity, contact_task_id: contactTaskId }
  })
