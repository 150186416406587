import React from 'react';
import S from '../../styled';
import { ChevronLeft } from '@styled-icons/fa-solid';

export default function ModuleHeader(props) {
  const { moduleName, history } = props;

  return (
    <S.Flex flex={20}>
      <S.Flex flex={0}>
        <S.Link navigation onClick={history.goBack}>
          <ChevronLeft size={25} />
        </S.Link>
      </S.Flex>
      <S.Flex flex={1} paddingRight={40} justifyStart>
        <S.Text largeSize whiteSpaceNormal wordBreakWord boldWeight>
          {moduleName}
        </S.Text>
      </S.Flex>
    </S.Flex>
  );
}
