import React, { useEffect } from 'react';
import S from '../../styled';

// Components
import Page from '../../components/Page';

export default function NotFound() {

  useEffect(() => {
    window.location.href = 'http://www.sideqik.com';
  });

  return (
    <Page>
      <S.Flex row alignCenter justifyCenter>
        <S.Spinner />
      </S.Flex>
    </Page>
  );
}
