import styled, { css } from 'styled-components';
import { motion } from 'framer-motion';
import { padding, margin, width, position, overflow, borderRadius } from '../Mixins';

const Image = styled(motion.img)`
  height: auto;
  max-height:100%;
  max-width:100%;
  border-radius: ${({ theme, borderRadius, sharpBorderRadius }) => {
    if (sharpBorderRadius) return theme.sharpBorderRadius;
    if (borderRadius) return borderRadius;
    return theme.borderRadius;
  }};
  overflow: ${({ overflowImage }) => {
    if (overflowImage) return;
    return 'hidden';
  }};
  ${padding}
  ${margin}
  ${width}
  ${position}
  ${overflow}
  ${borderRadius}
  ${({ objectFit }) => 
    objectFit && css`
      object-fit: ${objectFit}
    `}
`;

export default Image;
