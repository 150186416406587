import React, { useState, useEffect, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import S from '../../styled';

// Routes
import { routeNames } from '../../routing/routes';

// Components
import Page from '../../components/Page';
import Module from '../../components/Module';

// Requests
import { getPartnershipTerms } from '../../data/requests/partnership';

// Helpers
import navigateTo from '../../helpers/navigateTo';
import HtmlContent from '../../components/HtmlContent';

export default function Terms(props) {
  const history = useHistory();
  const { token, t } = props.store;
  const { name: brand_name, portal_brand_name } = props.store.account;

  const [terms, setTerms] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [isEmpty, setIsEmpty] = useState(false);

  const navigateToDashboard = (e) => {
    e.preventDefault();
    navigateTo(history, routeNames.dashboard, token);
  };

  const getTerms = useCallback(async () => {
    if (!token) return;

    let data;
    try {
      setIsLoading(true);
      data = await getPartnershipTerms(token);
      setTerms(data.terms);
    } catch (error) {
      console.error('getPartnershipTerms: ', error);
    } finally {
      setIsEmpty(!data?.terms);
      setIsLoading(false);
    }
  }, [token]);

  useEffect(() => {
    getTerms();
  }, [getTerms]);

  const emptyContent = (
    <S.Text muted>
      {portal_brand_name || brand_name} {t('pages.termsNotSetUp.termsNotSetUp')}
    </S.Text>
  );

  return (
    <Page title="Terms">
      <Module
        title="Terms"
        isLoading={isLoading}
        isEmpty={isEmpty}
        emptyContent={emptyContent}
      >
        <HtmlContent content={terms} />
        <S.Flex justifyBetween marginTop={20} >
          <S.Link marginLeft={10} onClick={navigateToDashboard}>{t('shared.back')}</S.Link>
        </S.Flex>
      </Module>
    </Page>

  );
}
