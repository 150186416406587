import React from 'react';
import S from '../../styled';
import NetworkIcon from '../NetworkIcon';

const RemoveNetworkModal = ({ profile: { network, raw_url, name, username }, confirm, cancel, store}) => {
  const { t } = store;

  return (
    <S.Flex alignCenter textAlignCenter column>
      <S.Flex alignCenter textAlignCenter column>
        <S.Flex>
          {t('components.RemoveNetworkModal.removeProfileConfirmation')}
        </S.Flex>
        <S.Flex alignCenter textAlign='center' row margin={20}>
          <S.Flex flex={0}>
            <NetworkIcon
              network={network}
              profileUrl={raw_url}
            />
          </S.Flex>
          <S.Flex column alignStart justifyStart marginLeft={10} flex={0} whiteSpace='nowrap' >
            <S.Text>{name || username}</S.Text>
          </S.Flex>
        </S.Flex>
      </S.Flex>
      <S.Flex gap={20} margin={10}>
        <S.Button gap={8} onClick={confirm}>
          {t('shared.confirm')}
        </S.Button>
        <S.Button secondary onClick={cancel}>
          {t('shared.cancel')}
        </S.Button>
      </S.Flex>
    </S.Flex>
  );
}

export default RemoveNetworkModal;
