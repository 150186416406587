import styled from 'styled-components';
import { darken } from 'polished';

const NavButton = styled.button`
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: row;
	padding: 18px 10px;
	border-radius: 8px;
	border: none;
	cursor: pointer;
	transition: all 0.3s ease-in-out;
	background-color: inherit;
	height: 25px;
	position: absolute;
	right: 24px;
	color: ${({ theme }) => theme.text.color.default};

	&:hover {
		background-color: ${({ theme }) => {
			return darken(0.1, theme.body.color.background);
		}};
	}

	> svg {
		height: 25px;
	}
`;

export default NavButton;
