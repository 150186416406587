import styled from 'styled-components';
import AsyncSelect from 'react-select/async';

const AutoCompleteSelect = styled(AsyncSelect)`
  font-weight: 300;
  width: 100%;
	color: ${({ theme }) => theme.input.color.text};
  & .Select__single-value {
    color: ${({ theme }) => theme.input.color.text};
  }
  & .Select__control {
    font-weight: ${({ theme }) => theme.input.fontWeight};
    width: 100%;

    background: ${({ theme }) => theme.input.color.background};
    border-radius: ${({ theme }) => theme.input.borderRadius};
  }
  & .Select__menu {
    background: ${({ theme }) => theme.input.color.background};
  }

  $ .Select__option {
    background: ${({ theme }) => theme.input.color.background};
  }
  .Select__option--is-focused {
    background: ${({ theme }) => theme.input.color.background};
  }
  
  .Select__option--is-selected {
    color: inherit;
    background: ${({ theme }) => theme.input.color.background};
  }
`;

export default AutoCompleteSelect;