import React from 'react';
import { useHistory } from 'react-router-dom';
import HtmlContent from '../../HtmlContent';

// Routes
import { routeNames } from '../../../routing/routes';

// Styled components
import S from '../../../styled';

// Helpers
import navigateTo from '../../../helpers/navigateTo';

// Icons
import { ChevronRight as ChevronRightIcon } from '@styled-icons/fa-solid';

// Default Image
import { ReactComponent as DefaultProgramImage } from '../../../assets/svg/no_program_image.svg';

export default function CampaignProgramPreview(props) {
  const { program, store } = props;
  const { token } = store;
  const history = useHistory();
  const { id: programId, activation_name, influencer_incentive, derived_preview_image_url: previewImage } = program;
  const navigateToProgramSignUp= (e) => {
    e.preventDefault();
    navigateTo(history, routeNames.campaignProgramSignUp, token, { queryParams: { programId: programId } })
  };

  const ChevronRight = (
    <S.SvgWrapper height={40} width={40} marginRight={10} displayInline scale={0.5}>
      <ChevronRightIcon />
    </S.SvgWrapper>
  );

  const renderPreviewImage = (mobile = false) => {
    const width = mobile ? 80 : 150;

    if (previewImage) {
      return (
        <S.Image
          sharpBorderRadius
          width={width}
          src={previewImage}
          alt={activation_name}
        />
      )
    } else {
      return (
        <S.SvgWrapper width={width}>
          <DefaultProgramImage />
        </S.SvgWrapper>
      )
    }
  }

  return (
    <S.Flex row gap={24} padding={5} clickable hoverable onClick={navigateToProgramSignUp}>
      <S.Visibility mobile width='100%'>
        <S.Flex column gap={8}>
          <S.Flex row gap={8}>
            {renderPreviewImage(true)}
            <S.Text maxWidth boldWeight marginBottom={4}>{activation_name}</S.Text>
            {ChevronRight}
          </S.Flex>
          <HtmlContent content={influencer_incentive} />
        </S.Flex>
      </S.Visibility>
      <S.Visibility tablet desktop alignCenter width='100%'>
        <S.Flex gap={10}>
          {renderPreviewImage()}
          <S.Flex column>
            <S.Text maxWidth boldWeight marginBottom={4}>{activation_name}</S.Text>
            <HtmlContent content={influencer_incentive} />
          </S.Flex>
        </S.Flex>
        {ChevronRight}
      </S.Visibility>
    </S.Flex>
  );
}
