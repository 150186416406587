import { useState, useEffect, useCallback } from 'react';

// Model
import { getCurrentAccountByToken } from './data/requests/account';

// Helpers
import getUrlToken from './helpers/getUrlToken';
import {
  AUTHED_STATUS_INVALID,
  AUTHED_STATUS_LOADING,
  FLASH_TYPE_SUCCESS,
} from './helpers/constants';

import { useTranslation } from 'react-i18next';

export default function Store(props) {
  // ------------------------------
  // STORE STATE
  // -------------------------------
  // Theming
  const [theme, setTheme] = useState('light');
  // Portal Token
  const [token, setToken] = useState('');
  // Account
  const [account, setAccount] = useState({});
  const [accountIsLoading, setAccountIsLoading] = useState(true);
  const [currentProfile, setCurrentProfile] = useState(undefined);
  const [currentPerformance, setCurrentPerformance] = useState(undefined);

  // Authentication
  const [authedStatus, setAuthedStatus] = useState(AUTHED_STATUS_LOADING);
  const [currentUser, setCurrentUser] = useState(null);
  const [userIsLoggingOut, setUserIsLoggingOut] = useState(false)

  // Network Authentication
  const [facebookToken, setFacebookToken] = useState(null);
  const [googleToken, setGoogleToken] = useState(null);
  const [authenticatingNetwork, setAuthenticatingNetwork] = useState(undefined);

  // Flash Messages
  const [flash, setFlash] = useState(null);

  // Modal
  const [renderModal, setRenderModal] = useState(false);
  const [modalTitle, setModalTitle] = useState('');
  const [modalContent, setModalContent] = useState(null);
  const [modalContentUnsafe, setModalContentUnsafe] = useState(null);
  // Navigation
  const [renderNavigation, setRenderNavigation] = useState(false);
  const [lastPrivateRouteVisited, setLastPrivateRouteVisited] = useState(null);

  const { t, i18n } = useTranslation('translation', {lng: account.portal_language});

  // -----------------------------
  // STORE STATE END
  // -----------------------------

  // ------------------------------
  // METHODS
  // -------------------------------
  // Portal Token
  const updateToken = useCallback(() => {
    const incomingToken = token || getUrlToken();
    if (incomingToken !== token) {
      setToken(incomingToken);
    }
  }, [token]);

  // Theming
  const setThemeFromAccount = useCallback(() => {
    if (!account || !account.portal_theme) return;

    setTheme(account.portal_theme);
  }, [account]);

  // Account
  const getCurrentAccount = useCallback(async () => {
    if (!token) {
      setAccountIsLoading(false);
      return;
    }

    if (account && ((account.portal_token || account.token) === token)) {
      return;
    }

    try {
      setAccountIsLoading(true);
      const data = await getCurrentAccountByToken(token);
      setAccount(data);
    } catch (error) {
      console.error('getCurrentAccount: ', error);
      if (authedStatus !== AUTHED_STATUS_INVALID) {
        setAuthedStatus(AUTHED_STATUS_INVALID);
      }
    } finally {
      setAccountIsLoading(false);
    }
  }, [token, authedStatus, account]);

  const setRenderFlashMessage = useCallback((message, type = FLASH_TYPE_SUCCESS, options = {}) => {
    setFlash({ message, type, ...options });
  }, [setFlash]);

  const clearFlash = useCallback(() => setFlash(null), []);

  // Modal
  const setModal = (open, title, content, isUnsafe) => {
    setRenderModal(open);
    if (open) {
      setModalTitle(title);
      if (isUnsafe) {
        setModalContentUnsafe(content);
      } else {
        setModalContent(content);
      }
    } else {
      setModalTitle('');
      setModalContent(null);
      setModalContentUnsafe(null);
    }
  };
  // ------------------------------
  // METHODS END
  // -------------------------------

  // -------------------------------
  // HOOKS
  // -------------------------------
  useEffect(() => {
    setThemeFromAccount();
  }, [setThemeFromAccount]);

  // Runs once on mount and when dependencies in array below update
  useEffect(() => {
    updateToken();
    getCurrentAccount();
  }, [authedStatus, updateToken, getCurrentAccount]);

  // -------------------------------
  // Render props pattern
  // -------------------------------
  return props.render({
    store: {
      theme,
      t,
      token,
      account,
      accountIsLoading,
      authedStatus,
      currentUser,
      userIsLoggingOut,
      currentProfile,
      currentPerformance,
      // Network tokens
      facebookToken,
      googleToken,
      authenticatingNetwork,
      // Flash Messages
      flash,
      // Navigation
      renderNavigation,
      lastPrivateRouteVisited,
      // Modal
      renderModal,
      modalTitle,
      modalContent,
      modalContentUnsafe,
    },
    setStore: {
      setTheme,
      setToken,
      setAuthedStatus,
      setCurrentUser,
      setUserIsLoggingOut,
      setCurrentProfile,
      setCurrentPerformance,
      // Network tokens
      setFacebookToken,
      setGoogleToken,
      setAuthenticatingNetwork,
      // Flash Messages
      setRenderFlashMessage,
      clearFlash,
      // Navigation
      setRenderNavigation,
      setLastPrivateRouteVisited,
      // Modal
      setRenderModal,
      setModalTitle,
      setModalContent,
      setModalContentUnsafe,
      setModal,
    },
  });
}
