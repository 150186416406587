import React from 'react';

// Components
import Page from '../../components/Page';
import CommunicationPreferencesModule from '../../components/CommunicationPreferencesModule';
import DelegateRelationshipModule from '../../components/DelegateRelationshipModule';
import UserSettingsModule from '../../components/UserSettingsModule'

// Helpers
import { useFeatureFlag } from '../../helpers/useFeatureFlag';
import { getDelegateRelationshipIdFromLocalStorage } from '../../helpers/delegates';

export default function SettingsPage(props) {
  const { store, setStore } = props;
  const { currentUser, t } = store;

  const showDelegateRelationshipModule = useFeatureFlag(store, 'portal_delegate') && !currentUser?.acting_as_delegate && !!!getDelegateRelationshipIdFromLocalStorage();

  return (
    <Page title={t('navigation.settings')} justifyStart alignStart>
      <CommunicationPreferencesModule store={store} setStore={setStore} />
      {showDelegateRelationshipModule && <UserSettingsModule store={store} setStore={setStore} />}
      {showDelegateRelationshipModule && <DelegateRelationshipModule store={store} setStore={setStore} />}
    </Page>
  );
}
