import React, { useCallback, useEffect, useContext, useState } from "react";
import { signOut, isAuthenticated } from '../data/requests/user';
import { addTikTokAccount, connectTikTokAccount } from '../data/requests/profile';
import {
  AUTHENTICATION_FACEBOOK,
  AUTHENTICATION_GOOGLE,
  AUTHENTICATION_INSTAGRAM,
  LOCAL_STORAGE_AUTHENTICATION_NETWORK_TYPE,
  AUTHED_STATUS_INVALID,
  AUTHED_STATUS_AUTHENTICATED,
  AUTHED_STATUS_UNAUTHENTICATED,
  AUTHED_STATUS_LOADING,
  AUTHENTICATION_TIKTOK,
  DELEGATE_RELATIONSHIP_LOCAL_STORAGE_NAME,
} from '../helpers/constants';
import useDebouncedEffect from '../helpers/useDebouncedEffect';
import { accountService } from "../services/account.service";
import { oauthRedirect, parseOAuthFailure } from "./oauth";

// Helpers
import { getDelegateRelationshipIdFromLocalStorage, removeDelegateRelationshipIdFromLocalStorage } from "../helpers/delegates";
// LogRocket
import LogRocket from 'logrocket';

const Context = React.createContext({
  loginToNetwork: (networkType) => { },
  logout: () => { },
  connectToNetwork: async (networkType) => { },
});

export function useAuthentication() {
  return useContext(Context)
}

export function AuthenticationProvider({
  store: {
    token,
    currentUser,
    authedStatus
  },
  setStore: {
    setCurrentUser,
    setAuthedStatus,
    setUserIsLoggingOut,
    setAuthenticatingNetwork,
  },
  children }) {

  const [error, setError] = useState(null);

  const getAuthenticationStatus = useCallback(async () => {
    if (!token) return;

    try {
      const data = await isAuthenticated(token);
      if (!data) {
        setAuthedStatus(AUTHED_STATUS_UNAUTHENTICATED);
        removeDelegateRelationshipIdFromLocalStorage();
        setCurrentUser(null);
      } else if (data.account_locked) {
        setAuthedStatus(AUTHED_STATUS_INVALID);
        removeDelegateRelationshipIdFromLocalStorage();
        setCurrentUser(null);
      } else {
        setAuthedStatus(AUTHED_STATUS_AUTHENTICATED);
        data.delegate_relationship_id = getDelegateRelationshipIdFromLocalStorage();
        setCurrentUser({ ...data });
      }
    } catch (error) {
      handleAuthError(error.response?.data);
    }
  }, [token, setAuthedStatus, setCurrentUser]);

  const setLogRocketIdentity = useCallback(async () => {
    if (currentUser && authedStatus === AUTHED_STATUS_AUTHENTICATED) {
      LogRocket.identify(currentUser.email);
    }
  }, [currentUser, authedStatus]);

  // Runs debounced, every x seconds
  useDebouncedEffect(
    () => {
      if (token && authedStatus === AUTHED_STATUS_AUTHENTICATED)
        getAuthenticationStatus();
    },
    10000,
    [currentUser, authedStatus, getAuthenticationStatus]
  );

  useEffect(() => {
    if (authedStatus === AUTHED_STATUS_LOADING) {
      const errorData = parseOAuthFailure();
      if (errorData.error) {
        handleAuthError(errorData)
      } else {
        getAuthenticationStatus();
      }
    }
  }, [getAuthenticationStatus, authedStatus, setAuthedStatus]);

  useEffect(setLogRocketIdentity, [setLogRocketIdentity]);

  const loginToNetwork = async (networkType) => {
    try {
      setAuthenticatingNetwork(networkType);
      if (networkType === AUTHENTICATION_FACEBOOK) {
        const result = await accountService.facebookLogin();
        if (!result) return; // user canceled
        getAuthenticationStatus();
      } else if (networkType === AUTHENTICATION_GOOGLE) {
        oauthRedirect(AUTHENTICATION_GOOGLE, 'login', { prompt: 'consent' });
      }

      localStorage.setItem(LOCAL_STORAGE_AUTHENTICATION_NETWORK_TYPE, networkType);
    } catch (err) {
      if (err.response?.data?.error) {
        setAuthedStatus(AUTHED_STATUS_INVALID);
        setError(err.response.data);
      }
    } finally {
      setAuthenticatingNetwork(undefined)
    }
  }

  const connectToNetwork = async (networkType, add = false, profile = null) => {
    if (networkType === AUTHENTICATION_TIKTOK) {
      if (profile) {
        if (add) {
          return await addTikTokAccount(token, profile);
        } else {
          return await connectTikTokAccount(token, profile);
        }
      }
    } else {
      let params = {};
      if (networkType === AUTHENTICATION_GOOGLE) {
        params = { ...params, prompt: "consent" };
      } else if (networkType === AUTHENTICATION_FACEBOOK || networkType === AUTHENTICATION_INSTAGRAM) {
        params = { ...params, auth_type: "rerequest" };
      }
      const action = "connect";
      let state = {};
      if (profile) {
        // This only returns a single profile id right now,
        // but may be updated in the future to return an array.
        state = { profile_ids: profile.id };
      }
      oauthRedirect(networkType, action, params, state);
    }
  };

  const handleAuthError = (errorData) => {
    const errorCode = errorData?.error_code

    if (errorCode === 'Unauthorized') {
      setAuthedStatus(AUTHED_STATUS_UNAUTHENTICATED);
    } else {
      setAuthedStatus(AUTHED_STATUS_INVALID);
    }
    removeDelegateRelationshipIdFromLocalStorage();
    setCurrentUser(null);
    setError(errorData);
  };

  const logout = async () => {
    try {
      const type = localStorage.getItem(LOCAL_STORAGE_AUTHENTICATION_NETWORK_TYPE);
      if (type === AUTHENTICATION_FACEBOOK) {
        accountService.facebookLogout();
      }
      localStorage.removeItem(LOCAL_STORAGE_AUTHENTICATION_NETWORK_TYPE);
      setAuthenticatingNetwork(null);
      await signOut();
    } catch (err) {
      console.error('signOutUser:', err);

    } finally {
      removeDelegateRelationshipIdFromLocalStorage();
      setUserIsLoggingOut(true);
      setCurrentUser(null);
      setAuthedStatus(AUTHED_STATUS_UNAUTHENTICATED);
    }
  }

  return (<Context.Provider value={{ loginToNetwork, logout, connectToNetwork, error }}>
    {children}
  </Context.Provider>)
}
