import Page from '../../components/Page';

import TaskList from '../../components/TasksModule/TaskList';

export default function TasksPage(props) {
  const { store, setStore } = props;
  const { theme, token } = store;

  return (
    <Page>
      <TaskList
        token={token}
        theme={theme}
        store={store}
        setStore={setStore}
      />
    </Page>
  );
};
