import React, { useEffect, useState, useCallback } from 'react';
import S from '../../styled';
import { FLASH_TYPE_SUCCESS, FLASH_TYPE_ERROR } from '../../helpers/constants';

// Components
import Module from '../Module';
import Tooltip from '../Tooltip';

// Helpers
import { useFeatureFlag } from '../../helpers/useFeatureFlag';

// Requests
import {
  getCommunicationPreferences,
  updateCommunicationPreferences,
} from '../../data/requests/communicationPreferences';

// Icons
import InformationalIcon from '../InformationalIcon';

export default function CommunicationPreferencesModule(props) {
  const isPortalCampaignSignupFeatureFlag = useFeatureFlag( props.store, 'portal_campaign_sign_up');
  const { setStore } = props;
  const { token, t } = props.store;
  const [isMounted, setIsMounted] = useState(true);
  const [moduleData, setModuleData] = useState({});
  const [moduleIsLoading, setModuleIsLoading] = useState(true);
  const { setRenderFlashMessage } = setStore;

  const [receiveNewCampaignCommunications, setReceiveNewCampaignCommunications] = useState(false);
  const [receiveUpdateCampaignCommunications, setReceiveUpdateCampaignCommunications] = useState(false);
  const [receiveNewCampaignSignupCommunications, setReceiveNewCampaignSignupCommunications] = useState(false);
  const [receiveTasksDueCommunications, setReceiveTasksDueCommunications] = useState(false);

  const loadModuleData = useCallback(async () => {
    if (!token) return;
    try {
      setModuleIsLoading(true);
      const data = await getCommunicationPreferences(token);
      setModuleData(data);
    } catch (error) {
      console.error('loadModuleData: ', error);
    } finally {
      if (isMounted) setModuleIsLoading(false);
    }
  }, [token, isMounted]);

  const setCommunicationPreferences = useCallback(() => {
    if (!moduleData || !moduleData.length) return;

    const communicationTypeGroupToSetStateMapping = {
      NewActivationCommunication: setReceiveNewCampaignCommunications,
      UpdateActivationCommunication: setReceiveUpdateCampaignCommunications
    };

    // NOTE: Group communication preferences to it's type
    const preferencesToTypeMapping = {};
    moduleData.forEach((data) => {
      const type = data.type_group;

      if (!Array.isArray(preferencesToTypeMapping[type])) {
        preferencesToTypeMapping[type] = [];
      }
      if(data.communication_type == 'NewCampaignSignupAvailableActivationCommunication') {
        setReceiveNewCampaignSignupCommunications(data?.use_email)   
      }
      if(data.communication_type == 'TasksDueForPortalUserActivationCommunication') {
        setReceiveTasksDueCommunications(data?.use_email)   
      }
      preferencesToTypeMapping[type].push(data);
    });

    if (!Object.keys(preferencesToTypeMapping).length) return;

    // NOTE: Set communication preferences checkbox values
    Object.keys(communicationTypeGroupToSetStateMapping).forEach((typeGroup) => {
      const preferences = preferencesToTypeMapping[typeGroup];
      const setStateMethod = communicationTypeGroupToSetStateMapping[typeGroup];
      // NOTE: Given all of the preferences are the same for a group
      // this only accounts for the first one. Saving here will set the
      // whole group to that value if any are not set.

      const valueToSet = preferences.length ? preferences[0].use_email : false;
      setStateMethod(valueToSet);
    });
  }, [moduleData]);

  useEffect(() => {
    loadModuleData();
    return () => setIsMounted(false);
  }, [loadModuleData]);

  useEffect(() => {
    setCommunicationPreferences();
  }, [setCommunicationPreferences]);

  const handleCommunicationPreferenceChange = (e) => {
    const name = e.target.name;
    const newState = e.target.checked;
    const setStateMethod = `set${name[0].toUpperCase() + name.substring(1)}(${newState})`;
    // NOTE: evaluates from the name attribute off of the checkbox,
    // which setState method to utilize

    // eslint-disable-next-line no-eval
    eval(setStateMethod);
  };

  const onNotificationSubmit = async (e) => {
    e.preventDefault();
    try {
      const params = { 
        receiveNewCampaignCommunications, 
        receiveUpdateCampaignCommunications, 
        receiveNewCampaignSignupCommunications,
        receiveTasksDueCommunications
      };

      const data = await updateCommunicationPreferences(token, params);
      setModuleData(data);
      setRenderFlashMessage(
        t('components.CommunicationPreferencesModule.updateAccountSettingsSucccess'),
        FLASH_TYPE_SUCCESS
      );
    } catch (error) {
      console.error('onNotificationSubmit: ', error);
      setRenderFlashMessage(
        t('components.CommunicationPreferencesModule.updateAccountSettingsError'),
        FLASH_TYPE_ERROR
      );
    }
  };

  const randerReceiveNewCampaignSignupCommunications = () => {
    return (
      <S.Flex gap={24} flex={0} alignStart>    
         <S.Checkbox
            name="receiveNewCampaignSignupCommunications"
            checked={receiveNewCampaignSignupCommunications}
            onChange={handleCommunicationPreferenceChange}
          />
          <S.Text as="p" maxWidth lightWeight marginTop={3}>
            {t('components.CommunicationPreferencesModule.notifyNewCampaignAvailable')}
          </S.Text>
      </S.Flex>
    )
  }
  return (
    <Module title={t('navigation.notifications')} isLoading={moduleIsLoading}>
      <S.Form
        column
        justifyBetween
        gap={14}
        flex={1}
        marginTop={14}
        maxWidth
        onSubmit={onNotificationSubmit}
      >
        <S.Flex column gap={24} alignStart justifyStart>
          <S.Flex gap={24} flex={0}>
            <S.Checkbox
              name="receiveNewCampaignCommunications"
              checked={receiveNewCampaignCommunications}
              onChange={handleCommunicationPreferenceChange}
            />
            <S.Text as="p" maxWidth lightWeight marginTop={3}>
              {t('components.CommunicationPreferencesModule.notifyWhenAddedToNewCampaign')}
            </S.Text>
          </S.Flex>
          <S.Flex gap={24} flex={0} alignStart>
            <S.Checkbox
              name="receiveUpdateCampaignCommunications"
              checked={receiveUpdateCampaignCommunications}
              onChange={handleCommunicationPreferenceChange}
            />
            <S.Text as="p" maxWidth lightWeight marginTop={3}>
              {t('components.CommunicationPreferencesModule.notifyCampaignUpdatesParticipation')}
            </S.Text>
          </S.Flex>
          {
            isPortalCampaignSignupFeatureFlag && randerReceiveNewCampaignSignupCommunications()
          }
          <S.Flex gap={24} flex={0}>
            <S.Checkbox
              name="receiveTasksDueCommunications"
              checked={receiveTasksDueCommunications}
              onChange={handleCommunicationPreferenceChange}
            />
            <S.Text as="p" maxWidth lightWeight marginTop={3}>
              {t('components.CommunicationPreferencesModule.notifyTaskDueSoonOrPastDue')}

              <Tooltip 
                text={t('components.CommunicationPreferencesModule.remindersTaskDueNotificationsTooltip')}
                position='left'
              >
                <InformationalIcon></InformationalIcon>
              </Tooltip>
            </S.Text>
          </S.Flex>
        </S.Flex>
        <S.Button type="submit">{t('shared.save')}</S.Button>
      </S.Form>
    </Module>
  );
}
