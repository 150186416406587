import styled from 'styled-components';
import { padding, margin, flex, width, height } from '../Mixins';

const Form = styled.form`
  width: 100%;
  max-width: 688px;
  gap: 18px;
  ${padding}
  ${margin}
  ${flex}
  ${width}
  ${height}
`;

export default Form;
