import React from 'react';
import S from '../../styled';
import { Download } from '@styled-icons/fa-solid';

export default function DownloadFileLinks(props) {
  const { uploads } = props;

  if (!uploads || (Array.isArray(uploads) && !uploads.length)) {
    return null;
  }

  return (
    <S.Flex justifyStart flexWrap gap={16} padding={10} paddingLeft={53}>
      {uploads.map(({ id, original_filename, download_url }) => {
          return (
            <S.Link key={id} href={download_url} textDecorationNone download>
              <S.Text noWrap mediumSize hoverable boldWeight>
                <Download size={10} /> {original_filename}
              </S.Text>
            </S.Link>
          );
        })
      }
    </S.Flex>
  );
}
