import React, { useState } from 'react';
import S from '../../styled';

// Components
import Module from '../Module';
import FileSelect from '../../components/FileSelect';
import ContentForReview from './ContentForReview';

import { ChevronLeft } from '@styled-icons/fa-solid';

export default function UploadContentReview({
  contentReview,
  isLoading,
  goBack,
  onContentSelected,
  contentURL,
  submitForReview,
  uploadingFileContentType,
  accept,
  isPostContentTask,
  fileOrContent,
  description,
  fileType,
  icon,
  uploadName,
  textPlaceholder,
  store
}) {
  const [text, setText] = useState(contentReview.text || "");
  const [contentSelected, setContentSelected] = useState(false);
  const { t } = store;

  const handleFile = (e) => {
    onContentSelected(e);
    setContentSelected(true);
  };

  const handleTextChange = (e) => {
    const newText = e.target.value;
    setText(newText);
  };

  const handleSubmit = () => {
    submitForReview(text);
  };

  const contentBody = (
    <S.Flex textAlign='center' padding={0} column flex={1} justifyStart>
      <S.Flex textAlign='center' marginTop={10} padding={0} column flex={1} justifyStart tabletStyles={{ width: '70%' }} desktopStyles={{ width: '70%' }} >
        <ContentForReview
          flex={1}
          contentReview={contentReview}
          uploadingFileContentType={uploadingFileContentType}
          desktopStyles={{ width: '40%' }}
          borderRadius={0}
          objectFit='cover'
          src={contentURL}
          isPostContentTask={isPostContentTask}
          uploadName={uploadName}/>
        <S.Flex row margin={30}>
          <S.Textarea padding={5} rows={4} value={text} onChange={handleTextChange} placeholder={textPlaceholder} />
        </S.Flex>
        <S.Flex row>
          <S.Button task onClick={handleSubmit}>{t('components.UploadContentReview.submitForReview')}</S.Button>
        </S.Flex>
      </S.Flex>
    </S.Flex>
  );

  const selectContent = (
    <S.Flex flex={1} column>
      <S.Flex flex={1} textAlignCenter>
        <S.Text mediumSize lightWeight marginLeft={20} marginRight={20}>
          {description}
        </S.Text>
      </S.Flex>
      <S.Flex flex={1} textAlignCenter>
        <S.Text mediumSize lightWeight marginLeft={20} marginRight={20}>
          {fileType}
        </S.Text>
      </S.Flex>
      <S.Flex flex={1} padding={30}>
        {icon}
      </S.Flex>
      <S.Flex flex={1} padding={10} tabletStyles={{ width: '70%' }} desktopStyles={{ width: '70%' }} >
        <FileSelect handleFile={handleFile} accept={accept} maxSize={805306368} uploadFromDeviceText={t('components.FileSelect.uploadFromDevice')} fileTooLargeText={t('components.FileSelect.fileTooLarge')}/>
      </S.Flex>
    </S.Flex>
  );

  const moduleTitle = (
    <S.Flex flex={1}>
      <S.Flex flex={0}>
        <S.Link marginLeft={10} onClick={goBack}><ChevronLeft size={25} /></S.Link>
      </S.Flex>
      <S.Flex flex={1} whitespaceNoWrap paddingRight={40}>
        <S.Text largeSize boldWeight capitalize>
          {t('components.UploadContentReview.upload')} {fileOrContent}
        </S.Text>
      </S.Flex>
    </S.Flex>
  );

  return (
    <Module
      flex={1}
      titleBlock={moduleTitle}
      isLoading={isLoading}
    >
      <S.Flex column>
        <S.Flex row padding={10}>
          <S.Text muted smallSize lightWeight>
            {contentReview?.content_activity?.text}
          </S.Text>
        </S.Flex>
        {
          contentSelected ?
            contentBody :
            selectContent
        }
      </S.Flex>
    </Module>
  );
}
