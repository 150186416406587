import React from 'react';
import S from '../../styled';

// Components
import Page from '../../components/Page';

export default function PersonalInfo(props) {
	const { store: { t } } = props;

	return (
		<Page>
			<S.Form column>
				<S.Text>
					{t('pages.PersonalInfo.tellUsMoreAboutYourself')}
				</S.Text>
				<S.Flex column alignStart>
					<S.Label htmlFor="gender">
						{t('pages.PersonalInfo.gender')}
					</S.Label>
					<S.Select name="gender">
						<S.Option hidden>
							{t('pages.PersonalInfo.selectGender')}
						</S.Option>
						<S.Option value="male">
							{t('pages.PersonalInfo.male')}
						</S.Option>
						<S.Option value="female">
							{t('pages.PersonalInfo.female')}
						</S.Option>
					</S.Select>
				</S.Flex>
				<S.Flex column alignStart>
					<S.Label htmlFor="birthday">
						{t('pages.PersonalInfo.birthday')}
					</S.Label>
					<S.Input name="birthday" />
				</S.Flex>
				<S.Flex column alignStart>
					<S.Label htmlFor="language">
						{t('pages.PersonalInfo.primaryLanguage')}
					</S.Label>
					<S.Select name="language">
						<S.Option hidden>
							{t('pages.PersonalInfo.selectLanguage')}
						</S.Option>
						<S.Option value="english">
							{t('pages.PersonalInfo.english')}
						</S.Option>
					</S.Select>
				</S.Flex>
				<S.Flex column alignStart>
					<S.Label htmlFor="bio">
						{t('pages.PersonalInfo.bio')}
					</S.Label>
					<S.Input name="bio" as="textarea" />
				</S.Flex>
				<S.Button type="submit">
					{t('pages.PersonalInfo.submit')}
				</S.Button>
			</S.Form>
		</Page>
	);
}
