import styled from 'styled-components';
import { padding, margin } from '../Mixins';

const Label = styled.label`
	color: ${({ theme }) => theme.label.color.default};
	font-weight: ${({ theme }) => theme.label.fontWeight};
	${padding}
	${margin}
`;

export default Label;
