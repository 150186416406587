import React from 'react';
import { Helmet } from 'react-helmet';

export default function Head(props) {
	const { account } = props.store;

	const brandName = account.portal_brand_name || account.name || 'Sideqik';
	const favicon = account.portal_logo_url || account.logo_url;
	const favicon32 = account.portal_logo_url || account.logo_url;
	const favicon16 = account.portal_logo_url || account.logo_url;
	const appleTouchIcon = account.portal_logo_url || account.logo_url;

	// Note: Helmet throws an error because of strict mode but it is fine and there isn't much to be done about it:
	/* index.js:1 Warning: Using UNSAFE_componentWillMount in strict mode is not recommended and may indicate bugs in your code. See https://reactjs.org/link/unsafe-component-lifecycles for details.
	* Move code with side effects to componentDidMount, and set initial state in the constructor.
	Please update the following components: SideEffect(NullComponent) */

	return (
		<Helmet>
			<html lang="en" />
			<title>{`${brandName}`} Creator Portal</title>
			<meta name="description" content={`${brandName} Creator Portal`} />
			<meta name="theme-color" content="#E6E6FA" />
			{favicon && <link rel="icon" href={favicon} />}
			{favicon32 && <link rel="icon" type="image/png" sizes="32x32" href={favicon32} />}
			{favicon16 && <link rel="icon" type="image/png" sizes="16x16" href={favicon16} />}
			{appleTouchIcon && <link rel="apple-touch-icon" sizes="180x180" ref={appleTouchIcon} />}
		</Helmet>
	);
}
