import React from 'react';
import styled from 'styled-components';

const Spinner = ({ mini }) => (
	<StyledSpinner mini={mini} viewBox="0 0 50 50">
		<circle
			className="path"
			cx="25"
			cy="25"
			r="20"
			fill="none"
			strokeWidth="4"
		/>
	</StyledSpinner>
);

const StyledSpinner = styled.svg`
	animation: rotate 1s linear infinite;
	margin: ${({ mini }) => mini ? '0px' : '40px'};
	width: ${({ mini }) => mini ? '20px' : '50px'};
	height: ${({ mini }) => mini ? '20px' : '50px'};

	& .path {
		stroke: ${({ theme }) => theme.loading.color.default };
		stroke-linecap: round;
		animation: dash 1.5s ease-in-out infinite;
	}

	@keyframes rotate {
		100% {
			transform: rotate(360deg);
		}
	}
	@keyframes dash {
		0% {
			stroke-dasharray: 1, 150;
			stroke-dashoffset: 0;
		}
		50% {
			stroke-dasharray: 90, 150;
			stroke-dashoffset: -35;
		}
		100% {
			stroke-dasharray: 90, 150;
			stroke-dashoffset: -124;
		}
	}
`;

export default Spinner;
