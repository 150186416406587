import React, { useCallback, useEffect, useState } from 'react';
import S from '../../styled';
import { useHistory } from 'react-router';

// Components
import Module from '../../components/Module';
import Copyable from '../../components/Copyable';

// Data
import { getSources } from '../../data/requests/sources';
import { getProducts, getProductLink } from '../../data/requests/products';

// Requests
export default function ProductLinkModule(props) {
  const { token, t } = props.store;
  const { name: brand_name, portal_brand_name } = props.store.account;

  const history = useHistory();
  const [moduleIsLoading, setModuleIsLoading] = useState(false);
  const [isEmpty, setEmpty] = useState(false);
  const [copied, setCopied] = useState(false);
  const [isLinkLoading, setLinkLoading] = useState(false);

  const [sourceData, setSourceData] = useState([]);
  const [productData, setProductData] = useState([]);

  const [selectedSource, setSelectedSource] = useState('');
  const [selectedProduct, setSelectedProduct] = useState('');
  const [personalLink, setPersonalLink] = useState('');

  const buildSources = useCallback(async () => {
    const data = await getSources(token, 'product_links');
    setSourceData(data);
    if (data && data.length > 0) {
      if (data.length === 1) {
        setSelectedSource({ value: data[0].sub_id, label: data[0].name });
      }
      setEmpty(false);
    } else if (!data || data.length === 0) {
      setEmpty(true);
    }
  }, [token]);

  const getSourcesForStore = useCallback(async () => {
    if (!token) return;

    try {
      setModuleIsLoading(true);
      buildSources();
    } catch (error) {
      console.error('getSources: ', error);
    } finally {
      setModuleIsLoading(false);
    }
  }, [token, buildSources]);

  const getProductsForSource = useCallback(async (input) => {
    if (!token) return;

    try {
      let products;
      if (selectedSource) {
        products = await getProducts(token, selectedSource.value, input);
        setProductData(products);
      }
      return products;
    } catch (error) {
      console.error('getProducts: ', error);
    } finally {
      setSelectedProduct('');
      setPersonalLink('');
    }
  }, [token, selectedSource]);

  useEffect(() => {
    getSourcesForStore();
    return () => setModuleIsLoading(false);
  }, [getSourcesForStore]);

  useEffect(() => {
    if (!selectedSource) return;

    getProductsForSource();
  }, [selectedSource, getProductsForSource]);

  const onChange = async (product) => {
    if (product) {
      setSelectedProduct(product);
      try {
        setLinkLoading(true);
        const link = await getProductLink(token, selectedSource.value, product.value);
        setPersonalLink(link.url);
      } catch (error) {
        console.error('getLink: ', error);
      } finally {
        setLinkLoading(false);
      }
    }
  }

  const emptyContent = (
    <S.Flex column>
      <S.Text muted>
        {portal_brand_name || brand_name} {t('components.ProductLinkModule.storeNotSetUp')}
      </S.Text>
      <S.Flex justifyBetween marginTop={20} >
        <S.Link marginLeft={10} onClick={history.goBack}>{t('shared.back')}</S.Link>
      </S.Flex>
    </S.Flex>
  );

  const copyValue = (e) => {
    navigator.clipboard.writeText(personalLink);
    setCopied(true);
  }

  const buildSourceOptions = useCallback(() => sourceData.map(source => ({ value: source.sub_id, label: source.name })), [sourceData]);

  return (
    <Module
      title="Share Specific Product"
      isLoading={moduleIsLoading}
      isProblem={!sourceData}
      flex={props.flex}
      isEmpty={isEmpty}
      emptyContent={emptyContent}
      minHeight={"40vh"}
    >
      <S.Text
        alignStart
        justifyStart
        height={10}
        smallSize
      >
        {t('components.ProductLinkModule.uniqueLinksToProducts')}
      </S.Text>
      <S.Flex column justifyBetween marginTop={24} alignStart>
        {sourceData && sourceData.length > 1 && (
          <S.Flex column alignStart>
            <S.Text lightWeight mediumSize marginTop={20}>{t('components.ProductLinkModule.whichShop')}</S.Text>
            <S.AutoCompleteSelect
              name="selectedSource"
              classNamePrefix='Select'
              defaultOptions={buildSourceOptions()}
              value={selectedSource}
              onChange={setSelectedSource} />
          </S.Flex>
        )}
        <S.Flex column alignStart>
          <S.Text lightWeight mediumSize marginTop={20}>{t('components.ProductLinkModule.whichProduct')}</S.Text>
          <S.AutoCompleteSelect
            defaultOptions={productData}
            classNamePrefix='Select'
            loadOptions={getProductsForSource}
            value={selectedProduct}
            key={selectedProduct}
            onChange={onChange}
          />
        </S.Flex>

        {selectedProduct && (
          <S.Flex column>
            <S.Flex
              justifyStart
              marginTop={20}
            >
              <S.Image
                  borderRadius={0}
                  height='50%'
                  width='50%'
                  maxWidth={150}
                  src={selectedProduct.featured_image?.data?.transformedSrc}
                  alt={`${selectedProduct.title} image`} />
              <S.Link marginLeft={10} small rel="noopener noreferrer" target="_blank" href={selectedProduct.online_store_url}>
                {selectedProduct.title}
              </S.Link>
            </S.Flex>
            <S.Flex justifyStart column alignStart marginTop={20} flex={1}>
              <Copyable loading={isLinkLoading} copied={copied} name={t('components.ProductLinkModule.yourProductLink')} value={personalLink} store={props.store} />
            </S.Flex>
          </S.Flex>
        )}
      </S.Flex>
        <S.Flex justifyBetween marginTop={20} flex={1} alignStart>
          <S.Link marginLeft={10} onClick={history.goBack}>{t('shared.back')}</S.Link>
          {personalLink && <S.Flex>
            <S.Button outline smallSize onClick={copyValue} flex={1}>
              {t('components.ProductLinkModule.copyLink')}
            </S.Button>
          </S.Flex>}
        </S.Flex>

    </Module>
  );
}
