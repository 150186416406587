import api from '../api';

const baseUrl = (token) => `program_applications/${token}`;

export const signUpForCampaignProgram = (token, programId, formData) =>
  api({
    method: 'post',
    url: `${baseUrl(token)}/${programId}`,
    data: {
      form_data: formData
    }
  });

export const checkForApplication = (token, programId) =>
  api({
    method: 'get',
    url:`${baseUrl(token)}/${programId}/application`
  });
