import React from 'react';
import S from '../../styled';
import NetworkButton from '../NetworkButton';
import Module from '../Module';

const AddNetwork = ({ isLoading, profile, connectToNetwork, openTikTokModal, goBack, profileTypeText, cancelText}) => {
  return (
    <Module isLoading={isLoading} isFailure={!profile}>
      <S.Flex column flex={0} gap={20}>
        <S.Flex row alignStart justifyStart marginBottom={30}>
          <S.Text>{profileTypeText}:</S.Text>
        </S.Flex>
        <S.Flex row flex={0}>
          <NetworkButton network='Facebook' onClick={() => connectToNetwork('facebook', true)} />
          <NetworkButton network='Instagram' onClick={() => connectToNetwork('instagram', true)} />
        </S.Flex>
        <S.Flex row flex={0}>
          <NetworkButton network='YouTube' onClick={() => connectToNetwork('youtube', true)} />
          <NetworkButton network='X' onClick={() => connectToNetwork('twitter', true)} />
        </S.Flex>
        <S.Flex row flex={0}>
          <NetworkButton network='Twitch' onClick={() => connectToNetwork('twitch', true)} />
          <NetworkButton network='TikTok' onClick={() => openTikTokModal()} />
        </S.Flex>
        <S.Flex padding={5} row flex={0}>
          <S.Link onClick={goBack}>{cancelText}</S.Link>
        </S.Flex>
      </S.Flex>
    </Module>
  );
}

export default AddNetwork;
