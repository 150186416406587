import styled from 'styled-components';
import { motion } from 'framer-motion';

const NavLogo = styled(motion.img)`
	max-height: 80px;
	height: auto;
	max-width: 200px;
	width: auto;
	object-fit: cover;
`;

export default NavLogo;
