// General Layout
import Flex from './components/Flex';
import Grid from './components/Grid';

// Application Layout
import BottomNav from './components/BottomNav';
import Footer from './components/Footer';
import Layout from './components/Layout';
import Page from './components/Page';
import TopNav from './components/TopNav';
import Yield from './components/Yield';

// Other Components
import Divider from './components/Divider';
import Flash from './components/Flash';
import Modal from './components/Modal';
import ModalBody from './components/ModalBody';
import ModalFooter from './components/ModalFooter';
import Navigation from './components/Navigation';
import ScreenOverlay from './components/ScreenOverlay';

// General Styled Components
import App from './components/App';
import AutoCompleteSelect from './components/AutoCompleteSelect';
import Badge from './components/Badge';
import Button from './components/Button';
import Checkbox from './components/Checkbox';
import DashboardLabel from './components/DashboardLabel';
import Form from './components/Form';
import HtmlWrapper from './components/HtmlWrapper'
import IFrame from './components/IFrame';
import Image from './components/Image';
import Input from './components/Input';
import InputFake from './components/InputFake';
import InputPhone from './components/InputPhone';
import InputRadio from './components/InputRadio';
import Label from './components/Label';
import Link from './components/Link';
import Module from './components/Module';
import ModuleItem from './components/ModuleItem';
import Nav from './components/Nav';
import Option from './components/Option';
import SectionBreak from './components/SectionBreak';
import Select from './components/Select';
import SvgWrapper from './components/SvgWrapper';
import Text from './components/Text';
import Textarea from './components/Textarea';
import Video from './components/Video';
import Visibility from './components/Visibility';

//drop down button components
import { DropDown, DropDownPrimaryButton, DropDownSecondaryButton } from './components/DropDownButton';

// Tooltip
import Tooltip, { TooltipTarget } from './components/Tooltip';

// Specified Styled Components
import NavButton from './components/NavButton';
import NavLogo from './components/NavLogo';
import ProfileModule from './components/ProfileModule';

// Loading / Spinners
import Spinner from './components/Spinner';

const styles = {
  App,
  AutoCompleteSelect,
  Badge,
  BottomNav,
  Button,
  Checkbox,
  DashboardLabel,
  Divider,
  DropDown,
  DropDownPrimaryButton,
  DropDownSecondaryButton,
  Flash,
  Flex,
  Footer,
  Form,
  Grid,
  HtmlWrapper,
  IFrame,
  Image,
  Input,
  InputFake,
  InputPhone,
  InputRadio,
  Label,
  Layout,
  Link,
  Modal,
  ModalBody,
  ModalFooter,
  Module,
  ModuleItem,
  Nav,
  NavButton,
  Navigation,
  NavLogo,
  Option,
  Page,
  ProfileModule,
  ScreenOverlay,
  SectionBreak,
  Select,
  Spinner,
  SvgWrapper,
  Text,
  Textarea,
  Tooltip,
  TooltipTarget,
  TopNav,
  Video,
  Visibility,
  Yield,
};

export default styles;
