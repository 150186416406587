import styled from 'styled-components';
import { motion } from 'framer-motion';
import { darken } from 'polished';
import { clickable } from '../Mixins';

const ModuleItem = styled(motion.div)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: relative;
  font-family: inherit;
  font-size: inherit;
  width: 100%;
  max-width: 343px;
  min-height: 50px;
  padding: 10px;
  transition: all 0.3s ease-in-out;
  border-width: 1px;
  border-style: solid;
  border-radius: ${({ theme }) => theme.input.borderRadius};
  border-color: ${({ theme }) => theme.input.color.border};
  background-color: ${({ theme }) => theme.input.color.background};
  ${clickable}

  &:hover {
    background-color: ${({ theme, hoverable }) =>
      hoverable ? darken(0.05, theme.input.color.background) : null};
  }
`;

export default ModuleItem;
