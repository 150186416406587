import React from 'react';

import Page from '../../components/Page';
import NetworkModule from '../../components/NetworkModule';

export default function NetworkConnect(props) {
  const { store, setStore } = props;
  const { t } = store;

  return (
    <Page title={t('pages.NetworkConnect.connectYourProfiles')}>
      <NetworkModule store={store} setStore={setStore}/>
    </Page>
  );
}
