import styled, { css } from 'styled-components';
import { motion } from 'framer-motion';
import { padding, margin, flex, breakpointNames, breakpoint } from '../Mixins';

const topPosition = 80;

export const base = css`
	transition: all 0.3s;
	position: fixed;
	width: calc(100% - 48px);
	margin-left: 24px;
	align-items: flex-start;
	justify-content: flex-start;
	z-index: 200;
	box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
	background-color: ${({ theme }) => theme.modal.color.background};
	color: ${({ theme }) => theme.modal.color.text};
	border-radius: ${({ theme }) => theme.modal.borderRadius};
	font-weight: ${({ theme }) => theme.modal.fontWeight};
	${breakpoint(breakpointNames.tablet, 'max-width: 500px')};

	${padding}
	${margin}
	${flex}
`;

const Modal = styled(motion.div)`
	${base}
	transform: ${({ render }) =>
		render ? 'translateY(0)' : `translateY(100vh)`};
	height: calc(100vh - 180px);
	padding: 20px;
	top: ${topPosition}px;
`;

export default Modal;
