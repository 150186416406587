import React from 'react';
import S from '../../styled';
import NetworkIcon from '../NetworkIcon';

export default function NetworkButton({network, onClick}) {
  const networkKey = network.toLowerCase();
  return (
    <S.Flex padding={5} flex={0}>
      <S.Button network={networkKey} width={150} onClick={onClick}>
        <NetworkIcon network={networkKey} />
        {network}
      </S.Button>
    </S.Flex>
  );
}
