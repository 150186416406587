import React from 'react';
import S from '../../styled';

const TaskModal = ({ confirm, cancel, task, confirmText, cancelText, markTaskAsCompleteText }) => {
  return (
    <S.Flex alignCenter textAlign='center' column>
      { task && (
        <S.Flex alignCenter textAlign='center' column>
          <S.Flex>
            {markTaskAsCompleteText}
          </S.Flex>
        </S.Flex>
      )}
      <S.Flex gap={20} margin={45}>
        <S.Button gap={8} onClick={() => confirm(task)}>
          {confirmText}
        </S.Button>
        <S.Button secondary onClick={() => cancel()}>
          {cancelText}
        </S.Button>
      </S.Flex>
    </S.Flex>
  );
};

export default TaskModal;
