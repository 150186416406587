import React, { useCallback } from 'react';
import S from '../../../styled';
import { useHistory } from 'react-router-dom';

// Constants
import { AUTHENTICATION_TIKTOK, FLASH_TYPE_ERROR, FLASH_TYPE_SUCCESS } from '../../../helpers/constants';

// Routes
import { routeNames } from '../../../routing/routes';

// Components
import TaskIcon from './TaskIcon';
import TaskModal from '../../TaskModal';
import TikTokModal from '../../../components/TikTokModal';

// Icons
import { ChevronRight as ChevronRightIcon } from '@styled-icons/fa-solid';
import { useAuthentication } from '../../../authentication';
import {
  ExclamationCircle as CircleExclamationIcon,
} from '@styled-icons/fa-solid';
import {
  CheckCircle as CheckCircleIcon
} from '@styled-icons/fa-regular';

// Helpers
import navigateTo from '../../../helpers/navigateTo';
import { taskNameWithDueDate } from '../../../helpers/taskNameDueDate';

import { useTask } from '../../../components/Task/useTask';

const ChevronRight = (
  <S.SvgWrapper height={40} width={40} marginRight={10} displayInline scale={0.5}>
    <ChevronRightIcon />
  </S.SvgWrapper>
);

export default function Task(props) {
  const { token, task, taskGroupName, setStore, onTaskUpdated, profile, summaryView, store } = props;
  const { name, description, type, complete, due_date } = task;
  const { setRenderModal, setModalContent, setRenderFlashMessage } = setStore;
  const history = useHistory();

  const { connectToNetwork } = useAuthentication();
  
  const { t } = store;

  const openTaskModal = useCallback(async (completeTask) => {
    setModalContent(<TaskModal
      confirm={() => {
        const success = completeTask();
        if (success) {
          setRenderModal(false);
          setRenderFlashMessage(
            t('components.TasksModule.taskMarkedAsComplete'),
            FLASH_TYPE_SUCCESS
          );
        } else {
          setRenderFlashMessage(
            t('components.TasksModule.errorMarkingTaskComplete'),
            FLASH_TYPE_ERROR
          );
        }
      }}
      cancel={() => setRenderModal(false)}
      task={task}
      markTaskAsCompleteText={t('components.TaskModal.markTaskAsComplete')}
      confirmText={t('shared.confirm')}
      cancelText={t('shared.cancel')}
    />);
    setRenderModal(true);
  }, [setModalContent, setRenderFlashMessage, setRenderModal, task]);

  const connectNetwork = async (profile, network) => {
    const socialProfileIndex = profile?.social_profiles && profile?.social_profiles?.findIndex((p => p.network === network));
    const hasNetwork = socialProfileIndex >= 0;
    if (hasNetwork) {
      const socialProfile = profile.social_profiles[socialProfileIndex];
      if (network === AUTHENTICATION_TIKTOK) {
        openTikTokModal(socialProfile);
      } else {
        return await connectToNetwork(network, false, socialProfile);
      }
    } else {
      if (network === AUTHENTICATION_TIKTOK) {
        openTikTokModal();
      } else {
        return await connectToNetwork(network, true);
      }
    }
  }

  const openTikTokModal = currentProfile => {
    setModalContent(<TikTokModal
      profile={currentProfile}
      cancel={() => setRenderModal(false)}
      confirm={connectTikTok}
      store={props.store}
    />);
    setRenderModal(true);
  }

  const connectTikTok = async (profile, add) => {
    await connectToNetwork(AUTHENTICATION_TIKTOK, add, profile);
    setRenderModal(false);
    navigateTo(history, routeNames.networkConnect, token);
  }

  const { isActionable, taskAction } = useTask(task, token, profile, taskGroupName, openTaskModal, onTaskUpdated, connectNetwork);

  return (
    <S.Flex
      clickable={!complete && isActionable}
      hoverable={!complete && isActionable}
      onClick={taskAction}
      padding={5}
      flex={1}
    >
      {summaryView ? 
        (
          <>
            {complete ? ( 
              <S.SvgWrapper height={35} width={35} padding={6}  displayInline buttonColor>
              <CheckCircleIcon/>
              </S.SvgWrapper> 

            ) : (
              <S.SvgWrapper height={35} width={35} padding={6}  displayInline warning>
              <CircleExclamationIcon/>
              </S.SvgWrapper> 

            )
            }
            </>
        ) : (
          <TaskIcon type={type} complete={complete} />
        )
      }
      <S.Flex column alignStart marginLeft={14} flex={1}>
        <S.Text mediumWeight mediumSize paragraph wordBreakWord strikethrough={complete && !summaryView}>
          {taskNameWithDueDate(name, due_date)}
        </S.Text>
        <S.Text muted lightWeight mediumSize paragraph flex={1} strikethrough={complete && !summaryView}>
          {description}
        </S.Text>
      </S.Flex>
      {isActionable && ChevronRight}
    </S.Flex>
  );
}

