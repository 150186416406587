import React, { useContext } from 'react';

import S from '../../../styled';
import { FormFieldContext } from '../../../store/FormFieldContext';
import { FORM_FIELD_DEFAULT_LABELS } from '../../../helpers/constants';

import FieldErrorMessage from './FieldErrorMessage';

export default function CheckBoxInput({ id, name, fieldType, required, store }) {
  const { formFieldData, onChangeData, errors } = useContext(FormFieldContext);
  const { t } = store;
  const labelText =
    name === FORM_FIELD_DEFAULT_LABELS[fieldType]
      ? t(`components.FormField.typeLabels.${fieldType}`)
      : name

  return (
    <S.Flex column alignStart marginTop={14}>
      <S.Flex key={id} gap={16} flex={0} marginTop={8}>
        <S.Checkbox
          name={name}
          checked={formFieldData[id] || false}
          onChange={(e) => onChangeData(id, fieldType, required, e.target.checked)}
        />
        <S.Text as="p" maxWidth marginTop={3}>
          {labelText}
        </S.Text>
      </S.Flex>
      <FieldErrorMessage
        id={id}
        required={required}
        errors={errors}
        fieldName={t('components.FormField.checkbox')}
        store={store}
      />
    </S.Flex>
  );
};
