import React, { useState, useEffect, useCallback } from 'react';
import S from '../../styled';
import { getTheme } from '../../styled/Themes';
import { useHistory } from 'react-router-dom';

// Components
import Page from '../../components/Page';

// Requests
import { getPaymentRailsWidgetIframeUrl } from '../../data/requests/payment';
import Module from '../../components/Module';
import PaymentsModule from '../../components/PaymentsModule';

// Helpers
import { PORTAL_PAYMENTS_DISABLED } from '../../helpers/constants';

export default function Payments(props) {
  const { account, token, theme, t, currentUser } = props.store;
  const history = useHistory();

  const [widgetLink, setWidgetLink] = useState('');
  const [isLoading, setIsLoading] = useState(true);

  const checkFeatureFlag = useCallback(async () => {
    if (!account) return;

    try {
      setIsLoading(true);
      if (portalPaymentsDisabled()) {
        history.goBack();
      }
    } finally {
      setIsLoading(false);
    }
  }, [account]);

  const portalPaymentsDisabled = () => {
    const arePortalPaymentsDisabled = account?.features?.indexOf(PORTAL_PAYMENTS_DISABLED) >= 0;

    return arePortalPaymentsDisabled || !isDelegatePayeeOrHasNoDelegatePayee() || currentUser?.acting_as_portal_preview;
  }

  const isDelegatePayeeOrHasNoDelegatePayee = () => {
    if (!currentUser) return;
    if (!currentUser.delegate_relationship_payee_id) return true;

    return currentUser.delegate_relationship_payee_id == currentUser.delegate_relationship_id;
  };

  const getWidgetLink = useCallback(async () => {
    if (!token) return;

    try {
      setIsLoading(true);
      const data = await getPaymentRailsWidgetIframeUrl(token, getTheme(theme));

      setWidgetLink(data.url);
    } catch (error) {
      console.error('getWidgetLink: ', error);
    } finally {
      setIsLoading(false);
    }
  }, [token, theme]);

  useEffect(() => {
    checkFeatureFlag();
    getWidgetLink();
  }, [checkFeatureFlag, getWidgetLink]);

  return (
    <Page
      title={t('navigation.payments')}
      isLoading={isLoading}
      alignStart
      justifyStart
    >
      {widgetLink &&
        <>
          <PaymentsModule store={props.store} />
            <Module
            isLoading={isLoading}
            flex={props.flex}
            fullPage
          >
            <S.IFrame src={widgetLink} />
          </Module>
        </>
      }
    </Page>
  );
}
