import React, { useContext } from 'react';
import S from '../../../styled';
import HtmlContent from '../../HtmlContent';
import { FormFieldContext } from '../../../store/FormFieldContext';
import { DEFAULT_TERMS_LABEL, FORM_FIELD_DEFAULT_LABELS, FORM_FIELD_TYPES } from '../../../helpers/constants';

import FieldErrorMessage from './FieldErrorMessage';

export default function TermsInput({ id, required, store, params }) {
  const { formFieldData, onChangeData, errors } = useContext(FormFieldContext);
  const { t } = store;

  const fieldType = params.field_type;
  const bodyText = params.metadata.body_text;
  const headerText =
    params.metadata.header_text === FORM_FIELD_DEFAULT_LABELS[FORM_FIELD_TYPES.TERMS_AND_CONDITIONS]
      ? t('components.FormField.typeLabels.termsConditions')
      : params.metadata.header_text;
  const labelText =
    params.metadata.label_text === DEFAULT_TERMS_LABEL
      ? t('components.FormField.typeLabels.termsConditionsLabel')
      : params.metadata.label_text;

  return (
    <S.Flex column alignStart marginTop={14}>
      <S.Text>{headerText}</S.Text>
      <HtmlContent border content={bodyText} useThemeFonts/>
      <S.Flex marginTop={8} gap={16} flex={0}>
        <S.Checkbox
          name={labelText}
          checked={formFieldData[id]}
          onChange={(e) => onChangeData(id, fieldType, required, e.target.checked)}
        />
        <S.Text as="p" maxWidth marginTop={3}>
          {labelText}
        </S.Text>
      </S.Flex>
      <FieldErrorMessage
        id={id}
        required={required}
        errors={errors}
        fieldName={labelText}
        store={store}
      />
    </S.Flex>
  );
}
