import config from "../config";
import getUrlToken from "../helpers/getUrlToken";
import {
  ERROR_403
} from "../helpers/constants";

export function oauthRedirect(provider, action, additionalParams, additionalState) {
  let decodedState = { action, portal_token: getUrlToken() };
  if (additionalState) {
    decodedState = { ...decodedState, ...additionalState };
  }
  const state = encodeState(decodedState);
  const params = new URLSearchParams();

  if (provider === 'youtube') {
    provider = 'google';
  }

  params.set("state", state);
  if (additionalParams) {
    for (let key in additionalParams) {
      params.set(key, additionalParams[key]);
    }
  }
  window.location.href = `${
    config.serverOrigin
  }/auth/${provider}?${params.toString()}`;
}

function encodeState(state) {
  return encodeURIComponent(JSON.stringify(state));
}

export function parseOAuthFailure() {
  const { pathname, search } = document.location;

  const isCallback = pathname.endsWith("/oauth/callback")
  const isLogin = pathname.includes("/login")
  if (!isCallback && !isLogin) return {};

  const data = {};
  new URLSearchParams(search).forEach((value, key) => {
    if (['status', 'error', 'error_code', 'reference_code'].indexOf(key) >= 0) {
      data[key] = value;
    }
  });

  if (data.status === ERROR_403.status && !data.error_code) return ERROR_403
  if (data.status && data.error && data.error_code) return data;
  return {};
}
