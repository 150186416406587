import React from 'react';
import S from '../../../styled';
import styled from 'styled-components';

const FormFieldSectionBreak = styled(S.Divider)`
  border-width: unset;
  border-color: unset;
	border-style: unset;
`

export default function SectionBreak({ params }) {
  const { name } = params

  return (
    <FormFieldSectionBreak column marginTop={8} width={'100%'}>
      <S.Text>
        {name}
      </S.Text>
      <S.SectionBreak/>
    </FormFieldSectionBreak>
  );
}
