import React, { useCallback, useState, useContext, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import _ from 'lodash';
import S from '../../styled';

// Routes
import { routeNames } from '../../routing/routes';

// Store
import { FormFieldContext } from '../../store/FormFieldContext';

// Model Requests
import { declineCampaignProgram } from '../../data/requests/program';
import { signUpForCampaignProgram } from '../../data/requests/programApplication';

// Helpers
import navigateTo from '../../helpers/navigateTo';

import FormField from '../FormField'

import { hasErrors } from '../../helpers/hasErrors';
import { FORM_FIELD_TYPES } from '../../helpers/constants';

const ALLOWED_PROGRAM_STATUS_RESPONSES = ['pending', 'active', 'declined'];

export default function CampaignProgramSignUpForm(props) {
  const {
    formFields, store, programId, errorMessage,
    successMessage, hasAlreadyApplied, hasAlreadyDeclined
  } = props;
  const { t, token } = store;

  const history = useHistory();
  const { formFieldData, validateAllFields } = useContext(FormFieldContext);

  const [signUpDisabled, setSignUpDisabled] = useState(false);
  const [renderDeclineButton, setRenderDeclineButton] = useState(!hasAlreadyDeclined);
  const [showMissingRequiredMessage, setShowMissingRequiredMessage] = useState(false);

  const initialButtonText = () => {
    if (hasAlreadyApplied) {
      return t('components.CampaignProgramSignUpForm.buttonSignedUp');
    };

    return t('components.CampaignProgramSignUpForm.buttonSignUp');
  }
  const [signUpButtonText, setSignUpButtonText] = useState(() => initialButtonText());

  const visibleFormFields = useMemo(() => {
    return formFields?.filter(
      (item) => (
        _.camelCase(item.field_type) !== FORM_FIELD_TYPES.NAME &&
        _.camelCase(item.field_type) !== FORM_FIELD_TYPES.EMAIL
      )
    ) || [];
  }, [formFields]);

  const signUpForProgram = useCallback(async () => {
    try {
      const errorsAfterValidation = validateAllFields(visibleFormFields);
      if (!hasErrors(errorsAfterValidation)) {
        setShowMissingRequiredMessage(true);
        return;
      }
      setShowMissingRequiredMessage(false);
      setSignUpButtonText(t('components.CampaignProgramSignUpForm.buttonSubmitting'));
      const application = await signUpForCampaignProgram(token, programId, formFieldData);
      if (ALLOWED_PROGRAM_STATUS_RESPONSES.includes(application?.status)) {
        successMessage();
        setSignUpButtonText(t('components.CampaignProgramSignUpForm.buttonSignedUp'));
        setSignUpDisabled(true);
        setRenderDeclineButton(false);
        navigateTo(history, routeNames.dashboard, token);
      } else {
        setSignUpButtonText(t('components.CampaignProgramSignUpForm.buttonSignUp'));
        errorMessage();
      }
    } catch (error) {
      errorMessage();
    }
  }, [
    token, programId, errorMessage, successMessage, history,
    formFieldData, visibleFormFields, t, validateAllFields
  ]);

  const declineProgram = useCallback(async () => {
    try {
      await declineCampaignProgram(token, programId);
      successMessage(true);
      history.goBack();
    } catch (error) {
      errorMessage(true);
    }
  }, [token, programId, history, errorMessage, successMessage]);

  const campaignSignUpButtons = (
    <S.Flex column alignCenter flex={1} overflowHidden marginTop={15} >
      <S.Visibility
        desktop
        tablet
        justifyBetween={renderDeclineButton}
        alignCenter
        width='90%'
      >
        <S.Button
          type="button"
          onClick={signUpForProgram}
          secondary={signUpButtonText !== t('components.CampaignProgramSignUpForm.buttonSignUp')}
          disabled={signUpDisabled || hasAlreadyApplied}
          marginAuto={!renderDeclineButton}
          width={renderDeclineButton ? '40%' : '60%'}
        >
          {signUpButtonText}
        </S.Button>
        {renderDeclineButton &&
          <S.Button
            type="button"
            secondary
            width='40%'
            onClick={declineProgram}
          >
            {t('pages.CampaignProgramSignUp.notInterested')}
          </S.Button>
        }
      </S.Visibility>
      <S.Visibility mobile column gap={10} alignCenter>
        <S.Button
          type="button"
          onClick={signUpForProgram}
          secondary={signUpButtonText !== t('components.CampaignProgramSignUpForm.buttonSignUp')}
          disabled={signUpDisabled || hasAlreadyApplied}
        >
          {signUpButtonText}
        </S.Button>
        {renderDeclineButton &&
          <S.Button
            type="button"
            secondary
            onClick={declineProgram}
          >
            {t('pages.CampaignProgramSignUp.notInterested')}
          </S.Button>
        }
      </S.Visibility>
    </S.Flex>
  )

  const missingRequiredFieldMessage = () => (
    <S.Text warning>
      {t('components.CampaignProgramSignUpForm.invalidForm')}
    </S.Text>
  )

  return (
    <S.Form column>
      <FormField flex={1} hasAlreadyApplied={hasAlreadyApplied} formFields={visibleFormFields} store={store}/>
      {campaignSignUpButtons}
      {showMissingRequiredMessage && missingRequiredFieldMessage()}
    </S.Form>
   );
}
