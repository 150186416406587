import styled from 'styled-components';
import {
  height,
  width,
  position,
  padding,
  margin,
  flex,
  border,
} from '../Mixins';

const Divider = styled.div`
  ${border}
  ${position}
  ${height}
  ${width}
  ${padding}
  ${margin}
  ${flex}
`;

export default Divider;
