import styled from 'styled-components';
import { gap } from '../Mixins';

const Layout = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	min-height: 100vh;
	position: relative;
	${gap}
`

export default Layout;
